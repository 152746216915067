import craneServiceSelected from '../../../images/craneServiceSelected.svg';
import bikeServiceUnselected from '../../../images/bikeServiceUnselected.svg';
import gasServiceUnselected from '../../../images/gasServiceUnselected.svg';
import tireServiceUnselected from '../../../images/tireServiceUnselected.svg';
import craneServiceUnselected from '../../../images/craneServiceUnselected.svg';
import bikeServiceSelected from '../../../images/bikeServiceSelected.svg';
import gasServiceSelected from '../../../images/gasServiceSelected.svg';
import tireServiceSelected from '../../../images/tireServiceSelected.svg';
import batteryServiceUnselected from '../../../images/batteryServiceUnselected.svg';
import batteryServiceSelected from '../../../images/batteryServiceSelected.svg';

import {
  afiliatedServices,
  particularServices,
} from '../../../assets/helpers/options';
import { capitalize } from '../../../assets/helpers/funciones';

import './Incidence.scss';

export const ServiceButton = (props) => {
  const { service, selectService } = props;

  let src = '';

  if (
    [afiliatedServices.crane, particularServices.crane].includes(service?.code)
  ) {
    service.selected
      ? (src = craneServiceSelected)
      : (src = craneServiceUnselected);
  }
  if ([afiliatedServices.gas, particularServices.gas].includes(service?.code)) {
    service.selected
      ? (src = gasServiceSelected)
      : (src = gasServiceUnselected);
  }
  if (
    [
      afiliatedServices.tire,
      particularServices.tire1,
      particularServices.tire2,
    ].includes(service?.code)
  ) {
    service.selected
      ? (src = tireServiceSelected)
      : (src = tireServiceUnselected);
  }
  if (
    [afiliatedServices.battery, particularServices.battery].includes(
      service?.code
    )
  ) {
    service.selected
      ? (src = batteryServiceSelected)
      : (src = batteryServiceUnselected);
  }
  if ([afiliatedServices.accompaniment].includes(service?.code)) {
    service.selected
      ? (src = bikeServiceSelected)
      : (src = bikeServiceUnselected);
  }

  return (
    <div className="service-button-container">
      <h6>{capitalize(service?.product)}</h6>
      <img src={src} onClick={() => selectService(service)} />
    </div>
  );
};

export const SMSServiceLogo = (props) => {
  const { service, action, grua } = props;

  let src = '';

  if (
    [afiliatedServices.crane, particularServices.crane].includes(service) ||
    grua
  ) {
    src = craneServiceUnselected;
  }
  if ([afiliatedServices.gas, particularServices.gas].includes(service)) {
    src = gasServiceUnselected;
  }
  if (
    [
      afiliatedServices.tire,
      particularServices.tire1,
      particularServices.tire2,
    ].includes(service)
  ) {
    src = tireServiceUnselected;
  }
  if (
    [afiliatedServices.battery, particularServices.battery].includes(service)
  ) {
    src = batteryServiceUnselected;
  }
  if ([afiliatedServices.accompaniment].includes(service)) {
    src = bikeServiceUnselected;
  }
  return <img onClick={action} className="gasLogo" src={src} />;
};

export const EstimatedTime = (props) => {
  const { service } = props;

  // Mensaje para las gruas
  if (
    [afiliatedServices.crane, particularServices.crane].includes(service) &&
    service
  ) {
    return (
      <>
        <br /> <b>Tiempo estimado:</b> 40 min
      </>
    );
  }
  if (
    ![afiliatedServices.crane, particularServices.crane].includes(service) &&
    service
  ) {
    return (
      <>
        <br /> <b>Tiempo estimado: </b>25 min
      </>
    );
  } else return null;
};
