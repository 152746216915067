import React from 'react';
import phone from '../../../images/phone.svg';
import {
  CreateParticularCustomertForm,
  EditParticularCustomertForm,
  CreateParticularCaseForm,
  EditParticularCaseForm,
} from '../../common/Forms/Form';
import { Select } from '../../common/Forms/Input/Input';
import { capitalize, exists } from '../../../assets/helpers/funciones';
import moment from 'moment/moment';
import { PlanStatus } from '../../common/Cards/Cards';
import searchImg from '../../../images/search.svg';
import { ServiceButton } from './IndicenceButtons';
import { serviceHistoryTableheader } from '../../../assets/helpers/tables';
import { IncidenceTable } from '../../common/Tables/Tables';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import {
  afiliatedServices,
  particularServices,
  clientTypes,
  planStatus,
} from '../../../assets/helpers/options';
import { SelectProvider } from '../../common/Forms/Input/Input.jsx';
import { notFound, lastServiceEnum, setviceType, towReasonsOptions } from '../../../assets/helpers/options';

import './Incidence.scss';

export const ClientDetailSectionForm = (props) => {
  const {
    t,
    register,
    errors,
    onSubmit,
    options,
    type,
    onChange,
    getValues,
    watch,
  } = props;
  return (
    <>
      <div className="light-gray-line"></div>
      <div className="buttons-container">
        <div>
          <h6>DETALLE DEL CLIENTE</h6>
          <p>(*) Campos obligatorios</p>
        </div>
      </div>
      {type === notFound ? (
        <CreateParticularCustomertForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          options={options}
          onChange={onChange}
          getValues={getValues}
          watch={watch}
        />
      ) : (
        <EditParticularCustomertForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          options={options}
          onChange={onChange}
          watch={watch}
        />
      )}
    </>
  );
};

export const KmComponent = () => { }

export const ClientDetail = (props) => {
  const { membership, _case } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="buttons-container">
        <h6>DETALLE DEL CLIENTE</h6>
        {membership?.status?.membershipStatus && (
          <PlanStatus label={membership?.status?.membershipStatus} membership={membership} />
        )}
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Datos del Cliente :</b>
            <br />
            {capitalize(
              membership?.customer?.firstName ||
              _case?.particularCustomer?.firstName
            ) +
              ' ' +
              capitalize(
                membership?.customer?.lastName ||
                _case?.particularCustomer?.lastName
              )}
            <br />
            <>
              {exists(
                membership?.customer?.phonePrefix ||
                _case?.particularCustomer?.phonePrefix
              ) +
                '-' +
                exists(
                  membership?.customer?.phoneNumber ||
                  _case?.particularCustomer?.phoneNumber
                )}
            </>
            <br />
            {membership?.customer?.prefixIdentification ? (
              <>
                {exists(membership?.customer?.prefixIdentification)}
                {exists(membership?.customer?.identification)} <br />
              </>
            ) : (
              <></>
            )}
            {membership?.code?.code ? (
              <>
                <b>Plan: {exists(membership?.code?.code)}</b> <br /> N°
                Producto:
                {' ' +
                  exists(membership?.code?.prefixCode) +
                  '-' +
                  exists(membership?.saleChannel?.shortName) +
                  '-' +
                  exists(membership?.membershipId)}
                <br /> <b>Vigencia:</b>{' '}
                {membership?.beginDate &&
                  moment.utc(membership?.beginDate).format('DD/MM/YYYY') +
                  ' al '}
                {membership?.endDate &&
                  moment.utc(membership?.endDate).format('DD/MM/YYYY')}

                {membership?.towKM &&
                  <>
                    <br /> <b>Km permitidos: </b>{membership?.towKM}
                  </>}
              </>
            ) : (
              <b>Cliente No Registrado</b>
            )}

            <br />
            {membership?.affiliationType && (
              <>
                {' '}
                <b>Tipo de Afiliación: </b>
                {exists(membership?.affiliationType)}
              </>
            )}

            <br />
          </p>
        </div>
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Datos del Vehiculo :</b>
            <ul>
              {membership?.vehicle?.class && (
                <li>Clase: {capitalize(membership?.vehicle?.class)}</li>
              )}
              {membership?.vehicle?.type && (
                <li>Tipo: {capitalize(membership?.vehicle?.type)}</li>
              )}
              <li>
                Año:{' '}
                {capitalize(
                  membership?.vehicle?.year ||
                  _case?.particularCustomer?.vehicleYear
                )}{' '}
              </li>
              <li>
                Modelo:{' '}
                {capitalize(
                  membership?.vehicle?.model ||
                  _case?.particularCustomer?.vehicleModel?.model
                )}
              </li>
              <li>
                Marca:{' '}
                {capitalize(
                  membership?.vehicle?.brand ||
                  _case?.particularCustomer?.vehicleBrand?.brand
                )}
              </li>
              <li>
                Color:{' '}
                {capitalize(
                  membership?.vehicle?.color ||
                  _case?.particularCustomer?.vehicleColor
                )}{' '}
              </li>
              <li>
                Placa:{' '}
                {exists(
                  membership?.vehicle?.plate ||
                  _case?.particularCustomer?.vehiclePlate
                )}
              </li>
              {membership?.vehicle?.serial && (
                <li>Serial: {exists(membership?.vehicle?.serial)} </li>
              )}
              {membership?.vehicle?.km > 0 && (
                <li>Kilometraje: {membership?.vehicle?.km}&nbsp;Km </li>
              )}
              {membership?.vehicle?.seats && (
                <li>Asientos: {exists(membership?.vehicle?.seats)}</li>
              )}
              <li>Blindaje: {membership?.vehicle?.armored ? "Si" : "No"}</li>
              {membership?.vehicle?.armored && (
                <li>Tipo de Blindaje: {membership?.vehicle?.armorType}</li>
              )}
              {/* <li>Blindaje: {exists(membership?.vehicle?.serial)} NO</li> */}
            </ul>
          </p>
        </div>
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Dirección asociada al vehiculo:</b>
            <br />
            <ul>
              <li>
                <b>Estado:</b> {capitalize(membership?.vehicle?.state)}
              </li>
              <li>
                <b>Ciudad:</b> {capitalize(membership?.vehicle?.city)}
              </li>

              <li>
                {capitalize(membership?.vehicle?.municipality) + '  '}
                {capitalize(membership?.vehicle?.address) +
                  ' ' +
                  capitalize(membership?.vehicle?.addressReference)}
              </li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
};

export const OptionStatusColor = (props) => {
  const { status } = props;

  let color = '';
  let newLabel = status;

  if ([planStatus.ACTIVE, planStatus.TO_BE_RENEW].includes(status)) {
    color = 'green';
    newLabel = 'Activo';
  }
  if ([planStatus?.WAITING_TO_BE_EXPIRED].includes(status)) {
    color = 'green';
    newLabel = 'Esperando Vencimiento';
  }

  if ([planStatus.EXPIRED, planStatus.TO_BE_EXPIRED].includes(status)) {
    color = 'red';
    newLabel = 'Vencido';
  }

  if ([planStatus.WAITING].includes(status)) {
    color = 'gray';
    newLabel = status;
  }

  return (
    <span style={{ color: color }}>
      {' '}
      <b>{capitalize(newLabel)}</b>&nbsp; | &nbsp;{' '}
    </span>
  );
};

export const IncidenceSearch = (props) => {
  const { clientSearch, onChange, search, selectClient, type } = props;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      search(clientSearch?.searchParam);
    }
  };

  return (
    <div className="incidence-search-container">
      <div className="buttons-container">
        <h6>BUSQUEDA DEL CLIENTE </h6>
      </div>
      <div className="incidence-search">
        <div className="searchImageContainers">
          <img
            src={searchImg}
            alt="search"
            onClick={() => search(clientSearch?.searchParam)}
          />
        </div>
        <input
          name={'searchParam'}
          value={clientSearch?.searchParam}
          type="text"
          placeholder="Busqueda"
          onChange={(e) => onChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <ActionButton
          action={() => search(clientSearch?.searchParam)}
          label={'Buscar'}
        />
        {clientSearch.isOpen && (
          <div className="incidence-search-options-container">
            {type === clientTypes.AFFILIATED &&
              clientSearch?.options?.length > 0 &&
              clientSearch?.options.map((o) => (
                <div key={o?._id} onClick={() => selectClient(o)}>
                  <p>
                    <OptionStatusColor status={o?.status} />
                    {capitalize(o?.name)} &nbsp; | &nbsp; CI:
                    {o?.prefixIdentification}-{o?.identification}&nbsp; | &nbsp;{' '}
                    {capitalize(o?.code)}
                    &nbsp; | &nbsp;
                    {capitalize(o?.brand)} {capitalize(o?.model)}{' '}
                    {capitalize(o?.color)} {o?.plate}
                  </p>
                </div>
              ))}
            {type === clientTypes.PARTICULAR &&
              clientSearch?.options?.length > 0 &&
              clientSearch?.options.map((o) => (
                <div key={o?._id} onClick={() => selectClient(o)}>
                  <p>
                    {exists(o?.vehiclePlate)} &nbsp;|&nbsp;
                    {capitalize(o?.firstName)} &nbsp;
                    {capitalize(o?.lastName)}&nbsp;|&nbsp;
                    {capitalize(o?.vehicleBrand?.brand)}&nbsp;
                    {capitalize(o?.vehicleModel?.model)}&nbsp;
                    {capitalize(o?.vehicleYear)}&nbsp;
                    {capitalize(o?.vehicleColor)}&nbsp;
                  </p>
                </div>
              ))}

            {clientSearch?.options?.length === 0 && (
              <div
                onClick={
                  type === clientTypes.PARTICULAR
                    ? () => selectClient()
                    : () => console.log('B')
                }
              >
                <h6>
                  No se encuentra ningún registro.{' '}
                  {type === clientTypes.PARTICULAR &&
                    'Haga click aqui para introducir los datos del cliente'}
                </h6>
              </div>
            )}
          </div>
        )}
      </div>

      {type === clientTypes.AFFILIATED ? (
        <p>Campos de búsqueda: (Nombre, Apellido, Placa, Identificación)</p>
      ) : (
        <p>Campos de búsqueda: (Placa del Vehículo))</p>
      )}
    </div>
  );
};

export const AvailableServices = (props) => {
  const { availableServices, selectService, _case } = props;

  let lastService = ""

  if (_case) {
    [lastService] = _case?.services?.slice(-1);
  }

  let filteredServices = [...availableServices?.available]


  if (lastService?.type === lastServiceEnum?.CRANE) {
    filteredServices = availableServices?.available?.filter(s => s.type === setviceType?.CRANE)
  }

  if (lastService?.type === lastServiceEnum?.ROAD_ATTENDANT) {
    filteredServices = availableServices?.available?.filter(s => s.type === setviceType?.ROAD_ATTENDANT)
  }

  // aqui filtrar por tipos de servicios dependiendo dlete si el ultimo servicio del caso es de grua o de AV

  return (
    <>
      <div className="light-gray-line"></div>
      <div className="buttons-container">
        <h6>SERVICIOS DISPONIBLES</h6>
      </div>
      <div className="service-avaliable-options-container">
        {filteredServices?.length > 0 &&
          filteredServices?.map((s) => {
            if (!s.selected) {
              return (
                <ServiceButton
                  key={s._id}
                  selectService={selectService}
                  service={s}
                />
              );
            }
          })}
        {filteredServices?.length === 0 && (
          <h6 style={{ textAlign: 'center', width: '100%' }}>
            No posee servicios disponibles
          </h6>
        )}
      </div>
    </>
  );
};

const SelectionReason = (props) => {

  const { name, options, label, value, onChange, } = props

  return (
    <Select name={name} options={options} label={label} value={value} onChange={onChange} />
  )
}

const isChangeService = (_case) => {
  if (_case?.services?.length > 0) {
    return false
  }
  else return true
}
export const SelectedServices = (props) => {
  const {
    providersOptions,
    unSelectService,
    availableServices,
    value,
    onChange,
    openSMSModal,
    openSMSTowModal,
    _case,
    reason,
    handleReason,
    clientType
  } = props;


  return (
    <>
      <br />
      <div className="light-gray-line"></div>
      <div className="buttons-container">
        <h6>SERVICIO SELECCIONADO</h6>
      </div>
      <div className="selected-service-container">
        {availableServices?.available?.map((s) => {
          if (s.selected) {
            return (
              <div className="col-12 col-md-4" key={s._id}>
                <ServiceButton selectService={unSelectService} service={s} />
              </div>
            );
          }
        })}

        {![afiliatedServices.crane, particularServices.crane].includes(
          availableServices?.selected?.code
        ) && isChangeService(_case) ? (
          availableServices?.selected?.code && (
            <SelectProvider
              bootstrap={'col-12 col-md-4'}
              label={'Elegir Motorizado:'}
              type={'select'}
              options={providersOptions}
              name={'motorizado'}
              placeholder={'Seleccione'}
              value={value}
              onChange={onChange}
            />
          )
        ) : (
          <div className="col-12 col-md-4">
            {(clientType === clientTypes.AFFILIATED || _case?.membership) &&
              <SelectionReason onChange={handleReason} name={"reason"} value={reason} label={"Motivo de selección de la grúa"} options={towReasonsOptions} />
            }
          </div>)}

        <div className="col-12 col-md-4 sms-button-logo-container">
          {value && isChangeService(_case) && (
            <img
              className="sms-button-logo"
              src={phone}
              alt="phone"
              onClick={() => openSMSModal()}
            />
          )}
          {[afiliatedServices.crane, particularServices.crane].includes(
            availableServices?.selected?.code
          ) && isChangeService(_case) && (
              <img
                className="sms-button-logo"
                src={phone}
                alt="phone"
                onClick={() => openSMSTowModal()}
              />
            )}
        </div>
      </div>
    </>
  );
};

export const HandleCaseButtons = (props) => {
  const {
    setShowSchedule,
    service,
    selectedBike,
    handleCancelService,
    _case,
    openModals,
    bikerData
  } = props;

  return (
    <div
      className="buttons-center-container"
      style={{ margin: '0px 0px 48px 0px' }}
    >
      <AltButton
        label={'Cancelar Servicio'}
        action={() => handleCancelService(_case)}
      />

      {[afiliatedServices.crane, particularServices.crane].includes(
        service?.code
      ) && isChangeService(_case) && (
          <AltButton
            label={'Agendar servicio'}
            action={() => setShowSchedule(true)}
          />
        )}
      {![afiliatedServices.crane, particularServices.crane].includes(
        service?.code
      ) && isChangeService(_case) &&
        selectedBike && (
          <AltButton
            label={'Agendar servicio'}
            action={() => setShowSchedule(true)}
          />
        )}

      {[afiliatedServices.crane, particularServices.crane].includes(
        service?.code
      ) && isChangeService(_case) && (
          <ActionButton
            label={'Iniciar'}
            action={() => openModals.openSMSTowModal()}
          />
        )}
      {!isChangeService(_case) && (
        <ActionButton
          label={'Editar Servicio'}
          action={() => openModals.handleEditService(_case)}
        />
      )}
      {selectedBike && isChangeService(_case) && (
        <ActionButton
          label={'Registrar Pago'}
          action={() => openModals.openSMSModal()}
          disabled={!bikerData?.isActive}
        />
      )}
    </div>
  );
};

export const IncidenceDetailForm = (props) => {
  const {
    register,
    errors,
    onChange,
    options,
    watch,
    openMapModal,
    _case,
    createCase,
    editCase,
    disabled,
  } = props;

  return (
    <>
      <div className="light-gray-line"></div>
      <div className="filter-button-container"></div>
      <div className="buttons-container">
        <div>
          <h6>DETALLES DE LA INCIDENCIA</h6>
          <p>(*) Campos obligatorios</p>
        </div>
      </div>
      {!_case ? (
        <CreateParticularCaseForm
          register={register}
          handleSubmit={createCase}
          errors={errors}
          onChange={onChange}
          options={options}
          watch={watch}
          openMapModal={openMapModal}
          _case={_case}
        />
      ) : (
        <EditParticularCaseForm
          register={register}
          handleSubmit={editCase}
          errors={errors}
          onChange={onChange}
          options={options}
          watch={watch}
          openMapModal={openMapModal}
          _case={_case}
          disabled={disabled}
        />
      )}
    </>
  );
};

export const ServiceHistory = (props) => {
  const { serviceHistory } = props;

  return (
    <>
      <br />
      <div className="light-gray-line"></div>
      <IncidenceTable
        title={'HISTORIAL DE SERVICIOS'}
        header={serviceHistoryTableheader}
        data={serviceHistory}
      />
    </>
  );
};

export const CaseDetail = (props) => {
  const { title, selectedCase } = props;
  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="buttons-container">
        <h6>{title}</h6>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Avería: </b> {selectedCase?.fault?.description} <br />
            <b>Caso Nro: </b> {selectedCase?.caseId}
            <br />
            {/* <b>Código de servicio: </b> CCCT15-0024435 */}
            <b>Operador: </b>
            {selectedCase?.user?.firstName} {selectedCase?.user?.lastName}
          </p>
        </div>
      </div>
    </div>
  );
};
