import {
  GoogleMap,
  Marker,
  InfoWindow
} from '@react-google-maps/api';
import craneLogo from '../../../images/craneLogo.png';
import { calculateMiddlePoint } from "../../../assets/helpers/funciones"
import './Map.scss';


export const AllDevicesMap = (props) => {
  const { data, showInfoWindow } = props;
  if (data?.length > 0) {
    return (
      <>
        <GoogleMap
          center={calculateMiddlePoint(data)}
          zoom={8}
          mapContainerStyle={{ width: '100%', height: '500px' }}
          options={{
            zoomControl: true,
            // streetViewControl: true,
            // mapTypeControl: true,
            fullscreenControl: true,
          }}
        >{data?.map((d, i) => (
          <Marker
            onClick={() => showInfoWindow(i)}
            position={{ lat: d?.lat, lng: d?.lng }}
            label={''}
            icon={{
              url: d?.icon?.path, scaledSize: new window.google.maps.Size(30, 30),
            }}
          >{d?.show && d?.name &&
            <InfoWindow>
              <div className='custom-info-window'>
                <p>{d?.name} </p>
              </div>
            </InfoWindow>
            }
          </Marker>
        ))
          }
        </GoogleMap>
      </>
    )
  } else {
    return null
  }
}
