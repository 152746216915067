import React, { useState, useEffect } from 'react';
import { FilterButton } from '../../common/Buttons/Buttons';
import { SearchInput } from '../../common/Forms/Input/Input.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { ProvidersTable } from '../../common/Tables/Tables';
import { useHistory } from 'react-router-dom';
import { Header } from '../../common/Header/Header';
import * as schemas from '../../../assets/schemas/schemas';
import { CreateTowServiceModal } from '../../common/Modals/Modals';
import {
  providersTableheader,
  searchInternalProviderTableHeader,
} from '../../../assets/helpers/tables';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { caseService } from '../../../services/caseService';
import { rateService } from '../../../services/rateService';
import { providersService } from '../../../services/providersService';
import { servicesService } from '../../../services/servicesService';
import { productService } from '../../../services/productService';
import { notify } from '../../../assets/helpers/toast';
import { ClientDetail, CaseDetail } from './IncidenceSections';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getProvidersEnum,
  PaymentStatusOptions,
  PaymentStatus,
  authorizedByOptions,
  userType
} from '../../../assets/helpers/options';
import { useTranslation } from 'react-i18next';
import { capitalize, filterParams } from '../../../assets/helpers/funciones';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import { CraneCounter } from '../../miscelaneos/CraneCounter/CraneCounter.jsx';
import './Incidence.scss';

const filterButtons = [
  { name: 'Propias', value: true },
  { name: 'Externas', value: false },
];

const itemsPerpage = [10, 25, 50, 100, 500];

export const AssingIncidence = () => {
  const { t } = useTranslation();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    getValues: getValues3,
    resetField: resetField3,
    watch: watch3,
  } = useForm({
    resolver: yupResolver(schemas.payTowServiceModal(t)),
  });
  const [dollarRate, setDollarRate] = useState('');
  const [openPayModal, setOpenPayModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState([]);
  const [selectedCase, setSelectedCase] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [bsDisabled, setbsDisabled] = useState(true);
  const [selectedProvider, setTelectedProvider] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [craneCounter, setCraneCounter] = useState([]);
  const [showCraneCounterModal, setShowCraneCounterModal] = useState({
    show: false,
    src: '',
    zoom: 1,
  });

  const [providersDataTable, setProvidersDataTable] = useState({
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    type: getProvidersEnum.CRANE,
    isInternal: true,
    isLoading: true,
    description: '',
  });

  let history = useHistory();
  let { caseId, serviceId } = useParams();

  const selectitemsPerpage = (event) => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const selectButton = (value) => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        isInternal: value,
      };
    });
  };

  const handlePageChange = (event, value) => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * providersDataTable?.limit,
      };
    });
  };

  const validateMaxPaymentMethods = () => {
    if (watch3('paymentMethods') > 5) {
      setValue3('paymentMethods', 5);
      notify('El limite permitido es de 5 métodos de pago', 'info');
    }
  };

  const setBsRate = (e) => {
    const { name, value } = e?.target;

    if (!dollarRate) {
      setbsDisabled(false);
    }

    if (name.includes('amount')) {
      setValue3(
        name.replace('amount', 'amountBsS'),
        (value * dollarRate).toFixed(2)
      );
    }
  };


  const getCaseById = (caseId) => {
    caseService
      .getCaseById(caseId)
      .then((response) => {
        if (response.status === 200) {
          setSelectedCase(response?.data?.case);
          getProviders(response?.data?.case);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getProviders = (_case) => {
    let params = {
      type: providersDataTable?.type,
      offset: providersDataTable?.offset,
      limit: providersDataTable?.limit,
      isInternal: providersDataTable?.isInternal,
      description: providersDataTable?.description,
      isActive: true,// Aqui va un condicional que dependiendo si es scheduled se ven los activos o no
    };

    if (_case?.scheduleDate) {
      delete params.isActive
    }

    providersService
      .getProvidersByType(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          setProvidersDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.providers,
              count: response?.data?.count,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  // If the payment method is set to 0 clear the form for the N payment methods
  const restorePaymentMethods = () => {
    if (parseFloat(watch3('pvp')) === 0) {
      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        resetField3(`method${i}`);
        resetField3(`reference${i}`);
        resetField3(`paymentMethod${i}`);
        resetField3(`amount${i}`);
        resetField3(`amountBsS${i}`);
      }
      resetField3('paymentMethods');
    }
    // setValue3()
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setbsDisabled(true);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const handleSearchChange = (e) => {
    const { value } = e?.target;
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        description: value,
        page: 1,
        offset: 0,
      };
    });
  };

  let loadPaymentForms = (params) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 0; i < parseInt(getValues3('paymentMethods')); i++) {
      payments.push({
        method: params[`method` + i.toString()],
        reference: params[`reference` + i.toString()],
        amount: params[`amount` + i.toString()],
        amountBsS: params[`amountBsS` + i.toString()],
      });
    }

    // "REmove unwanted params from JSON"
    let words = ['method', 'reference', 'amount', 'amountBsS', 'paymentMethod'];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const appendFile = (data) => {
    const formData = new FormData();
    formData.append('provider', data?.provider);
    formData.append('price', data?.price);
    formData.append('pvp', data?.pvp);
    formData.append('amountExcess', parseFloat(watch3('amountExcess')));
    formData.append('amountHandling', parseFloat(watch3('amountHandling')));
    formData.append('paymentStatus', data?.paymentStatus);
    if (data?.authorizedBy) {
      formData.append('authorizedBy', data?.authorizedBy);
    }
    if (data?.externalTowReason) {
      formData.append('externalTowReason', data?.externalTowReason);
    }

    // SI es cuentas por cobrar o NA no anexar datos ni comprobantes de pago

    if (
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      for (var i = 0; i < data?.payments.length; i++) {
        formData.append(`payments[${i}][amount]`, data?.payments[i].amount);
        formData.append(
          `payments[${i}][amountBsS]`,
          data?.payments[i].amountBsS
        );
        formData.append(`payments[${i}][method]`, data?.payments[i].method);
        formData.append(
          `payments[${i}][reference]`,
          data?.payments[i].reference
        );
      }

      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch3('paymentMethods')) &&
      parseFloat(watch3('paymentMethods')) > 0 &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }
    if (
      (parseFloat(watch3("pvp")) >= 0 || parseFloat(watch3("amountHandling")) >= 0 || parseFloat(watch3("amountExcess")) >= 0) && ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(watch3('paymentStatus'))
    ) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum !== (parseFloat(watch3('pvp')) + parseFloat(watch3('amountHandling')) + parseFloat(watch3('amountExcess')))) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }
    return formData;
  };

  const payTowService = handleSubmit3(async (data) => {
    // Update Endpoint
    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost);
    delete dataToPost?.contact;
    delete dataToPost?.vehicleBrand;
    delete dataToPost?.vehicleColor;
    delete dataToPost?.vehicleYear;
    delete dataToPost?.vehicleModel;
    delete dataToPost?.estimatedTime;
    delete dataToPost?.vehiclePlate;
    delete dataToPost?.case;

    dataToPost.product = '';
    let fileToPost = appendFile(filterParams(dataToPost));
    if (!isLoading && fileToPost) {
      setIsloading(true);
      servicesService
        .updateTowService(fileToPost, serviceId)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Servicio de grua creado con exito`, 'success');
            history.push('/services');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const assignTow = (provider) => {
    setTelectedProvider(provider);
    setValue3(
      'contact',
      (selectedCase?.membership?.customer?.phonePrefix ||
        selectedCase?.particularCustomer?.phonePrefix) +
      '-' +
      (selectedCase?.membership?.customer?.phoneNumber ||
        selectedCase?.particularCustomer?.phoneNumber)
    );
    setValue3(
      'vehicleYear',
      selectedCase?.membership?.vehicle?.year ||
      selectedCase?.particularCustomer?.vehicleYear
    );
    setValue3(
      'vehicleModel',
      capitalize(
        selectedCase?.membership?.vehicle?.model ||
        selectedCase?.particularCustomer?.vehicleModel?.model
      )
    );
    setValue3(
      'vehicleBrand',
      capitalize(
        selectedCase?.membership?.vehicle?.brand ||
        selectedCase?.particularCustomer?.vehicleColor
      )
    );
    setValue3(
      'vehicleColor',
      capitalize(
        selectedCase?.membership?.vehicle?.color ||
        selectedCase?.particularCustomer?.vehicleBrand?.brand
      )
    );
    setValue3(
      'vehiclePlate',
      selectedCase?.membership?.vehicle?.plate ||
      selectedCase?.particularCustomer?.vehiclePlate
    );
    setValue3(
      'vehiclePlate',
      selectedCase?.membership?.vehicle?.plate ||
      selectedCase?.particularCustomer?.vehiclePlate
    );
    setValue3('provider', provider?._id);

    if (providersDataTable?.isInternal) {
      setValue3("isInternal", userType?.INTERNO);
    } else {
      setValue3("isInternal", userType?.EXTERNO);
    }


    setOpenPayModal(true);
  };

  const validateService = () => {
    if (!serviceId || serviceId == 'undefined') {
      history.push('/services');
      notify('El caso no tien servicio asignado', 'error');
    }
  };

  const changePaymentStatus = () => {
    setValue3('authorizedBy', "");
    if (watch3('paymentStatus') === PaymentStatus?.COURTESY) {
      setValue3('pvp', 0);
      setValue3('amountHandling', 0);
      setValue3('amountExcess', 0);
      return null
    }
    if (watch3('paymentStatus') === PaymentStatus?.CUENTA_POR_COBRAR) {
      setValue3('pvp', 0);
      setValue3('amountHandling', 0);
      setValue3('amountExcess', 0);
      return null
    }
    if (watch3('paymentStatus') === PaymentStatus?.DE_CONTADO) {
      setValue3('pvp', 0);
      setValue3('amountHandling', 0);
      setValue3('amountExcess', 0);
      return null
    }
    if (watch3('paymentStatus') === PaymentStatus?.N_A) {
      setValue3('pvp', 0);
      setValue3('amountHandling', 0);
      setValue3('amountExcess', 0);
      return null
    } else {
      setValue3('pvp', '');
      setValue3('amountHandling', '');
      setValue3('amountExcess', '');
      return null
    }
  };

  const getInternalTowServices = () => {
    let params = {
      type: 'all',
    };

    providersService
      .getInternalTowServices(params)
      .then((response) => {
        if (response.status === 200) {
          setShowCraneCounterModal((prevState) => {
            return {
              ...prevState,
              data: response?.data,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const openDocumentModal = (src, service) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        show: !showCraneCounterModal?.show,
        src: !showCraneCounterModal?.src ? src : '',
        service: service,
      };
    });
  };

  const zoomDocumentModal = (zoom) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        zoom: zoom,
      };
    });
  };

  useEffect(() => {
    validateService();
    getCaseById(caseId);
    getPaymentMethods();
    getDollarRate();
  }, []);

  useEffect(() => {
    getCaseById(caseId);
    getInternalTowServices();
  }, [
    providersDataTable?.page,
    providersDataTable?.offset,
    providersDataTable?.limit,
    providersDataTable?.isInternal,
  ]);

  return (
    <>
      <CraneCounter
        showCraneCounterModal={showCraneCounterModal}
        openDocumentModal={openDocumentModal}
        zoomDocumentModal={zoomDocumentModal}
        craneCounter={craneCounter}
      />
      <LoadSpinner show={isLoading} />
      <Header />
      <CreateTowServiceModal
        show={openPayModal}
        register={register3}
        openPayModal={openPayModal}
        handleClose={() => setOpenPayModal()}
        payTowService={payTowService}
        errors={errors3}
        options={{
          paymentMethods: paymentMethods,
          PaymentStatusOptions: PaymentStatusOptions,
          authorizedByOptions: authorizedByOptions
        }}
        onChange={{
          restorePaymentMethods: restorePaymentMethods,
          setBsRate: setBsRate,
          validateMaxPaymentMethods: validateMaxPaymentMethods,
          changePaymentStatus: changePaymentStatus,
        }}
        watch={watch3}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
      />
      <div className="service-with-container container">
        <h1 className="title-component">Búsqueda de Proveedor</h1>
        <p>Incidencia {selectedCase?.caseId}</p>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="card">
              <ClientDetail
                membership={selectedCase?.membership}
                _case={selectedCase}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card">
              <CaseDetail
                title={'DETALLES DEL CASO'}
                selectedCase={selectedCase}
              />
            </div>
          </div>
        </div>{' '}
        <div className="buttons-container">
          <h5>PROVEEDORES DISPONIBLES {providersDataTable?.count}</h5>
        </div>
        <div className="breadcrumb-container">
          <div className="filter-button-area">
            {filterButtons?.map((f) => (
              <FilterButton
                key={f.name}
                button={f}
                selected={providersDataTable?.isInternal}
                selectButton={selectButton}
              />
            ))}
          </div>

          <div className="service-filter-buttons-container">
            <SelectPerPage
              label={'Registros por página'}
              options={itemsPerpage}
              value={providersDataTable?.limit}
              onChange={selectitemsPerpage}
            />
            <SearchInput
              placeholder={'Nombre, Apellido o Documento de Identidad'}
              width="360px"
              value={providersDataTable?.description}
              onChange={handleSearchChange}
              searchAction={getProviders}
            />
          </div>
        </div>
        <div className="card">
          <ProvidersTable
            externalHeader={providersTableheader}
            internalHeader={searchInternalProviderTableHeader}
            data={providersDataTable}
            assignTow={assignTow}
            handlePageChange={handlePageChange}
            internal={providersDataTable?.isInternal}
          />
        </div>
      </div>
    </>
  );
};
