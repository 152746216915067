import React from 'react';
import { Input, PaymentSection } from './Input/Input';
import * as forms from '../../../assets/forms/forms';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import {
  phoneCodes,
  locationOptions,
  addressEnum,
  PaymentStatus,
  addressStatus,
  IVA, paymentType, userType, externalTowReasonsOptions
} from '../../../assets/helpers/options';
import { capitalize, validateCourtesy } from '../../../assets/helpers/funciones';
import { operationsRoles, salesRoles } from '../../../assets/helpers/roles';
import './Form.scss';

export const LoginForm = (props) => {
  const { t, register, errors, action } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.login(t, register, errors).map((l, i) => (
          <Input
            key={i}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        {/* <span className="forgot-password-link">
          <NavLink to={'/forgot-password'}>Olvidé mi contraseña</NavLink>
        </span> */}
        <ActionButton action={action} label={'Ingresar'} />
      </div>
    </form>
  );
};

export const FollowingForm = (props) => {
  const { t, register, errors, action } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.following(t, register, errors).map((l, i) => (
          <Input
            key={i}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
            data={l}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <ActionButton action={action} label={'Aceptar'} />
      </div>
    </form>
  );
};

export const CancelCaseForm = (props) => {
  const { register, errors, action, cancel, cancelOptions } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Motivo de cancelación*'}
          type={'select'}
          options={cancelOptions}
          name={'cancelationReason'}
          register={register}
          placeholder={''}
          errors={errors?.cancelationReason?.message}
        />{' '}
        <Input
          bootstrap={'col-12'}
          label={'Observación'}
          type={'textarea'}
          name={'cancelationReasonDescription'}
          register={register}
          placeholder={''}
          errors={errors?.cancelationReasonDescription?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>¿ Esta seguro que desea cancelar el caso ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const FailedCaseForm = (props) => {
  const { register, errors, action, cancel, } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Motivo*'}
          type={'textarea'}
          name={'failedReason'}
          register={register}
          placeholder={''}
          errors={errors?.failedReason?.message}
        />{' '}
      </div>

      <div className="buttons-center-container">
        <h6>¿ Esta seguro que desea marcar el caso como fallido ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};


export const CancelServiceForm = (props) => {
  const { register, errors, action, cancel, cancelOptions } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Motivo de cancelación*'}
          type={'select'}
          options={cancelOptions}
          name={'cancellationReason'}
          register={register}
          placeholder={''}
          errors={errors?.cancellationReason?.message}
        />{' '}
        <Input
          bootstrap={'col-12'}
          label={'Observación'}
          type={'textarea'}
          name={'cancellationDescription'}
          register={register}
          placeholder={''}
          errors={errors?.cancellationDescription?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>¿ Esta seguro que desea cancelar el servicio ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};


export const FinishServiceForm = (props) => {
  const { register, errors, action, cancel, options, actionLabel } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Avería real *'}
          type={'select'}
          options={options}
          name={'providerFailureReason'}
          register={register}
          placeholder={''}
          errors={errors?.providerFailureReason?.message}
        />{' '}
        <Input
          bootstrap={'col-12'}
          label={'Explique: *'}
          type={'textarea'}
          name={'providerFailureDescription'}
          register={register}
          placeholder={''}
          errors={errors?.providerFailureDescription?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>¿ Esta seguro que desea finalizar el servicio ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={actionLabel} />
      </div>
    </form>
  );
};

export const FailureAssignForm = (props) => {
  const { register, errors, action, cancel, options, actionLabel } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12'}
          label={'Avería real *'}
          type={'select'}
          options={options}
          name={'providerFailureReason'}
          register={register}
          placeholder={''}
          errors={errors?.providerFailureReason?.message}
        />{' '}
        <Input
          bootstrap={'col-12'}
          label={'Explique: *'}
          type={'textarea'}
          name={'providerFailureDescription'}
          register={register}
          placeholder={''}
          errors={errors?.providerFailureDescription?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>¿ Esta seguro que desea actualizar el servicio ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={actionLabel} />
      </div>
    </form>
  );
};


export const ProvidersPaymenForm = (props) => {
  const { watch, modal, register, errors, action, cancel, options, onChange } = props;

  return (
    <form className="providers-payment-form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Número de Factura'}
          type={'text'}
          name={'billNumber'}
          register={register}
          placeholder={''}
          errors={errors?.billNumber?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Cantidad de métodos de pago *'}
          type={'select'}
          options={options?.paymentMethodsQuantity}
          name={'providerMethods'}
          register={register}
          placeholder={''}
          onChange={onChange?.handleMethodsChange}
          errors={errors?.providerMethods?.message}
        />

        <div className="col-12">
          <h6 style={{ textAlign: "center" }}>{modal?.affiliatedPayment ? `Monto de afiliados: ${modal?.affiliatedPayment}$` : ""}</h6>
          <h6 style={{ textAlign: "center" }}>{modal?.particularPayment ? `Monto de particulares: ${modal?.particularPayment}$` : ""}</h6>
          <h5 style={{ textAlign: "center" }}>Total a pagar <b>{watch("billNumber") ? (modal?.totalAmount * (100 + IVA) / 100)?.toFixed(2) : (modal?.totalAmount)?.toFixed(2)}$ </b> </h5>
          <p style={{ textAlign: "center", color: "red" }} >{watch("billNumber") ? `Incluye IVA ${IVA}% ` : ""}</p>
        </div>
        <br />

        {/* Methodo 1 */}
        <br />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Método de pago 1 *'}
          type={'select'}
          options={options?.paymentMethods}
          name={'method1'}
          register={register}
          placeholder={''}
          errors={errors?.method1?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Monto 1 *'}
          type={'number'}
          name={'amount1'}
          register={register}
          placeholder={''}
          onChange={() => onChange?.handleAmountChange("1")}
          errors={errors?.amount1?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Monto  1 BsS *'}
          type={'number'}
          name={'amountBsS1'}
          register={register}
          placeholder={''}
          errors={errors?.amountBsS1?.message}
        />
        {/* Methodo 2 */}
        {watch("providerMethods") > 1 &&
          <>
            <Input
              bootstrap={'col-12 col-md-4'}
              label={'Método de pago 2 *'}
              type={'select'}
              options={options?.paymentMethods}
              name={'method2'}
              register={register}
              placeholder={''}
              errors={errors?.method2?.message}
            />
            <br />
            <Input
              bootstrap={'col-12 col-md-4'}
              label={'Monto 2 *'}
              type={'number'}
              name={'amount2'}
              register={register}
              placeholder={''}
              onChange={() => onChange?.handleAmountChange("2")}
              errors={errors?.amount2?.message}
            />
            <Input
              bootstrap={'col-12 col-md-4'}
              label={'Monto 2 BsS *'}
              type={'number'}
              name={'amountBsS2'}
              register={register}
              placeholder={''}
              errors={errors?.amountBsS2?.message}
            />
          </>
        }
        <Input
          bootstrap={'col-12 '}
          label={'¿Deseas agregar alguna observación?'}
          type={'textarea'}
          name={'observation'}
          register={register}
          placeholder={''}
          errors={errors?.observation?.message}
        />
      </div>

      <div className="buttons-center-container">
        <h6>¿ Está seguro de gestionar este pago ?</h6>
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={"Si"} />
      </div>
    </form>
  );
};



export const NewCraneProviderForm = (props) => {
  const { t, register, errors, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .CreateExternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Ingresar'} />
      </div>
    </form>
  );
};

export const NewVehicularAssetForm = (props) => {
  const {
    control,
    register,
    errors,
    action,
    cancel,
    options,
    watch,
    onChange,
  } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Tipo *'}
          type={'select'}
          options={options?.vehicleClassesOptions}
          name={'class'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.class?.message}
          onChange={onChange?.getVehicleBrands}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Marca *'}
          type={'select'}
          options={options?.brandOptions}
          name={'brand'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.brand?.message}
          onChange={onChange?.getVehicleModels}
          disabled={!watch('class') || options?.brandOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Modelo *'}
          type={'select'}
          options={options?.modelOptions}
          name={'model'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.model?.message}
          disabled={!watch('brand') || options?.modelOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Placa *'}
          type={'text'}
          name={'plate'}
          placeholder={''}
          register={register}
          errors={errors?.plate?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Color *'}
          type={'text'}
          name={'color'}
          placeholder={''}
          register={register}
          errors={errors?.color?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Año *'}
          type={'number'}
          name={'year'}
          placeholder={''}
          register={register}
          errors={errors?.year?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Fecha de compra *'}
          type={'date'}
          name={'purchaseDate'}
          placeholder={''}
          register={register}
          errors={errors?.purchaseDate?.message}
          control={control}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Descripción'}
          type={'textarea'}
          name={'description'}
          placeholder={''}
          register={register}
          errors={errors?.description?.message}
        />
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Ingresar'} />
      </div>
    </form>
  );
};

export const EditVehicularAssetForm = (props) => {
  const {
    control,
    register,
    errors,
    action,
    cancel,
    options,
    watch,
    onChange,
  } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Tipo *'}
          type={'select'}
          options={options?.vehicleClassesOptions}
          name={'class'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.class?.message}
          onChange={(e) => onChange?.getVehicleBrands(e?.target?.value)}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Marca *'}
          type={'select'}
          options={options?.brandOptions}
          name={'brand'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.brand?.message}
          onChange={(e) => onChange?.getVehicleModels(e?.target?.value)}
          disabled={!watch('class') || options?.brandOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Modelo *'}
          type={'select'}
          options={options?.modelOptions}
          name={'model'}
          placeholder={'Seleccione'}
          register={register}
          errors={errors?.model?.message}
          disabled={!watch('brand') || options?.modelOptions?.length === 0}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Placa *'}
          type={'text'}
          name={'plate'}
          placeholder={''}
          register={register}
          errors={errors?.plate?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Color *'}
          type={'text'}
          name={'color'}
          placeholder={''}
          register={register}
          errors={errors?.color?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Año *'}
          type={'number'}
          name={'year'}
          placeholder={''}
          register={register}
          errors={errors?.year?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Fecha de compra *'}
          type={'date'}
          name={'purchaseDate'}
          placeholder={''}
          register={register}
          errors={errors?.purchaseDate?.message}
          control={control}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Descripción'}
          type={'textarea'}
          name={'description'}
          placeholder={''}
          register={register}
          errors={errors?.description?.message}
        />
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const EditExternalProviderForm = (props) => {
  const { t, register, errors, action, options, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .CreateExternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const EditInternalProviderForm = (props) => {
  const { t, register, errors, action, options, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .newInternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const NewVehicleForm = (props) => {
  const { t, control, register, errors, action } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.newVehicle(t, register, errors).map((l, i) => (
          <Input
            key={i}
            title={l?.title}
            bootstrap={l?.bootstrap}
            label={l?.label}
            type={l?.type}
            name={l?.name}
            register={l?.register}
            placeholder={l?.placeholder}
            errors={l?.errors}
            data={l}
            control={control}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={action} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const ApprovalPaymentForm = (props) => {
  const { t, control, register, errors, action, cancel } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms.approvalPaymentForm(t, register, errors).map((l, i) => (
          <Input
            key={i}
            title={l?.title}
            bootstrap={l?.bootstrap}
            label={l?.label}
            type={l?.type}
            name={l?.name}
            register={l?.register}
            placeholder={l?.placeholder}
            errors={l?.errors}
            data={l}
            control={control}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const CreateParticularCustomertForm = (props) => {
  const { t, register, errors, action, options, onChange, getValues, watch } =
    props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .CreateParticularCustomertForm(
            t,
            register,
            errors,
            options,
            onChange,
            getValues,
            watch
          )
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
              disabled={l?.disabled}
            />
          ))}
      </div>
      <div style={{ margin: '24px 0px' }} className="buttons-center-container">
        <AltButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const EditParticularCustomertForm = (props) => {
  const { register, errors, action, options, onChange, watch } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Nombre *'}
          type={'text'}
          name={'firstName'}
          register={register}
          placeholder={''}
          errors={errors?.firstName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Apellido *'}
          type={'text'}
          name={'lastName'}
          register={register}
          placeholder={''}
          errors={errors?.lastName?.message}
        />
        <Input
          bootstrap={'col-4 col-sm-2 col-md-1'}
          label={'Teléfono *'}
          type={'select'}
          name={'phonePrefix'}
          register={register}
          options={phoneCodes}
          placeholder={''}
          errors={errors?.phonePrefix?.message}
        />
        <Input
          bootstrap={'col-8 col-sm-4 col-md-3'}
          label={' '}
          type={'text'}
          name={'phoneNumber'}
          register={register}
          placeholder={''}
          errors={errors?.phoneNumber?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Marca del Vehículo *'}
          type={'select'}
          name={'vehicleBrand'}
          options={options?.vehicleBrandOptions}
          register={register}
          placeholder={''}
          onChange={() => onChange?.getVehicleModels(watch('vehicleBrand'))}
          errors={errors?.vehicleBrand?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Modelo del vehículo *'}
          type={'select'}
          name={'vehicleModel'}
          options={options?.vehicleModelOptions}
          register={register}
          placeholder={''}
          errors={errors?.vehicleModel?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Color del vehículo *'}
          type={'text'}
          name={'vehicleColor'}
          register={register}
          placeholder={''}
          errors={errors?.vehicleColor?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Año *'}
          type={'number'}
          name={'vehicleYear'}
          register={register}
          placeholder={''}
          errors={errors?.vehicleYear?.message}
        />
        <Input
          bootstrap={'col-12 col-md-4'}
          label={'Placa *'}
          type={'text'}
          name={'vehiclePlate'}
          register={register}
          placeholder={''}
          errors={errors?.vehiclePlate?.message}
        />
      </div>
      <div style={{ margin: '24px 0px' }} className="buttons-center-container">
        <AltButton action={action} label={'Editar'} />
      </div>
    </form>
  );
};

// export const ChargeForm = (props) => {
//   const { t, register, errors, action, cancel } = props;

//   return (
//     <form className="form-container">
//       <div className="row">
//         {forms.Charge(t, register, errors).map((l, i) => (
//           <Input
//             key={i}
//             title={l?.title}
//             bootstrap={l?.bootstrap}
//             label={l?.label}
//             type={l?.type}
//             name={l?.name}
//             register={l?.register}
//             placeholder={l?.placeholder}
//             errors={l?.errors}
//             data={l}
//           />
//         ))}
//       </div>
//       <div className="buttons-center-container">
//         <AltButton action={cancel} label={'Cancelar'} />
//         <ActionButton action={action} label={'Guardar'} />
//       </div>
//     </form>
//   );
// };

export const NewInternalProviderForm = (props) => {
  const { t, register, errors, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        {forms
          .newInternalProviderForm(t, register, errors, options)
          .map((l, i) => (
            <Input
              key={i}
              title={l?.title}
              bootstrap={l?.bootstrap}
              label={l?.label}
              type={l?.type}
              name={l?.name}
              register={l?.register}
              placeholder={l?.placeholder}
              errors={l?.errors}
              data={l}
            />
          ))}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </form>
  );
};

export const CardCaseInfoForm = (props) => {
  const { t, register, errors, action, cancel, options, control } = props;

  return (
    <form className="form-container">
      <div className="container" style={{ width: "100%" }}>
        <div className="row">
          {forms
            .carCaseInfo1(t, register, errors, options)
            .map((l, i) => (
              <Input
                key={i}
                title={l?.title}
                bootstrap={l?.bootstrap}
                label={l?.label}
                type={l?.type}
                name={l?.name}
                register={l?.register}
                placeholder={l?.placeholder}
                errors={l?.errors}
                data={l}
              />
            ))}
        </div>
        <div className="gray-line"></div>
        <div className="row">
          {forms
            .carCaseInfo2(t, register, errors, options, control)
            .map((l, i) => (
              <Input
                key={i}
                title={l?.title}
                bootstrap={l?.bootstrap}
                label={l?.label}
                type={l?.type}
                name={l?.name}
                register={l?.register}
                placeholder={l?.placeholder}
                errors={l?.errors}
                control={control}
                data={l}
              />
            ))}
        </div>
        <br />
        <div className="gray-line"></div>
        <br />
        <div className="row small-input-label">
          {forms
            .carCaseInfo3(t, register, errors, options)
            .map((l, i) => (
              <Input
                key={i}
                title={l?.title}
                bootstrap={l?.bootstrap}
                label={l?.label}
                type={l?.type}
                name={l?.name}
                register={l?.register}
                placeholder={l?.placeholder}
                errors={l?.errors}
                data={l}
              />
            ))}
        </div>
        <br />
        <div className="gray-line"></div>
        <br />
        <div className="row small-input-label">
          {forms
            .carCaseInfo4(t, register, errors, options)
            .map((l, i) => (
              <Input
                key={i}
                title={l?.title}
                bootstrap={l?.bootstrap}
                label={l?.label}
                type={l?.type}
                name={l?.name}
                register={l?.register}
                placeholder={l?.placeholder}
                errors={l?.errors}
                data={l}
              />
            ))}
        </div>
        <br />
        <div className="gray-line"></div>
        <br />
        <div className="row small-input-label">
          {forms
            .carCaseInfo5(t, register, errors, options)
            .map((l, i) => (
              <Input
                key={i}
                title={l?.title}
                bootstrap={l?.bootstrap}
                label={l?.label}
                type={l?.type}
                name={l?.name}
                register={l?.register}
                placeholder={l?.placeholder}
                errors={l?.errors}
                data={l}
              />
            ))}
        </div>
        <br />
        <div className="gray-line"></div>
        <br />
        <div className="row small-input-label">
          {forms
            .carCaseInfo6(t, register, errors, options)
            .map((l, i) => (
              <Input
                key={i}
                title={l?.title}
                bootstrap={l?.bootstrap}
                label={l?.label}
                type={l?.type}
                name={l?.name}
                register={l?.register}
                placeholder={l?.placeholder}
                errors={l?.errors}
                data={l}
              />
            ))}
        </div>
        <br />
        <div className="gray-line"></div>
        <br />
        <div className="row">
          {forms
            .carCaseInfo7(t, register, errors, options)
            .map((l, i) => (
              <Input
                key={i}
                title={l?.title}
                bootstrap={l?.bootstrap}
                label={l?.label}
                type={l?.type}
                name={l?.name}
                register={l?.register}
                placeholder={l?.placeholder}
                errors={l?.errors}
                data={l}
              />
            ))}
        </div>
        <div className="buttons-center-container">
          <AltButton action={cancel} label={'Cancelar'} />
          <ActionButton action={action} label={'Completar Formulario'} />
        </div>

      </div>
    </form>
  );
};


export const RegisterForm = (props) => {
  const { t, register, errors } = props;

  return (
    <form>
      <div className="row">
        {forms.register(t, register, errors).map((l, i) => (
          <Input
            key={l.name}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
          />
        ))}
      </div>
    </form>
  );
};

export const ScheduleServicesForm = (props) => {
  const { t, register, errors, action, control } = props;

  return (
    <form>
      <div className="row">
        {forms.scheduleService(t, register, errors).map((l, i) => (
          <Input
            key={l.name}
            data={l}
            bootstrap={l.bootstrap}
            label={l.label}
            type={l.type}
            name={l.name}
            register={l.register}
            placeholder={l.placeholder}
            errors={l.errors}
            control={control}
          />
        ))}
      </div>
      <div className="buttons-center-container">
        <ActionButton action={action} label={'Agendar servicio'} />
      </div>
    </form>
  );
};

export const CreateParticularCaseForm = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    onChange,
    options,
    watch,
    openMapModal,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-5'}
              label={'Dirección de origen *'}
              type={'select'}
              options={options?.addressOption}
              name={'originLocationStatus'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.originLocationStatus?.message}
              onChange={(e) => onChange?.selectGarage(e, 'originState')}
            />
            {
              <Input
                bootstrap={'col-4'}
                label={'Ubicacion *'}
                type={'select'}
                options={locationOptions}
                name={'isOriginBasement'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.isOriginBasement?.message}
                disabled={[addressEnum.GARAGE].includes(
                  watch('originLocationStatus')
                )}
              />
            }
            {[addressEnum.OWN, addressEnum.OTHER].includes(
              watch('originLocationStatus')
            ) && watch('isOriginBasement') === 'true' ? (
              <Input
                bootstrap={'col-3'}
                label={'Nivel *'}
                type={'number'}
                name={'basementLevel'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.basementLevel?.message}
              />
            ) : null}
          </div>
          <Input
            bootstrap={'col-12'}
            label={'Estado (Origen)*'}
            type={'select'}
            options={options?.statesOptions}
            name={'originState'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originState?.message}
            onChange={(e) => onChange?.getCities(e, 'originState')}
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Ciudad (Origen)*'}
            type={'select'}
            options={options?.originCitiesOptions}
            name={'originCity'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originCity?.message}
            onChange={(e) =>
              onChange?.forceRecalulateDistance(e, addressStatus?.ORIGIN)
            }
            disabled={
              !watch('originState') ||
              options?.originCitiesOptions.length === 0 ||
              [addressEnum.GARAGE].includes(watch('originLocationStatus'))
            }
          />
          <Input
            bootstrap={'col-12'}
            label={'Dirección (Origen)*'}
            type={'textarea'}
            name={'originAddress'}
            placeholder={''}
            register={register}
            errors={errors?.originAddress?.message}
            onChange={(e) =>
              onChange?.forceRecalulateDistance(e, addressStatus?.ORIGIN)
            }
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Punto de referencia (Origen)'}
            type={'textarea'}
            name={'originReferencePoint'}
            placeholder={''}
            register={register}
            errors={errors?.originReferencePoint?.message}
          />{' '}
        </div>
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-3'}
              label={'Teléfono *'}
              type={'select'}
              options={phoneCodes}
              name={'contactNumberPrefix'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumberPrefix?.message}
            />{' '}
            <Input
              bootstrap={'col-9'}
              label={''}
              type={'number'}
              name={'contactNumber'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumber?.message}
            />
            <Input
              bootstrap={'col-12'}
              label={'Avería reportada *'}
              type={'select'}
              options={options?.faultsOptions}
              name={'customerFailureReason'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureReason?.message}
            />
            <Input
              bootstrap={'col-12'}
              label={'Comentario adicional (Avería)'}
              type={'textarea'}
              name={'customerFailureDescription'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureDescription?.message}
            />
            <Input
              bootstrap={'col-12'}
              subLabel="Sin dirección destino"
              type={'checkbox'}
              name={'withOutDestinyAddress'}
              placeholder={''}
              register={register}
              errors={errors?.withOutDestinyAddress?.message}
              onChange={onChange?.clearDestinyData}
            />
          </div>
        </div>
        {!watch('withOutDestinyAddress') && (
          <div className="col-12 col-md-4">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Dirección de Destino *'}
                type={'select'}
                options={options?.addressOption}
                name={'destinyLocationStatus'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyLocationStatus?.message}
                onChange={(e) => onChange?.selectGarage(e, 'destinyState')}
              />
              <Input
                bootstrap={'col-12'}
                label={'Estado (Destino)*'}
                type={'select'}
                options={options?.statesOptions}
                name={'destinyState'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyState?.message}
                onChange={(e) => onChange?.getCities(e, 'destinyState')}
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />
              <Input
                bootstrap={'col-12'}
                label={'Ciudad (Destino)*'}
                type={'select'}
                options={options?.destinycitiesOptions}
                name={'destinyCity'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyCity?.message}
                onChange={(e) =>
                  onChange?.forceRecalulateDistance(e, addressStatus?.DESTINY)
                }
                disabled={
                  !watch('destinyState') ||
                  options?.destinycitiesOptions.length === 0 ||
                  [addressEnum.GARAGE].includes(watch('destinyLocationStatus'))
                }
              />
              <Input
                bootstrap={'col-12'}
                label={'Dirección (Destino)*'}
                type={'textarea'}
                name={'destinyAddress'}
                placeholder={''}
                register={register}
                errors={errors?.destinyAddress?.message}
                onChange={(e) =>
                  onChange?.forceRecalulateDistance(e, addressStatus?.DESTINY)
                }
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />
              <Input
                bootstrap={'col-12'}
                label={'Punto de referencia (Destino)'}
                type={'textarea'}
                name={'destinyReferencePoint'}
                placeholder={''}
                register={register}
                errors={errors?.destinyReferencePoint?.message}
              />{' '}
              <div className="buttons-center-container">
                {/* <AltButton label={'Ajustar ubicación'} /> */}
                <AltButton label={'Calcular distancia'} action={openMapModal} />
              </div>
              <Input
                bootstrap={'col-12'}
                label={'Distancia de origen a destino (Km)*'}
                type={'number'}
                name={'caseKm'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.caseKm?.message}
                disabled={true}
              />
            </div>
          </div>
        )}
        <div
          className="buttons-center-container"
          style={{ margin: '0px 0px 48px 0px' }}
        >
          <AltButton label={'Guardar'} action={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export const EditParticularCaseForm = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    onChange,
    options,
    watch,
    openMapModal,
    disabled,
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-5'}
              label={'Dirección de origen*'}
              type={'select'}
              options={options?.addressOption}
              name={'originLocationStatus'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.originLocationStatus?.message}
              onChange={(e) => onChange?.selectGarage(e, 'originState')}
            />

            <Input
              bootstrap={'col-4'}
              label={'Ubicacion*'}
              type={'select'}
              options={locationOptions}
              name={'isOriginBasement'}
              placeholder={'Seleccione'}
              register={register}
              errors={errors?.isOriginBasement?.message}
              disabled={watch('originLocationStatus') === addressEnum.GARAGE}
            />
            {[addressEnum.OWN, addressEnum.OTHER].includes(
              watch('originLocationStatus')
            ) && watch('isOriginBasement') === 'true' ? (
              <Input
                bootstrap={'col-3'}
                label={'Nivel *'}
                type={'number'}
                name={'basementLevel'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.basementLevel?.message}
              />
            ) : null}
          </div>
          <Input
            bootstrap={'col-12'}
            label={'Estado (Origen)*'}
            type={'select'}
            options={options?.statesOptions}
            name={'originState'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originState?.message}
            onChange={(e) => onChange?.getCities(e, 'originState')}
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Ciudad (Origen)*'}
            type={'select'}
            options={options?.originCitiesOptions}
            name={'originCity'}
            placeholder={'Seleccione'}
            register={register}
            errors={errors?.originCity?.message}
            onChange={(e) => onChange?.forceRecalulateDistance(e)}
            disabled={
              !watch('originState') ||
              options?.originCitiesOptions.length === 0 ||
              [addressEnum.GARAGE].includes(watch('originLocationStatus'))
            }
          />
          <Input
            bootstrap={'col-12'}
            label={'Dirección (Origen)*'}
            type={'textarea'}
            name={'originAddress'}
            placeholder={''}
            register={register}
            errors={errors?.originAddress?.message}
            onChange={(e) => onChange?.forceRecalulateDistance(e)}
            disabled={[addressEnum.GARAGE].includes(
              watch('originLocationStatus')
            )}
          />
          <Input
            bootstrap={'col-12'}
            label={'Punto de referencia (Origen)'}
            type={'textarea'}
            name={'originReferencePoint'}
            placeholder={''}
            register={register}
            errors={errors?.originReferencePoint?.message}
          />{' '}
        </div>
        <div className="col-12 col-md-4">
          <div className="row">
            <Input
              bootstrap={'col-3'}
              label={'Teléfono *'}
              type={'select'}
              options={phoneCodes}
              name={'contactNumberPrefix'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumberPrefix?.message}
            />{' '}
            <Input
              bootstrap={'col-9'}
              label={''}
              type={'number'}
              name={'contactNumber'}
              placeholder={''}
              register={register}
              errors={errors?.contactNumber?.message}
            />
            <Input
              bootstrap={'col-12'}
              label={'Avería reportada *'}
              type={'select'}
              options={options?.faultsOptions}
              name={'customerFailureReason'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureReason?.message}
              disabled={disabled}
            />
            <Input
              bootstrap={'col-12'}
              label={'Comentario adicional (Avería)'}
              type={'textarea'}
              name={'customerFailureDescription'}
              placeholder={''}
              register={register}
              errors={errors?.customerFailureDescription?.message}
            />
            <Input
              bootstrap={'col-12'}
              subLabel="Sin dirección destino"
              type={'checkbox'}
              name={'withOutDestinyAddress'}
              placeholder={''}
              register={register}
              errors={errors?.withOutDestinyAddress?.message}
              onChange={onChange?.clearDestinyData}
            />
          </div>
        </div>
        {!watch('withOutDestinyAddress') && (
          <div className="col-12 col-md-4">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Dirección de Destino *'}
                type={'select'}
                options={options?.addressOption}
                name={'destinyLocationStatus'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyLocationStatus?.message}
                onChange={(e) => onChange?.selectGarage(e, 'destinyState')}
              />
              <Input
                bootstrap={'col-12'}
                label={'Estado (Destino)*'}
                type={'select'}
                options={options?.statesOptions}
                name={'destinyState'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyState?.message}
                onChange={(e) => onChange?.getCities(e, 'destinyState')}
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />
              <Input
                bootstrap={'col-12'}
                label={'Ciudad (Destino)*'}
                type={'select'}
                options={options?.destinycitiesOptions}
                name={'destinyCity'}
                placeholder={'Seleccione'}
                register={register}
                errors={errors?.destinyCity?.message}
                onChange={(e) => onChange?.forceRecalulateDistance(e)}
                disabled={
                  !watch('destinyState') ||
                  options?.destinycitiesOptions.length === 0 ||
                  [addressEnum.GARAGE].includes(watch('destinyLocationStatus'))
                }
              />
              <Input
                bootstrap={'col-12'}
                label={'Dirección (Destino)*'}
                type={'textarea'}
                name={'destinyAddress'}
                placeholder={''}
                register={register}
                errors={errors?.destinyAddress?.message}
                onChange={(e) => onChange?.forceRecalulateDistance(e)}
                disabled={[addressEnum.GARAGE].includes(
                  watch('destinyLocationStatus')
                )}
              />
              <Input
                bootstrap={'col-12'}
                label={'Punto de referencia (Destino)'}
                type={'textarea'}
                name={'destinyReferencePoint'}
                placeholder={''}
                register={register}
                errors={errors?.destinyReferencePoint?.message}
              />{' '}
              <div className="buttons-center-container">
                {/* <AltButton label={'Ajustar ubicación'} /> */}
                <AltButton label={'Calcular distancia'} action={openMapModal} />
              </div>
              <Input
                bootstrap={'col-12'}
                label={'Distancia de origen a destino (Km)*'}
                type={'number'}
                name={'caseKm'}
                placeholder={''}
                register={register}
                min={1}
                errors={errors?.caseKm?.message}
                disabled={true}
              />
            </div>
          </div>
        )}
        <div
          className="buttons-center-container"
          style={{ margin: '0px 0px 48px 0px' }}
        >
          <AltButton label={'Editar'} action={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export const CreateSalesUserForm = (props) => {
  const { register, errors, action, cancel, options, onChange, watch } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Nombre*'}
          type={'text'}
          name={'firstName'}
          register={register}
          placeholder={''}
          errors={errors?.firstName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Apellido *'}
          type={'text'}
          name={'lastName'}
          register={register}
          placeholder={''}
          errors={errors?.lastName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Cedula*'}
          type={'number'}
          name={'identification'}
          register={register}
          placeholder={''}
          errors={errors?.identification?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Email *'}
          type={'text'}
          name={'email'}
          register={register}
          placeholder={''}
          errors={errors?.email?.message}
        />
        <div className="col-md-6"></div>
        <div className="col-12 col-md-6">
          <p>
            La contraseña debe:
            <li style={{ fontSize: '12px' }}>
              Tener un <b>minimo de 8 caracteres</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener <b>1 mayuscula</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>2 numeros</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>1 caracter especial</b>
            </li>
          </p>
        </div>
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Contraseña *'}
          type={'password'}
          name={'password'}
          register={register}
          placeholder={''}
          errors={errors?.password?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Confirmar contraseña *'}
          type={'password'}
          name={'confirmPassword'}
          register={register}
          placeholder={''}
          errors={errors?.confirmPassword?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Modulo *'}
          type={'select'}
          name={'module'}
          options={options?.modulesOptions}
          register={register}
          placeholder={''}
          errors={errors?.module?.message}
          onChange={onChange?.getRoles}
          disabled={true}
        />{' '}
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Rol *'}
          type={'select'}
          options={options?.rolesOptions}
          name={'role'}
          register={register}
          placeholder={''}
          errors={errors?.role?.message}
          disabled={options?.rolesOptions.length <= 0}
          onChange={onChange?.getProviders}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Estatus *'}
          type={'select'}
          name={'status'}
          options={options?.userStatusOptions}
          register={register}
          placeholder={''}
          errors={errors?.status?.message}
        />{' '}
        {watch('role') === salesRoles?.SALES_COUNTER && (
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Tipo *'}
            type={'select'}
            name={'type'}
            options={options?.userTypeOptions}
            register={register}
            placeholder={''}
            errors={errors?.type?.message}
          />
        )}
        {[operationsRoles?.OPERATIONS_FLOTA_AV, operationsRoles?.OPERATIONS_FLOTA_GRUA]?.includes(watch('role')) &&
          <Input
            bootstrap={'col-12 col-md-6'}
            label={'Proveedor *'}
            type={'select'}
            name={'provider'}
            options={options?.providersOptions}
            register={register}
            placeholder={''}
            errors={errors?.provider?.message}
          />
        }
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const EditSalesUserForm = (props) => {
  const { register, errors, action, cancel, options } = props;

  return (
    <form className="form-container">
      <div className="row">
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Nombre*'}
          type={'text'}
          name={'firstName'}
          register={register}
          placeholder={''}
          errors={errors?.firstName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Apellido *'}
          type={'text'}
          name={'lastName'}
          register={register}
          placeholder={''}
          errors={errors?.lastName?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Cedula*'}
          type={'number'}
          name={'identification'}
          register={register}
          placeholder={''}
          errors={errors?.identification?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Email *'}
          type={'text'}
          name={'email'}
          register={register}
          placeholder={''}
          errors={errors?.email?.message}
        />
        <div className="col-md-6"></div>
        <div className="col-12 col-md-6">
          <p>
            La contraseña debe:
            <li style={{ fontSize: '12px' }}>
              Tener un <b>minimo de 8 caracteres</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener <b>1 mayuscula</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>2 numeros</b>
            </li>
            <li style={{ fontSize: '12px' }}>
              Tener un <b>1 caracter especial</b>
            </li>
          </p>
        </div>
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Contraseña *'}
          type={'password'}
          name={'password'}
          register={register}
          placeholder={''}
          errors={errors?.password?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Confirmar contraseña *'}
          type={'password'}
          name={'confirmPassword'}
          register={register}
          placeholder={''}
          errors={errors?.confirmPassword?.message}
        />
        <Input
          bootstrap={'col-12 col-md-6'}
          label={'Estatus *'}
          type={'select'}
          name={'status'}
          options={options?.userStatusOptions}
          register={register}
          placeholder={''}
          errors={errors?.status?.message}
        />{' '}
      </div>

      <div className="buttons-center-container">
        {/* <h6>¿ Esta seguro que desea cancelar el servicio ?</h6> */}
      </div>
      <div className="buttons-center-container">
        <AltButton action={cancel} label={'No'} />
        <ActionButton action={action} label={'Si'} />
      </div>
    </form>
  );
};

export const CreateAVForm = (props) => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-6',
        errors: errors?.[`reference` + i]?.message,
      });
      array.push({
        // name: 'paymentMethod' + i,
        // label: `Comprobante ${i + 1} *`,
        // type: 'text',
        bootstrap: 'col-6',
        // errors: errors?.[`paymentMethod` + i]?.message,
      });
      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter((a) => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Información Adicional</h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Teléfono del Cliente *'}
                type={'text'}
                options={options?.brandOptions}
                name={'contact'}
                register={register}
                placeholder={''}
                errors={errors?.contact?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Año del Vehículo *'}
                type={'text'}
                name={'vehicleYear'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleYear?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Marca del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleBrand'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleBrand?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Modelo del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleModel'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleModel?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Placa del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehiclePlate'}
                register={register}
                placeholder={''}
                errors={errors?.vehiclePlate?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Color del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleColor'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleColor?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Tiempo de Llegada (min) *'}
                type={'text'}
                name={'estimatedTime'}
                register={register}
                placeholder={''}
                errors={errors?.estimatedTime?.message}
                disabled={true}
              />{' '}
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-6'}
                label={'Estatus de Pago *'}
                type={'select'}
                options={options?.PaymentStatusOptions}
                name={'paymentStatus'}
                register={register}
                placeholder={''}
                onChange={onChange?.changePaymentStatus}
                errors={errors?.paymentStatus?.message}
              />
              {validateCourtesy(watch("paymentStatus"), PaymentStatus?.COURTESY) &&
                <Input
                  bootstrap={'col-6'}
                  label={'Autorizado por *'}
                  type={'select'}
                  options={options?.authorizedByOptions}
                  name={'authorizedBy'}
                  register={register}
                  placeholder={''}
                  errors={errors?.authorizedBy?.message}
                />
              }
              <Input
                bootstrap={'col-12'}
                label={'Costo del servicio seleccionado USD *'}
                type={'number'}
                options={options?.brandOptions}
                name={'price'}
                register={register}
                placeholder={''}
                errors={errors?.price?.message}
                min={'0'}
              />
              <Input
                bootstrap={'col-12'}
                label={'Monto que paga el cliente USD *'}
                type={'number'}
                name={'pvp'}
                register={register}
                placeholder={''}
                errors={errors?.pvp?.message}
                min={'0'}
                onChange={onChange?.restorePaymentMethods}
                disabled={[PaymentStatus?.N_A, PaymentStatus?.COURTESY].includes(watch('paymentStatus'))}
              />
              {watch('pvp') > 0 &&
                ![
                  PaymentStatus?.N_A,
                  PaymentStatus?.CUENTA_POR_COBRAR,
                ].includes(watch('paymentStatus')) && (
                  <Input
                    bootstrap={'col-12'}
                    label={'Cantidad de Métodos de Pago'}
                    type={'number'}
                    name={'paymentMethods'}
                    register={register}
                    placeholder={''}
                    errors={errors?.paymentMethods?.message}
                    options={options?.paymentMethods}
                    onChange={onChange?.validateMaxPaymentMethods}
                    min={'1'}
                    max={'5'}
                  />
                )}
              {watch('paymentMethods') > 0 &&
                watch('pvp') > 0 &&
                ![
                  PaymentStatus?.N_A,
                  PaymentStatus?.CUENTA_POR_COBRAR,
                ].includes(watch('paymentStatus')) && (
                  <>
                    {paymentMethods().map((p, index) => (
                      <PaymentSection
                        key={p?.label}
                        payment={p}
                        watch={watch}
                        options={options}
                        register={register}
                        index={p?.index}
                        returnAccountData={returnAccountData}
                        fileUploaded={fileUploaded}
                        setFileUploaded={setFileUploaded}
                        onChange={onChange}
                      />
                    ))}
                  </>
                )}
            </div>
          </div>
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const CreatePaymentForm = (props) => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    control,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-6',
        errors: errors?.[`reference` + i]?.message,
      });
      array.push({
        // name: 'paymentMethod' + i,
        // label: `Comprobante ${i + 1} *`,
        // type: 'text',
        bootstrap: 'col-6',
        // errors: errors?.[`paymentMethod` + i]?.message,
      });
      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter((a) => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };


  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Información Adicional</h2>
      </div>
      <p style={{ margin: '0px' }}>(*) Informacion de pago</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-12'}
                label={'Servicio *'}
                type={'text'}
                name={'serviceName'}
                register={register}
                placeholder={''}
                errors={errors?.serviceName?.message}
                disabled={true}
              />
              {
                watch("paid") &&
                <div className="col-12 pending-paiment-success-message">
                  <h6>Pago Pendiente Realizado</h6>
                </div>
              }
              <Input
                bootstrap={'col-12'}
                label={'Estatus de Pago *'}
                type={'select'}
                options={options?.PaymentStatusOptions}
                name={'paymentStatus'}
                register={register}
                placeholder={''}
                onChange={onChange?.changePaymentStatus}
                errors={errors?.paymentStatus?.message}
                disabled={watch("paymentStatus") === paymentType?.PENDING}
              />
              {validateCourtesy(watch("paymentStatus"), PaymentStatus?.COURTESY) &&
                <Input
                  bootstrap={'col-12'}
                  label={'Autorizado por *'}
                  type={'select'}
                  options={options?.authorizedByOptions}
                  name={'authorizedBy'}
                  register={register}
                  placeholder={''}
                  errors={errors?.authorizedBy?.message}
                />
              }
              <Input
                bootstrap={'col-12'}
                label={'Costo del servicio seleccionado USD *'}
                type={'number'}
                options={options?.brandOptions}
                name={'price'}
                register={register}
                placeholder={''}
                errors={errors?.price?.message}
                min={'0'}
              />
              <Input
                bootstrap={'col-12'}
                label={'Monto que paga el cliente USD *'}
                type={'number'}
                name={'pvp'}
                register={register}
                placeholder={''}
                errors={errors?.pvp?.message}
                min={'0'}
                onChange={onChange?.restorePaymentMethods}
                disabled={watch('paymentStatus') === PaymentStatus?.N_A}
              />

              {watch("serviceName") === "Grua" && <>
                <Input
                  bootstrap={'col-12'}
                  label={'Monto que paga el cliente (Maniobra) USD*'}
                  type={'number'}
                  name={'amountHandling'}
                  register={register}
                  placeholder={''}
                  errors={errors?.amountHandling?.message}
                  min={'0'}
                  onChange={onChange?.restorePaymentMethods}
                  disabled={[PaymentStatus?.N_A, PaymentStatus?.COURTESY]?.includes(watch('paymentStatus'))}
                />
                <Input
                  bootstrap={'col-12'}
                  label={'Monto que paga el cliente (Excedente) USD*'}
                  type={'number'}
                  name={'amountExcess'}
                  register={register}
                  placeholder={''}
                  errors={errors?.amountExcess?.message}
                  min={'0'}
                  onChange={onChange?.restorePaymentMethods}
                  disabled={[PaymentStatus?.N_A, PaymentStatus?.COURTESY]?.includes(watch('paymentStatus'))}
                />
              </>}
              <h6>
                {watch('accumulatedAmount') > 0 &&
                  `El cliente tiene un pago acumulado de ${watch(
                    'accumulatedAmount'
                  )}$`}
                <br />
                <br />
                <span style={{ color: 'red' }}>
                  {watch('paymentDifference') > 0 &&
                    `Debe pagar una diferencia de ${watch(
                      'paymentDifference'
                    )}$`}
                </span>
              </h6>
            </div>
          </div>

          {(watch('paymentDifference') > 0 || [
            PaymentStatus?.CUENTA_POR_COBRAR,
          ].includes(watch('paymentStatus'))) && (
              <div className="col-6">
                <div className="row">
                  {watch('paymentMethods') > 0 &&
                    <Input
                      bootstrap={'col-12'}
                      label={'Fecha de Pago'}
                      type={'date'}
                      name={'paymentDate'}
                      register={register}
                      control={control}
                      placeholder={''}
                      errors={errors?.paymentDate?.message}
                    />
                  }
                  {(watch('pvp') > 0 || watch('amountHandling') > 0 || watch('amountExcess') > 0) &&
                    ![
                      PaymentStatus?.N_A,
                    ].includes(watch('paymentStatus')) && (
                      <Input
                        bootstrap={'col-12'}
                        label={'Cantidad de Métodos de Pago'}
                        type={'number'}
                        name={'paymentMethods'}
                        register={register}
                        placeholder={''}
                        errors={errors?.paymentMethods?.message}
                        options={options?.paymentMethods}
                        onChange={onChange?.validateMaxPaymentMethods}
                        min={'0'}
                        max={'2'}
                      />
                    )}

                  {watch('paymentMethods') > 0 &&
                    (watch('pvp') > 0 || watch('amountHandling') > 0 || watch('amountExcess') > 0) &&
                    ![
                      PaymentStatus?.N_A,
                    ].includes(watch('paymentStatus')) && (
                      <>
                        {paymentMethods().map((p, index) => (
                          <PaymentSection
                            key={p?.label}
                            payment={p}
                            watch={watch}
                            options={options}
                            register={register}
                            index={p?.index}
                            returnAccountData={returnAccountData}
                            fileUploaded={fileUploaded}
                            setFileUploaded={setFileUploaded}
                            onChange={onChange}
                          />
                        ))}
                      </>
                    )}
                </div>
              </div>
            )}
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        <ActionButton action={action} label={'Guardar'} />
      </div>
    </>
  );
};

export const CreateTowForm = (props) => {
  const {
    register,
    options,
    errors,
    cancel,
    action,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    selectedProvider,
    bsDisabled,
  } = props;

  const validateIfcanShow = () => {

    if (parseFloat(watch("pvp")) >= 0 || parseFloat(watch("amountHandling")) >= 0 || parseFloat(watch("amountExcess")) >= 0) {
      return true
    } else return false
  }

  const paymentMethods = () => {
    let array = [];
    for (let i = 0; i < parseInt(watch('paymentMethods')); i++) {
      array.push({
        name: 'method' + i,
        label: `Metodo ${i + 1} *`,
        type: 'select',
        bootstrap: 'col-7',
        errors: errors?.[`method` + i]?.message,
      });
      array.push({
        name: 'reference' + i,
        label: `Referencia ${i + 1} *`,
        type: 'text',
        bootstrap: 'col-6',
        errors: errors?.[`reference` + i]?.message,
      });
      array.push({
        // name: 'paymentMethod' + i,
        // label: `Comprobante ${i + 1} *`,
        // type: 'text',
        bootstrap: 'col-6',
        // errors: errors?.[`paymentMethod` + i]?.message,
      });
      array.push({
        name: 'amount' + i,
        label: `Monto ${i + 1} (USD) *`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amount` + i]?.message,
      });
      array.push({
        name: 'amountBsS' + i,
        label: `Monto ${i + 1} (Bs)*`,
        type: 'number',
        bootstrap: 'col-6',
        errors: errors?.[`amountBsS` + i]?.message,
        disabled: bsDisabled,
      });

      array.push({
        dropzone: 'a',
        label: `Comprobante ${i + 1} *`,
        index: i,
      });
    }
    return array;
  };

  const returnAccountData = (paymentMethods, id) => {
    let [account] = paymentMethods.filter((a) => a._id === id);

    let showInfo = (text, value) => {
      if (value) {
        return text + value + '\n';
      } else return '';
    };

    let data = '';
    data =
      showInfo(``, capitalize(account?.name)) +
      showInfo(`Cuenta: `, account?.details?.account) +
      showInfo(`Banco: `, account?.details?.bank) +
      showInfo(`Numero de Cuenta: `, account?.details?.accountNumber) +
      showInfo(`Numero de Telefono: `, account?.details?.phone) +
      showInfo(`Cedula: `, account?.details?.rif);
    return data;
  };

  const sumExcess = () => {
    let pvp = parseFloat(watch('pvp')) || 0
    let amountExcess = parseFloat(watch('amountExcess')) || 0
    let amountHandling = parseFloat(watch('amountHandling')) || 0
    return pvp + amountExcess + amountHandling
  }

  const validateIfCanPay = (action) => {
    if ([PaymentStatus?.N_A, PaymentStatus?.COURTESY, PaymentStatus?.CUENTA_POR_COBRAR].includes(watch('paymentStatus'))) {
      return <ActionButton action={action} label={'Guardar'} />
    }

    if (![PaymentStatus?.N_A, PaymentStatus?.COURTESY, PaymentStatus?.CUENTA_POR_COBRAR].includes(watch('paymentStatus')) && validateIfcanShow() && fileUploaded[0]) {
      return <ActionButton action={action} label={'Guardar'} />
    }

    else return null
  }

  return (
    <>
      <div style={{ margin: '0px' }} className="buttons-container">
        <h2 style={{ margin: '0px' }}>Información Adicional</h2>
      </div>
      <p style={{ marginTop: '12px' }}>
        <b>Proveedor: </b> {selectedProvider?.contactName} &nbsp;&nbsp;&nbsp;
        <b>Contacto: </b>
        {selectedProvider?.phonePrefix}-{selectedProvider?.phoneNumber}
      </p>
      <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
      <form style={{ maxWidth: '700px' }} className="form-container">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <Input
                bootstrap={'col-'}
                label={'Tipo de Grua *'}
                type={'text'}
                name={'isInternal'}
                register={register}
                placeholder={''}
                errors={errors?.isInternal?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Teléfono del Cliente *'}
                type={'text'}
                name={'contact'}
                register={register}
                placeholder={''}
                errors={errors?.contact?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Año del Vehículo *'}
                type={'text'}
                name={'vehicleYear'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleYear?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Marca del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleBrand'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleBrand?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Modelo del Vehiculo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleModel'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleModel?.message}
                disabled={true}
              />{' '}
              <Input
                bootstrap={'col-12'}
                label={'Placa del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehiclePlate'}
                register={register}
                placeholder={''}
                errors={errors?.vehiclePlate?.message}
                disabled={true}
              />
              <Input
                bootstrap={'col-12'}
                label={'Color del Vehículo *'}
                type={'text'}
                options={options?.brandOptions}
                name={'vehicleColor'}
                register={register}
                placeholder={''}
                errors={errors?.vehicleColor?.message}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              {watch("isInternal") === userType?.EXTERNO &&
                <Input
                  bootstrap={'col-12'}
                  label={'Motivo de Selección de Grúa externa *'}
                  type={'select'}
                  name={'externalTowReason'}
                  options={externalTowReasonsOptions}
                  register={register}
                  placeholder={''}
                  errors={errors?.externalTowReason?.message}
                />
              }
              <Input
                bootstrap={'col-6'}
                label={'Estatus de Pago *'}
                type={'select'}
                options={options?.PaymentStatusOptions}
                name={'paymentStatus'}
                register={register}
                placeholder={''}
                onChange={onChange?.changePaymentStatus}
                errors={errors?.paymentStatus?.message}
              />{' '}

              {validateCourtesy(watch("paymentStatus"), PaymentStatus?.COURTESY) &&
                <Input
                  bootstrap={'col-6'}
                  label={'Autorizado por *'}
                  type={'select'}
                  options={options?.authorizedByOptions}
                  name={'authorizedBy'}
                  register={register}
                  placeholder={''}
                  errors={errors?.authorizedBy?.message}
                />
              }
              <>
                <Input
                  bootstrap={'col-12'}
                  label={'Costo del servicio seleccionado USD *'}
                  type={'number'}
                  name={'price'}
                  register={register}
                  placeholder={''}
                  errors={errors?.price?.message}
                  min={'0'}
                />
                <Input
                  bootstrap={'col-12'}
                  label={'Monto que paga el cliente (Servicio) USD*'}
                  type={'number'}
                  name={'pvp'}
                  register={register}
                  placeholder={''}
                  errors={errors?.pvp?.message}
                  min={'0'}
                  onChange={onChange?.restorePaymentMethods}
                  disabled={[PaymentStatus?.N_A, PaymentStatus?.COURTESY].includes(watch('paymentStatus'))}
                />
                <Input
                  bootstrap={'col-12'}
                  label={'Monto que paga el cliente (Maniobra) USD*'}
                  type={'number'}
                  name={'amountHandling'}
                  register={register}
                  placeholder={''}
                  errors={errors?.amountHandling?.message}
                  min={'0'}
                  onChange={onChange?.restorePaymentMethods}
                  disabled={[PaymentStatus?.N_A, PaymentStatus?.COURTESY].includes(watch('paymentStatus'))}
                />
                <Input
                  bootstrap={'col-12'}
                  label={'Monto que paga el cliente (Excedente) USD*'}
                  type={'number'}
                  name={'amountExcess'}
                  register={register}
                  placeholder={''}
                  errors={errors?.amountExcess?.message}
                  min={'0'}
                  onChange={onChange?.restorePaymentMethods}
                  disabled={[PaymentStatus?.N_A, PaymentStatus?.COURTESY].includes(watch('paymentStatus'))}
                />
                {validateIfcanShow() &&
                  <h4>Monto Total: {sumExcess()} $</h4>
                }
              </>
              {(watch("pvp") > 0 || watch("amountHandling") > 0 || watch("amountExcess") > 0) &&
                ![
                  PaymentStatus?.N_A,
                  PaymentStatus?.COURTESY,
                  PaymentStatus?.CUENTA_POR_COBRAR
                ].includes(watch('paymentStatus')) && (
                  <Input
                    bootstrap={'col-12'}
                    label={'Cantidad de Métodos de Pago'}
                    type={'number'}
                    name={'paymentMethods'}
                    register={register}
                    placeholder={''}
                    errors={errors?.paymentMethods?.message}
                    options={options?.paymentMethods}
                    onChange={onChange?.validateMaxPaymentMethods}
                    min={'1'}
                    max={'5'}
                  />
                )}
              {watch('paymentMethods') > 0 &&
                (watch('pvp') > 0 || watch('amountHandling') > 0 || watch('amountExcess') > 0) &&
                ![
                  PaymentStatus?.N_A,
                  PaymentStatus?.COURTESY,
                  PaymentStatus?.CUENTA_POR_COBRAR
                ].includes(watch('paymentStatus')) && (
                  <>
                    {paymentMethods().map((p, index) => (
                      <PaymentSection
                        key={p?.label}
                        payment={p}
                        watch={watch}
                        options={options}
                        register={register}
                        index={p?.index}
                        returnAccountData={returnAccountData}
                        fileUploaded={fileUploaded}
                        setFileUploaded={setFileUploaded}
                        onChange={onChange}
                      />
                    ))}
                  </>
                )}
            </div>
          </div>
        </div>
      </form>
      <div className="buttons-center-container">
        <AltButton action={() => cancel()} label={'Cancelar'} />
        {validateIfCanPay(action)}
      </div>
    </>
  );
};
