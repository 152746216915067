import Pagination from '@material-ui/lab/Pagination';
import navOptionArrow from '../../../images/navOptionArrow.svg';
import editLogo from '../../../images/edit.svg';
import view from '../../../images/view.svg';
import add from '../../../images/add.svg';
// import checkStatus from '../../../images/checkStatus.svg';
// import redXStatus from '../../../images/redXStatus.svg';
import grayCheckboxSelected from '../../../images/grayCheckboxSelected.svg';
import grayCheckboxUnselected from '../../../images/grayCheckboxUnselected.svg';
import locationsLogo from '../../../images/locations.svg';
import {
  ActionButton,
  TableActionButton,
  SwitchComponent,
} from '../Buttons/Buttons';
import { capitalize, exists } from '../../../assets/helpers/funciones';
import { exportablesTypes, caseType } from '../../../assets/helpers/options';
import moment from 'moment';
import { TableLoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import factura from '../../../images/factura.svg';
import {
  serviceName,
  reasignTime,
  calculateDuration,
  transcriptStatus,
} from '../../../assets/helpers/funciones';
import {
  transcriptRoles,
  operationsRoles,
} from '../../../assets/helpers/roles';
import './Tables.scss';

export const Checkbox = props => {
  const { payments, payment, select, index } = props;

  let selectedPayment = () => {
    let selected = payments?.filter(p => p?.selected);

    if (selected[0] && payment?.providerName !== selected[0]?.providerName) {
      return true;
    } else return false;
  };

  if (selectedPayment()) {
    return null;
  }

  if (payment.selected) {
    return (
      <img
        style={{ cursor: 'pointer' }}
        src={grayCheckboxSelected}
        alt="grayCheckboxSelected"
        onClick={() => select(payment, index)}
      />
    );
  }
  if (!payment.selected) {
    return (
      <img
        style={{ cursor: 'pointer' }}
        src={grayCheckboxUnselected}
        alt="grayCheckboxSelected"
        onClick={() => select(payment, index)}
      />
    );
  } else return null;
};

export const SelectAllCheckbox = props => {
  const { data, handleMultipleSelection } = props;

  let selected = false;
  let [name] = data?.data?.filter(d => d?.selected === true);
  name = name?.providerName;
  let selectedCounter = data?.data?.filter(
    d =>
      d?.selected === true &&
      d?.providerName === name &&
      d?.providerPaymentStatus !== 'Pagado'
  );
  let providerNameCounter = 0;
  for (let i = 0; i < data?.data?.length; i++) {
    if (
      data?.data[i]?.providerName === name &&
      data?.data[i]?.providerPaymentStatus !== 'Pagado'
    ) {
      providerNameCounter = providerNameCounter + 1;
    }

    if (data?.data[i]?.selected) {
      selected = true;
    }
  }
  if (!selected) return null;

  if (selected && selectedCounter?.length === providerNameCounter) {
    return (
      <img
        style={{ cursor: 'pointer' }}
        src={grayCheckboxSelected}
        alt="grayCheckboxSelected"
        onClick={() => handleMultipleSelection(false, name)}
      />
    );
  } else {
    return (
      <img
        style={{ cursor: 'pointer' }}
        src={grayCheckboxUnselected}
        alt="grayCheckboxSelected"
        onClick={() => handleMultipleSelection(true, name)}
      />
    );
  }
};

export const Tables = props => {
  const { header, dataTable, handlePageChange, edit } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>

          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.invoiceName || d?.companyName)}</td>
                <td>{capitalize(d?.contactName)}</td>
                <td>{exists(d?.phonePrefix) + '-' + exists(d?.phoneNumber)}</td>
                <td>
                  {exists(d?.identificationType) +
                    ': ' +
                    exists(d?.identification)}
                </td>
                <td>{capitalize(d?.state)}</td>
                <td>{exists(d?.isActive)}</td>
                <td>
                  <div className="table-buttons-conatiner">
                    {/* <TableActionButton src={view} /> */}
                    <TableActionButton src={editLogo} action={() => edit(d)} />
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const FleetLocationTable = props => {
  const { header, dataTable, handlePageChange, handleLocationModal } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>

          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td>{d?.name}</td>
                <td>{transcriptStatus(d?.online)}</td>
                <td>
                  {d?.time ? moment.utc(d?.time).format('DD/MM/YYYY  LTS') : ''}
                </td>
                {/* <td>{d?.lat + "  "} {d?.lng}</td> */}
                <td>
                  <TableActionButton
                    src={locationsLogo}
                    action={() => handleLocationModal(d)}
                  />
                </td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const GarageGPTable = props => {
  const { header, dataTable, handlePageChange } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>

          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td></td>
                <td>{d?.invoiceNum}</td>
                <td>{d?.client?.clientNum}</td>
                <td>{capitalize(d?.client?.name)}</td>
                <td>
                  {d?.date ? moment.utc(d?.date).format('DD/MM/YYYY') : ''}
                </td>
                <td>XXX</td>
                <td>{d?.paymentMethod}</td>
                <td>{capitalize(d?.paymentDesc)}</td>
                <td>{(d?.total - d?.pendingAmount)?.toFixed(2)}</td>
                <td>{d?.total}</td>
                <td>{d?.totalNoTax}</td>
                <td>{d?.totalTax}</td>
                <td>{d?.pendingAmount}</td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const UsersTable = props => {
  const { header, data } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.name}</td>
              <td>{d?.email}</td>
              <td>{d?.profile}</td>
              <td>{d?.status}</td>
              <td>{d?.createdAt}</td>
              <td>{d?.editedAt}</td>
              <td>
                <div className="table-buttons-conatiner">
                  <TableActionButton src={view} />
                  <TableActionButton src={editLogo} />
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
        //   onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const FleetsTable = props => {
  const { header, data } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data.map(d => (
            <tr key={d?._id}>
              <td>{d?.transport}</td>
              <td>{d?.model}</td>
              <td>{d?.brand}</td>
              <td>{d?.plate}</td>
              <td>{d?.year}</td>
              <td>{d?.purchaseDate}</td>
              <td>
                <div className="table-buttons-conatiner">
                  <TableActionButton src={view} />
                  <TableActionButton src={editLogo} />
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={5}
          variant="outlined"
          shape="rounded"
          page={1}
        //   onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ExportablesDBTable = props => {
  const { header, data, handlePageChange, displayPayment, type, tableRef } =
    props;

  const pendingStatus = service => {
    if (service?.paymentStatus === 'Cuenta por cobrar') {
      return service?.pendingPayment?.pending ? 'Pendiente' : 'Pago Realizado';
    } else {
      return null;
    }
  };

  const validateAffiliation = data => {
    if (data?.caseType === caseType?.AFFILIATED) {
      return 'Si';
    }
    if (data?.caseType === caseType?.PARTICULAR) {
      if (data?.particularCustomer?.isAffiliated) {
        return 'Si';
      } else {
        return 'No';
      }
    }
  };

  return (
    <>
      <div className="table-container">
        <table className="table exportable-table">
          <tr>
            {header?.map((h, i) => (
              <th key={h?.name + i} style={{ display: h?.hide ? 'none' : '' }}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {!type &&
            data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map((d, i) => (
              <tr key={d?.caseId}>
                <td>
                  {capitalize(d?.createdBy[0]?.firstName)}{' '}
                  {capitalize(d?.createdBy[0]?.lastName)}
                </td>
                <td>
                  {capitalize(d?.user?.firstName)}{' '}
                  {capitalize(d?.user?.lastName)}
                </td>
                <td>{d?.caseId}</td>
                <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                <td>{moment.utc(d?.createdAt).format('LTS')}</td>
                <td>{d?.status}</td>
                <td>
                  {capitalize(d?.particularCustomer?.firstName) ||
                    capitalize(d?.membership?.customer?.firstName)}
                </td>
                <td>
                  {capitalize(d?.particularCustomer?.lastName) ||
                    capitalize(d?.membership?.customer?.lastName)}
                </td>{' '}
                <td>{d?.phone}</td>
                <td>
                  {exists(d?.particularCustomer?.vehiclePlate) ||
                    exists(d?.membership?.vehicle?.plate)}
                </td>
                <td>
                  {capitalize(d?.particularCustomer?.vehicleBrand?.brand) ||
                    capitalize(d?.membership?.vehicle?.brand)}
                </td>{' '}
                <td>
                  {capitalize(d?.particularCustomer?.vehicleModel?.model) ||
                    capitalize(d?.membership?.vehicle?.model)}
                </td>{' '}
                <td>
                  {exists(d?.particularCustomer?.vehicleYear) ||
                    exists(d?.membership?.vehicle?.year)}
                </td>{' '}
                <td>
                  {capitalize(d?.membership?.code) ||
                    capitalize(d?.particularCustomer?.code)}
                </td>
                <td>
                  {capitalize(d?.membership?.status) ||
                    capitalize(d?.particularCustomer?.status)}
                </td>
                <td>{capitalize(d?.caseType)}</td>
                <td>{validateAffiliation(d)}</td>
                <td>{capitalize(d?.fault)}</td>
                <td>{capitalize(d?.realFault)}</td>
                <td>{capitalize(d?.originState)}</td>
                <td>{capitalize(d?.originCity)}</td>
                <td>{capitalize(d?.destinyState)}</td>
                <td>{capitalize(d?.destinyCity)}</td>
                {d?.services?.map((service, j) => {
                  return (
                    <>
                      <td style={{ display: displayPayment }}>
                        {serviceName(service?.productCode)}
                      </td>
                      {j === 0 && <td>{service?.towReason}</td>}
                      <td>{service?.externalTowReason}</td>
                      <td style={{ display: displayPayment }}>
                        {service?.code}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.provider?.isInternal === true && 'Propia'}
                        {service?.provider?.isInternal === false && 'Externa'}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.provider?.contactName}
                      </td>{' '}
                      <td style={{ display: displayPayment }}>
                        {service?.shift}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.price ? service?.price + '$' : ''}{' '}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.pvp ? service?.pvp + '$' : ''}{' '}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.amountExcess
                          ? service?.amountExcess + ' $'
                          : ''}
                      </td>
                      <td style={{ display: displayPayment }}>
                        {service?.amountHandling
                          ? service?.amountHandling + ' $'
                          : ''}
                      </td>
                      <td>{service?.authorizedBy}</td>
                      <td style={{ display: displayPayment }}>
                        {service?.paymentStatus}
                      </td>
                      <td>{pendingStatus(service)}</td>
                      <td>{service?.pendingPayment?.amount}</td>
                      <td>
                        {service?.pendingPayment?.datePaid
                          ? moment
                            .utc(service?.pendingPayment?.datePaid)
                            .format('DD/MM/YYYY')
                          : ''}
                      </td>
                      {service?.payments?.map((payment, k) => {
                        return (
                          <>
                            <td style={{ display: displayPayment }}>
                              {capitalize(payment?.method)}
                            </td>
                            <td style={{ display: displayPayment }}>
                              {payment?.totalAmount
                                ? payment?.totalAmount + '$'
                                : ''}
                            </td>
                            <td style={{ display: displayPayment }}>
                              {payment?.reference}
                            </td>
                            <td style={{ display: displayPayment }}>
                              {payment?.referenceImg && (
                                <a
                                  target="_blank"
                                  href={payment?.referenceImg}
                                  rel="noreferrer"
                                >
                                  Comprobante de pago
                                </a>
                              )}
                            </td>
                          </>
                        );
                      })}
                    </>
                  );
                })}
                {d?.newStages?.map((stage, i) => {
                  return <td>{reasignTime(d, i)}</td>;
                })}
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.avImage) {
                      return (
                        <a
                          target="_blank"
                          href={service?.avImage}
                          rel="noreferrer"
                        >
                          Imagen AV
                        </a>
                      );
                    }
                  })}
                </td>
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.towImages?.towAtOrigin) {
                      return (
                        <a
                          target="_blank"
                          href={service?.towImages?.towAtOrigin}
                          rel="noreferrer"
                        >
                          Gruero en origen
                        </a>
                      );
                    }
                  })}
                </td>
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.towImages?.vehicleOnTow) {
                      return (
                        <a
                          target="_blank"
                          href={service?.towImages?.vehicleOnTow}
                          rel="noreferrer"
                        >
                          Vehículo en grúa
                        </a>
                      );
                    }
                  })}
                </td>
                <td>
                  {d?.services?.map((service, j) => {
                    if (service?.towImages?.vehicleOnRoad) {
                      return (
                        <a
                          target="_blank"
                          href={service?.towImages?.vehicleOnRoad}
                          rel="noreferrer"
                        >
                          Vehículo en destino
                        </a>
                      );
                    }
                  })}
                </td>
              </tr>
            ))}
        </table>

        <PrintBDTable
          data={data}
          header={header}
          displayPayment={''}
          type={type}
          tableRef={tableRef}
        />
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ExportablesReviewTable = props => {
  const { header, data, handlePageChange, type, tableRef } = props;

  return (
    <>
      <div className="table-container">
        <table className="table exportable-table">
          <tr>
            {header?.map((h, i) => (
              <th key={h?.name + i} style={{ display: h?.hide ? 'none' : '' }}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {!type &&
            data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map((d, i) => (
              <tr key={d?.caseId}>
                <td>{d?.serviceCode}</td>
                <td>
                  {d?.serviceDate
                    ? moment?.utc(d?.serviceDate)?.format('DD/MM/YYYY')
                    : ''}
                </td>
                <td>{d?.vehicle?.plate}</td>
                <td>{capitalize(d?.vehicle?.brand)}</td>
                <td>{capitalize(d?.vehicle?.model)}</td>
                <td>
                  {d?.customer?.phone?.phonePrefix +
                    '-' +
                    d?.customer?.phone?.phoneNumber}
                </td>
                <td>
                  {capitalize(d?.customer?.firstName) +
                    ' ' +
                    capitalize(d?.customer?.lastName)}
                </td>
                <td>{d?.checkups?.coolantLevel}</td>
                <td>{d?.checkups?.engineOilLevel}</td>
                <td>{d?.checkups?.brakeFluidLevel}</td>
                <td>{d?.checkups?.gearboxOilLevel}</td>
                <td>{d?.checkups?.powerSteeringFluidLevel}</td>
                {/*  */}
                <td>{d?.checkups?.engineOilCondition}</td>
                <td>{d?.checkups?.coolantFluidCondition}</td>
                <td>{d?.checkups?.powerSteeringFluidCondition}</td>
                <td>{d?.checkups?.brakeFluidCondition}</td>
                <td>{d?.checkups?.gearboxOilCondition}</td>
                <td>{d?.checkups?.timingBeltCondition}</td>
                <td>{d?.checkups?.uniqueBeltCondition}</td>
                <td>{d?.checkups?.alternatorBeltCondition}</td>
                <td>{d?.checkups?.airConditionerBeltCondition}</td>
                <td>{d?.checkups?.steeringPumpBeltCondition}</td>
                <td>{d?.checkups?.pulleysCondition}</td>
                <td>{d?.checkups?.tensorCondition}</td>
                <td>{d?.checkups?.upperHosesCondition}</td>
                <td>{d?.checkups?.lowerHosesCondition}</td>
                <td>{d?.checkups?.thermostatCondition}</td>
                <td>{d?.checkups?.coolantTankCondition}</td>
                {/*  */}
                <td>{d?.checkups?.valveCoverLeak}</td>
                <td>{d?.checkups?.oilPanLeak}</td>
                <td>{d?.checkups?.gearboxPanLeak}</td>
                <td>{d?.checkups?.frontcrankshaftSealLeak}</td>
                <td>{d?.checkups?.rearCrankshaftSealLeak}</td>
                <td>{d?.checkups?.camshaftSealLeak}</td>
                <td>{d?.checkups?.gearboxSealLeak}</td>
                <td>{d?.checkups?.steeringHosesLeak}</td>
                <td>{d?.checkups?.steeringPumpLeak}</td>
                <td>{d?.checkups?.transferCaseLeak}</td>
                <td>{d?.checkups?.differentialLeak}</td>
                <td>{d?.checkups?.UpperRadiatorTankLeak}</td>
                <td>{d?.checkups?.LowerRadiatorTankLeak}</td>
                {/*  */}
                <td>{d?.checkups?.fuelPumpHealth}</td>
                <td>{d?.comments}</td>
              </tr>
            ))}
        </table>

        <PrintBDTable
          data={data}
          header={header}
          displayPayment={''}
          type={type}
          tableRef={tableRef}
        />
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const PrintBDTable = props => {
  const { data, header, displayPayment, type, tableRef } = props;

  let newHeader = header?.filter(h => !h?.hidePrint);

  return (
    <table
      style={{ display: 'none' }}
      ref={tableRef}
      className="table exportable-table"
    >
      <tr>
        {newHeader?.map((h, i) => (
          <th key={h?.name + i} style={{ display: h?.hide ? 'none' : '' }}>
            {h?.name}
            {h?.sortable && (
              <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
            )}
          </th>
        ))}
      </tr>
      {!type &&
        data?.data.length > 0 &&
        !data?.isLoading &&
        data?.data?.map((d, i) => (
          <tr key={d?.caseId}>
            <td>
              {d?.user?.firstName} {d?.user?.lastName}
            </td>
            <td>{d?.caseId}</td>
            <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
            <td>{moment.utc(d?.createdAt).format('LTS')}</td>
            <td>{d?.status}</td>
            <td>
              {capitalize(d?.particularCustomer?.firstName) ||
                capitalize(d?.membership?.customer?.firstName)}
            </td>
            <td>
              {capitalize(d?.particularCustomer?.lastName) ||
                capitalize(d?.membership?.customer?.lastName)}
            </td>{' '}
            <td>
              {exists(d?.particularCustomer?.vehiclePlate) ||
                exists(d?.membership?.vehicle?.plate)}
            </td>
            <td>
              {capitalize(d?.particularCustomer?.vehicleBrand?.brand) ||
                capitalize(d?.membership?.vehicle?.brand)}
            </td>{' '}
            <td>
              {capitalize(d?.particularCustomer?.vehicleModel?.model) ||
                capitalize(d?.membership?.vehicle?.model)}
            </td>{' '}
            <td>
              {exists(d?.particularCustomer?.vehicleYear) ||
                exists(d?.membership?.vehicle?.year)}
            </td>{' '}
            <td>{capitalize(d?.membership?.code)}</td>
            <td>{capitalize(d?.membership?.status)}</td>
            <td>{capitalize(d?.caseType)}</td>
            <td>{capitalize(d?.fault)}</td>
            <td>{capitalize(d?.realFault)}</td>
            <td>{capitalize(d?.originState)}</td>
            <td>{capitalize(d?.originCity)}</td>
            <td>{capitalize(d?.destinyState)}</td>
            <td>{capitalize(d?.destinyCity)}</td>
            {d?.services?.map((service, j) => {
              return (
                <>
                  <td style={{ display: displayPayment }}>
                    {serviceName(service?.productCode)}
                  </td>
                  <td style={{ display: displayPayment }}>{service?.code}</td>
                  <td style={{ display: displayPayment }}>
                    {service?.provider?.isInternal === true && 'Propia'}
                    {service?.provider?.isInternal === false && 'Externa'}
                  </td>
                  <td style={{ display: displayPayment }}>
                    {service?.provider?.contactName}
                  </td>
                  <td style={{ display: displayPayment }}>{service?.shift}</td>
                  <td style={{ display: displayPayment }}>
                    {service?.price ? service?.price + '$' : ''}{' '}
                  </td>
                  <td style={{ display: displayPayment }}>
                    {service?.pvp ? service?.pvp + '$' : ''}{' '}
                  </td>
                  <td style={{ display: displayPayment }}>
                    {service?.paymentStatus}
                  </td>
                  {service?.payments?.map((payment, k) => {
                    return (
                      <>
                        <td style={{ display: displayPayment }}>
                          {capitalize(payment?.method)}
                        </td>
                        <td style={{ display: displayPayment }}>
                          {payment?.totalAmount
                            ? payment?.totalAmount + '$'
                            : ''}
                        </td>
                        <td style={{ display: displayPayment }}>
                          {payment?.reference}
                        </td>
                      </>
                    );
                  })}
                </>
              );
            })}
            {d?.newStages?.map((stage, i) => {
              return <td>{reasignTime(d, i)}</td>;
            })}
          </tr>
        ))}
    </table>
  );
};

export const ExportablesPaymentTable = props => {
  const { header, data, handlePageChange, type, tableRef } = props;
  return (
    <>
      <div className="table-container">
        <table ref={tableRef} className="table exportable-table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {[
            exportablesTypes?.PAYMENT_AV,
            exportablesTypes?.PAYMETN_INTERNAL_TOW,
            exportablesTypes?.PAYMENT_EXTERNAL_TOW,
          ].includes(type) &&
            data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => (
              <tr key={d?._id}>
                {[
                  exportablesTypes?.PAYMENT_AV,
                  exportablesTypes?.PAYMETN_INTERNAL_TOW,
                ].includes(type) && <td>{capitalize(d?.providerName)}</td>}{' '}
                {[exportablesTypes?.PAYMENT_EXTERNAL_TOW].includes(type) && (
                  <td>{capitalize(d?.providerCompany)}</td>
                )}
                <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                <td>{capitalize(d?.product)}</td>
                <td>{capitalize(d?.caseType)}</td>
                <td>{d?.authorizedBy}</td>
                <td>{exists(d?.code)}</td>
                <td>{exists(d?.plate)}</td>
                <td>{capitalize(d?.vehicleBrand)}</td>
                <td>{capitalize(d?.originState)}</td>
                <td>{capitalize(d?.originCity)}</td>
                <td>{exists(d?.providerPay?.toFixed(2))}$</td>
              </tr>
            ))}
        </table>
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ExportablesPendingPaymentTable = props => {
  const { header, data, handlePageChange, tableRef } = props;

  const sumAmount = (data, param) => {
    let total = 0;
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i][param];
    }
    return total?.toFixed(2);
  };

  return (
    <>
      <div className="table-container">
        <table ref={tableRef} className="table exportable-table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => (
              <tr key={d?._id}>
                <td>{d?.code}</td>
                <td>
                  {capitalize(d?.case?.particularCustomer?.firstName) +
                    ' ' +
                    capitalize(d?.case?.particularCustomer?.lastName) +
                    ' ' +
                    capitalize(d?.case?.membership?.customer?.firstName) +
                    ' ' +
                    capitalize(d?.case?.customer?.lastName)}
                </td>
                <td>
                  {exists(d?.case?.particularCustomer?.vehiclePlate) +
                    exists(d?.case?.membership?.vehicle?.plate)}
                </td>
                <td>{exists(d?.caseType)}</td>
                <td>{sumAmount(d?.payments, 'totalAmount')}</td>
                <td>{sumAmount(d?.payments, 'totalAmountInBsS')}</td>
                <td>
                  {d?.pendingPayment?.datePaid
                    ? moment
                      .utc(d?.pendingPayment?.datePaid)
                      .format('DD/MM/YYYY')
                    : ''}
                </td>
              </tr>
            ))}
        </table>
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ExportableAdministrationTable = props => {
  const { header, data, handlePageChange, tableRef } = props;

  const totalAmount = (payments, param) => {
    let total = 0;
    for (let i = 0; i < payments?.length; i++) {
      total = total + payments[i][param];
    }

    if (total > 0) {
      return total?.toFixed(2);
    } else return null;
  };

  const AdminPayments = props => {
    const { payments, index } = props;

    return (
      <>
        <td>{capitalize(payments[index]?.method?.method)}</td>
        <td>{payments[index]?.totalAmount}</td>
        <td>{payments[index]?.totalAmountInBsS}</td>
        <td>{payments[index]?.reference}</td>
      </>
    );
  };

  const validatePending = data => {
    if (data?.paymentStatus !== 'Cuenta por cobrar') {
      return null;
    }

    if (data?.pendingPayment?.datePaid) {
      return 'Pago realizado';
    } else {
      return 'Pendiente';
    }
  };

  return (
    <>
      <div className="table-container">
        <table ref={tableRef} className="table exportable-table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.caseType}</td>
                  <td>{capitalize(d?.customerFailureReason?.description)}</td>
                  <td>{capitalize(d?.providerFailureReason?.description)}</td>
                  <td>{capitalize(d?.product?.product)}</td>
                  <td>{d?.code}</td>
                  <td>
                    {d?.pvp > 0 ? 'Servicio (PVP) ' : ''}
                    {d?.amountExcess > 0 ? 'Excedente ' : ''}{' '}
                    {d?.amountHandling > 0 ? 'Maniobra ' : ''}
                  </td>
                  <td>{totalAmount(d?.payments, 'totalAmount')}</td>
                  <td>{totalAmount(d?.payments, 'totalAmountInBsS')}</td>
                  <td>{d?.paymentStatus}</td>
                  <AdminPayments payments={d?.payments} index={0} />
                  <AdminPayments payments={d?.payments} index={1} />
                  <AdminPayments payments={d?.payments} index={2} />
                  <AdminPayments payments={d?.payments} index={3} />
                  <td>{d?.pendingPayment?.amount}</td>
                  <td>{validatePending(d)}</td>
                </tr>
              );
            })}
        </table>
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ProvidersPaymentTable = props => {
  const {
    header,
    handleMultipleSelection,
    data,
    handlePageChange,
    type,
    tableRef,
    selectPayment,
  } = props;
  return (
    <>
      <div className="table-container">
        <table ref={tableRef} className="table exportable-table">
          <tr>
            <th>
              <SelectAllCheckbox
                data={data}
                handleMultipleSelection={handleMultipleSelection}
              />
            </th>
            {header?.map(h => (
              <th key={h?.name}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {[
            exportablesTypes?.PAYMENT_AV,
            exportablesTypes?.PAYMETN_INTERNAL_TOW,
            exportablesTypes?.PAYMENT_EXTERNAL_TOW,
          ].includes(type) &&
            data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map((d, i) => (
              <tr key={d?._id}>
                <td>
                  {!d?.invoice && (
                    <Checkbox
                      payments={data?.data}
                      payment={d}
                      select={selectPayment}
                      index={i}
                    />
                  )}
                </td>
                {[
                  exportablesTypes?.PAYMENT_AV,
                  exportablesTypes?.PAYMETN_INTERNAL_TOW,
                ].includes(type) && <td>{capitalize(d?.providerName)}</td>}{' '}
                {[exportablesTypes?.PAYMENT_EXTERNAL_TOW].includes(type) && (
                  <td>{capitalize(d?.providerCompany)}</td>
                )}
                <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                <td>{capitalize(d?.product)}</td>
                <td>{capitalize(d?.caseType)}</td>
                <td>{exists(d?.code)}</td>
                <td>{exists(d?.plate)}</td>
                <td>{capitalize(d?.vehicleBrand)}</td>
                <td>{capitalize(d?.originState)}</td>
                <td>{capitalize(d?.originCity)}</td>
                <td>{exists(d?.providerPay?.toFixed(2))}$</td>
                <td>
                  {d?.providerPaymentStatus ? d?.providerPaymentStatus : ''}
                </td>
                <td>{d?.invoice ? d?.invoice?.receipt : ''}</td>
                <td>{d?.invoice ? d?.invoice?.billNumber : ''}</td>
                <td>
                  {d?.invoice?.paymentDate
                    ? moment.utc(d?.invoice?.paymentDate).format('DD/MM/YYYY')
                    : ''}
                </td>
                <td>
                  {d?.invoice ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={d?.invoice?.receiptPath}
                    >
                      <img src={factura} alt="factura" />
                    </a>
                  ) : (
                    ''
                  )}
                </td>{' '}
              </tr>
            ))}
        </table>
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const ParticularCounterTable = props => {
  const { header, data, type, tableRef } = props;

  const calculateTotal = (data, param) => {
    let total = 0;
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i][param];
    }
    return total;
  };

  return (
    <>
      <div className="table-container particular-counter-table-container">
        <table ref={tableRef} className="table  exportable-table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {[
            exportablesTypes?.PAYMENT_AV,
            exportablesTypes?.PAYMETN_INTERNAL_TOW,
            exportablesTypes?.PAYMENT_EXTERNAL_TOW,
          ].includes(type) &&
            data?.data.length > 0 &&
            !data?.isLoading &&
            data?.data?.map((d, i) => (
              <tr key={d?._id}>
                <td>
                  {capitalize(d?.firstName) + ' ' + capitalize(d?.lastName)}
                </td>
                <td>{d?.activeServices}</td>
                <td>{d?.doneServices}</td>
                <td>{d?.towServices}</td>
                <td>{d?.avServices}</td>
                <td>{d?.totalServices} </td>
                <td>{d?.particularPay} $</td>
              </tr>
            ))}
          <tr>
            <td>
              <b>Total</b>
            </td>
            <td>
              <b>{calculateTotal(data?.data, 'activeServices')}</b>
            </td>
            <td>
              <b>{calculateTotal(data?.data, 'doneServices')}</b>
            </td>
            <td>
              <b>{calculateTotal(data?.data, 'towServices')}</b>
            </td>
            <td>
              <b>{calculateTotal(data?.data, 'avServices')}</b>
            </td>
            <td>
              <b>{calculateTotal(data?.data, 'totalServices')}</b>
            </td>
            <td>
              <b>{calculateTotal(data?.data, 'particularPay')}$</b>
            </td>
          </tr>
        </table>
      </div>
      {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
      {data?.data.length <= 0 && !data?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      {/* <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div> */}
    </>
  );
};

export const ProvidersTable = props => {
  const {
    externalHeader,
    internalHeader,
    data,
    assignTow,
    handlePageChange,
    internal,
  } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {internal ? (
              <>
                {internalHeader.map(h => (
                  <th key={h?.value}>
                    {h?.name}
                    {h?.sortable && (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={navOptionArrow}
                      ></img>
                    )}
                  </th>
                ))}
              </>
            ) : (
              <>
                {externalHeader.map(h => (
                  <th key={h?.value}>
                    {h?.name}
                    {h?.sortable && (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={navOptionArrow}
                        alt="image"
                      ></img>
                    )}
                  </th>
                ))}
              </>
            )}
          </tr>
          {data?.data?.map(d => (
            <tr key={d?._id}>
              <td>
                <img
                  onClick={() => assignTow(d)}
                  style={{ cursor: 'pointer' }}
                  src={add}
                  alt="add"
                />
              </td>
              {!internal && <td>{capitalize(d?.companyName)}</td>}
              <td>{capitalize(d?.contactName)}</td>
              <td>{exists(d?.phonePrefix) + '-' + exists(d?.phoneNumber)}</td>
              <td>
                {exists(d?.identificationType) +
                  ': ' +
                  exists(d?.identification)}
              </td>

              <td>{capitalize(d?.state)}</td>
              <td>{d?.isActive}</td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const InternalProviderTable = props => {
  const { header, dataTable, handlePageChange, edit, activateProvider } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.contactName)}</td>
                <td>{capitalize(d?.type)}</td>
                <td>{exists(d?.phonePrefix) + '-' + exists(d?.phoneNumber)}</td>
                <td>
                  {exists(d?.identificationType) +
                    ': ' +
                    exists(d?.identification)}
                </td>
                <td>{capitalize(d?.state)}</td>
                <td>{exists(d?.isActive)}</td>
                <td>
                  <div className="table-buttons-conatiner">
                    {/* <TableActionButton src={view} /> */}
                    <TableActionButton src={editLogo} action={() => edit(d)} />
                    <SwitchComponent
                      data={d}
                      active={d?.isActive === 'Activo'}
                      action={activateProvider}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const VehicularAssetsTable = props => {
  const { header, dataTable, handlePageChange, edit } = props;
  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {dataTable?.data.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.class)}</td>
                <td>{capitalize(d?.brand)}</td>
                <td>{capitalize(d?.model)}</td>
                <td>{capitalize(d?.color)}</td>
                <td>{exists(d?.plate)}</td>
                <td>{exists(d?.year)}</td>
                <td>
                  {d?.purchaseDate
                    ? moment.utc(d?.purchaseDate).format('DD/MM/YYYY')
                    : ''}
                </td>
                <td>{exists(d?.description)}</td>
                <td>
                  <div className="table-buttons-conatiner">
                    {/* <TableActionButton src={view} /> */}
                    <TableActionButton src={editLogo} action={() => edit(d)} />a
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const IncidenceTable = props => {
  const { header, data, title } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="incidence-table table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>{h?.name}</th>
            ))}
          </tr>
          {data?.map(d => (
            <tr key={d?._id}>
              <td>
                {d?.createdAt
                  ? moment.utc(d?.createdAt).format('DD/MM/YYYY')
                  : ''}
              </td>
              <td>{capitalize(d?.fault)}</td>
              <td>
                {d?.service ? capitalize(d?.service) : capitalize(d?.type)}
              </td>
              <td>{d?.providerFailureDescription}</td>
              <td>
                {d?.serviceKm || d?.serviceKm == 0
                  ? d?.serviceKm + ' Km'
                  : 'N/A'}
              </td>
            </tr>
          ))}
        </table>
        {data?.length <= 0 && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
    </>
  );
};

export const FollowingTable = props => {
  const { header, data, title } = props;

  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="incidence-table table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>{h?.name}</th>
            ))}
          </tr>
          {data?.map(d => (
            <tr key={d?._id}>
              <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
              <td>{moment.utc(d?.createdAt).format('LTS')}</td>
              <td>{exists(d?.stage)}</td>
              <td>{d?.type}</td>
              <td>{exists(d?.message)}</td>
              <td>{exists(d?.note)}</td>
            </tr>
          ))}
        </table>
        {data?.length <= 0 && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
    </div>
  );
};

export const OperationsUserTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    openDetail,
    tableRef,
    handleEditFleetForm,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    {capitalize(d?.firstName)} {capitalize(d?.lastName)}
                  </td>
                  <td>{d?.email}</td>
                  <td>{transcriptRoles(d?.role)}</td>
                  <td>{capitalize(d?.provider?.contactName)}</td>
                  <td>{d?.status}</td>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{moment.utc(d?.updatedAt).format('DD/MM/YYYY')}</td>

                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={editLogo}
                        action={() => handleEditFleetForm(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const SalesUserTable = props => {
  const {
    header,
    data,
    title,
    handlePageChange,
    openDetail,
    tableRef,
    handleEditFleetForm,
  } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table ref={tableRef} className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.data?.length > 0 &&
            !data?.isLoading &&
            data?.data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>
                    {d?.firstName} {d?.lastName}
                  </td>
                  <td>{d?.email}</td>
                  <td>{transcriptRoles(d?.role)}</td>
                  <td>{d?.status}</td>
                  <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{moment.utc(d?.updatedAt).format('DD/MM/YYYY')}</td>

                  <td>
                    <div className="table-buttons-conatiner">
                      <TableActionButton
                        src={editLogo}
                        action={() => handleEditFleetForm(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
        {data?.isLoading && <TableLoadSpinner show={data?.isLoading} />}
        {data?.data.length <= 0 && !data?.isLoading && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(data?.count / data?.limit)}
          variant="outlined"
          shape="rounded"
          page={data?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export const CancelServicesTable = props => {
  const { header, data, cancelService } = props;

  return (
    <>
      <div className="filter-button-container"></div>
      <div className="table-container">
        <table className="table">
          <tr>
            {header?.map(h => (
              <th key={h?.name}>{h?.name}</th>
            ))}
          </tr>
          {data?.length > 0 &&
            data?.map(d => {
              return (
                <tr key={d?._id}>
                  <td>{d?.type}</td>
                  <td>{capitalize(d?.provider?.contactName)}</td>
                  <td>{d?.status}</td>
                  <td>{d?.code}</td>
                  <td>
                    <div className="buttons-center-container">
                      <ActionButton
                        label={'Cancelar'}
                        action={() => cancelService(d)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
    </>
  );
};

export const TrazeTable = props => {
  const { header, data, title } = props;
  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="title-center-component">
        <h5>{title}</h5>
      </div>
      <div className="table-container">
        <table className="incidence-table table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>{h?.name}</th>
            ))}
          </tr>
          {data?.map(d => {
            return (
              <tr key={d?._id}>
                <td>{moment.utc(d?.createdAt).format('DD/MM/YYYY')}</td>
                <td>{moment.utc(d?.createdAt).format('LTS')}</td>
                <td>{d?.stage?.stage}</td>
                <td>{calculateDuration(d)}</td>
              </tr>
            );
          })}
        </table>
        {data?.length <= 0 && (
          <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
        )}
      </div>
    </div>
  );
};
export const CounterTable = props => {
  const { header, dataTable } = props;

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {dataTable?.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.map(d => (
              <tr key={d?._id}>
                <td>{capitalize(d?.product?.product)}</td>
                <td>{d?.num}</td>
                <td>{d?.amount} $</td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
    </>
  );
};

export const AVStatstable = props => {
  const { header, dataTable, user, handlePageChange } = props;

  const filterServices = (data, atribute) => {
    let [filterData] = data?.services?.filter(
      d => d?.provider?._id === user?.provider?._id
    );

    if (atribute === 'createdAt' && data[atribute]) {
      return moment?.utc(data[atribute]).format('DD/MM/YYYY');
    }
    if (atribute === 'plate') {
      let plate =
        data?.membership?.vehicle?.plate ||
        data?.particularCustomer?.vehiclePlate;
      return plate;
    }
    if (atribute === 'product') {
      return capitalize(filterData?.product?.name);
    }
    if (atribute === 'providerPay') {
      return filterData?.providerPay?.toFixed(2);
    }
    if (atribute === 'providerPaymentStatus') {
      return filterData?.providerPaymentStatus;
    }

    if (filterData) {
      return filterData[atribute];
    }
  };

  return (
    <>
      <div className="table-container">
        <table className="table">
          <tr>
            {header.map(h => (
              <th key={h?.value}>
                {h?.name}
                {h?.sortable && (
                  <img style={{ cursor: 'pointer' }} src={navOptionArrow}></img>
                )}
              </th>
            ))}
          </tr>
          {dataTable?.data?.length > 0 &&
            !dataTable?.isLoading &&
            dataTable?.data?.map(d => (
              <tr key={d?._id}>
                <td>{filterServices(d, 'product')}</td>
                <td>{filterServices(d, 'code')}</td>
                <td>{filterServices(d, 'plate')}</td>
                <td>{filterServices(d, 'createdAt')}</td>
                {user?.roles[0]?.code ===
                  operationsRoles?.OPERATIONS_FLOTA_AV && (
                    <td>{d?.solvedByAV ? 'Si' : 'No'}</td>
                  )}
                <td>{filterServices(d, 'providerPay')}</td>
                <td>{filterServices(d, 'providerPaymentStatus')}</td>
              </tr>
            ))}
        </table>
      </div>
      {dataTable?.isLoading && <TableLoadSpinner show={dataTable?.isLoading} />}
      {dataTable?.data?.length <= 0 && !dataTable?.isLoading && (
        <h6 style={{ textAlign: 'center' }}>No hay registros disponibles</h6>
      )}
      <div className="pagination-container">
        <Pagination
          count={Math.ceil(dataTable?.count / dataTable?.limit)}
          variant="outlined"
          shape="rounded"
          page={dataTable?.page}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};
