export const fleetLocationTableheader = [
  { name: 'Unidad', value: 'name', sortable: false },
  { name: 'Estatus de Unidad', value: 'contact', sortable: false },
  {
    name: 'Fecha de la ultima actualización',
    value: 'contact',
    sortable: false,
  },
  { name: 'Ubicación', value: 'contact', sortable: false },
]

export const craneTableheader = [
  { name: 'Empresa', value: 'name', sortable: false },
  { name: 'Nombre de contacto', value: 'contact', sortable: false },
  { name: 'Teléfono', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Identificación', value: 'identification', sortable: false },
  { name: 'Estado', value: 'state', sortable: false },
  { name: 'Estatus', value: 'status', sortable: false },
  { name: 'Acción', value: 'table-action', sortable: false },
]

export const GarageTableHeader = [
  { name: 'Grupo de Facturación', value: 'name', sortable: false },
  { name: 'Num.Factura', value: 'contact', sortable: false },
  { name: 'Número Cliente', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Nombre Cliente', value: 'identification', sortable: false },
  { name: 'Fecha Factura', value: 'state', sortable: false },
  { name: 'Fecha Cobro', value: 'status', sortable: false },
  { name: 'Forma Pago', value: 'table-action', sortable: false },
  { name: 'Condiciones de Pago', value: 'table-action', sortable: false },
  { name: 'Importe Cobrado', value: 'table-action', sortable: false },
  { name: 'Total Factura', value: 'table-action', sortable: false },
  { name: 'Base Imponible', value: 'table-action', sortable: false },
  { name: 'IVA', value: 'table-action', sortable: false },
  { name: 'CXC Clientes', value: 'table-action', sortable: false },
]

export const usersTableheader = [
  { name: 'Nombres', value: 'name', sortable: false },
  { name: 'Email', value: 'contact', sortable: false },
  { name: 'Perfil', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Status', value: 'state', sortable: false },
  { name: 'Creado', value: 'status', sortable: false },
  { name: 'Modificado', value: '', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]

export const selectServiceHeader = [
  { name: 'Servicio', value: 'name', sortable: false },
  { name: 'Status de pago', value: 'contact', sortable: false },
  { name: 'Proveedor', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Codigo', value: 'state', sortable: false },
  { name: 'Precio', value: 'status', sortable: false },
  { name: 'PVP', value: '', sortable: false },
  { name: '', value: '', sortable: false },
]

export const fleetsTableheader = [
  { name: 'Transporte', value: 'name', sortable: false },
  { name: 'Modelo', value: 'contact', sortable: false },
  { name: 'Marca', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Placa', value: 'state', sortable: false },
  { name: 'Año', value: 'status', sortable: false },
  { name: 'Fecha de Compra', value: '', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]
export const searchInternalProviderTableHeader = [
  { name: '', value: 'plus', sortable: false },
  { name: 'Nombre', value: 'name', sortable: false },
  { name: 'Teléfono', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Identificación', value: 'contact', sortable: false },
  { name: 'Estado', value: 'state', sortable: false },
  { name: 'Estatus', value: 'status', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]
export const saleUsersTableheader = [
  { name: 'Nombres', value: 'name', sortable: false },
  { name: 'Email', value: 'contact', sortable: false },
  { name: 'Perfil', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Proveedor', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Status', value: 'state', sortable: false },
  { name: 'Creado', value: 'status', sortable: false },
  { name: 'Modificado', value: '', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]

export const internalProviderTableHeader = [
  { name: 'Nombre', value: 'name', sortable: false },
  { name: 'Tipo', value: 'type', sortable: false },
  { name: 'Teléfono', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Identificación', value: 'contact', sortable: false },
  { name: 'Estado', value: 'state', sortable: false },
  { name: 'Estatus', value: 'status', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]

export const vehicularAssetsTableHeader = [
  { name: 'Transporte', value: 'name', sortable: false },
  { name: 'Marca', value: 'contact', sortable: false },
  { name: 'Modelo', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Color', value: 'color', sortable: false },
  { name: 'Placa', value: 'state', sortable: false },
  { name: 'Año', value: 'status', sortable: false },
  { name: 'Fecha de Compra', value: 'status', sortable: false },
  { name: 'Descripción', value: '', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]

export const providersTableheader = [
  { name: '', value: '', sortable: false },
  { name: 'Empresa', value: 'name', sortable: false },
  { name: 'Nombre de contacto', value: 'contact', sortable: false },
  { name: 'Teléfono', value: 'phone', sortable: false },
  { name: 'Identificación', value: 'identification', sortable: false },
  { name: 'Estado', value: 'region', sortable: false },
  { name: 'Estatus', value: 'status', sortable: false },
]

export const serviceHistoryTableheader = [
  { name: 'Fecha', value: 'date', sortable: false },
  { name: 'Averia', value: 'fault', sortable: false },
  { name: 'Servicio', value: '', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Comentario', value: 'KMEfectivo', sortable: false },
  { name: 'Km', value: 'KMEfectivo', sortable: false },
]

export const exportanblesTableheader = [
  { name: 'Operador Inicial', value: 'name', sortable: false },
  { name: 'Operador Final', value: 'name', sortable: false },
  { name: 'Nro. de caso', value: 'contact', sortable: false },
  { name: 'Fecha de creación', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Hora de creación', value: 'status', sortable: false },
  { name: 'Estatus', value: 'state', sortable: false },
  { name: 'Nombre', value: '', sortable: false },
  { name: 'Apellido', value: '', sortable: false },
  { name: 'Número', value: '', sortable: false },
  { name: 'Placa', value: '', sortable: false },
  { name: 'Marca', value: '', sortable: false },
  { name: 'Modelo', value: '', sortable: false },
  { name: 'Año', value: '', sortable: false },
  { name: 'Plan', value: '', sortable: false },
  { name: 'Estatus de Afiliación', value: '', sortable: false },
  { name: 'Tipo de gestión', value: '', sortable: false },
  { name: 'Cliente Afiliado', value: '', sortable: false },
  { name: 'Avería', value: '', sortable: false },
  { name: 'Avería Real', value: '', sortable: false },
  { name: 'Estado origen', value: '', sortable: false },
  { name: 'Ciudad origen', value: '', sortable: false },
  { name: 'Estado destino', value: '', sortable: false },
  { name: 'Ciudad destino', value: '', sortable: false },
]

export const imageHeader = [
  {
    name: `Imagen AV`,
    value: '',
    sortable: false,
    hidePrint: true,
  },
  {
    name: `Imagen grúa 1`,
    value: '',
    sortable: false,
    hidePrint: true,
  },
  {
    name: `Imagen grúa 2`,
    value: '',
    sortable: false,
    hidePrint: true,
  },
  {
    name: `Imagen grúa 3`,
    value: '',
    sortable: false,
    hidePrint: true,
  },
]

export const stageTimeHeader = [
  {
    name: `Tiempo AV Asignado`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo AV en vía`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo AV en origen`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo en búsqueda grúa`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo grúa asginada`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo grúa en vía`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo en grúa en origen`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo cliente vía destino`,
    value: '',
    sortable: false,
  },
  {
    name: `Tiempo total gestión`,
    value: '',
    sortable: false,
  },
]

export const AdminExportableHeader = [
  { name: 'Tipo de gestión', value: 'name', sortable: false },
  { name: 'Avería', value: 'name', sortable: false },
  { name: 'Avería Real', value: 'name', sortable: false },
  { name: 'Servicio Prestado', value: 'name', sortable: false },
  { name: 'Código de Servicio', value: 'name', sortable: false },
  { name: 'Tipo de ingreso', value: 'name', sortable: false },
  { name: 'Monto ingresado $', value: 'name', sortable: false },
  { name: 'Monto ingresado BsS', value: 'name', sortable: false },
  { name: 'Estatus de Pago', value: 'name', sortable: false },

  { name: 'Método de Pago 1', value: 'name', sortable: false },
  { name: 'Monto Pago 1 $', value: 'name', sortable: false },
  { name: 'Monto Pago 1 BsS', value: 'name', sortable: false },
  { name: 'Referencia de Pago 1', value: 'name', sortable: false },

  { name: 'Método de Pago 2', value: 'name', sortable: false },
  { name: 'Monto Pago 2 $', value: 'name', sortable: false },
  { name: 'Monto Pago 2 BsS', value: 'name', sortable: false },
  { name: 'Referencia de Pago 2', value: 'name', sortable: false },

  { name: 'Método de Pago 3', value: 'name', sortable: false },
  { name: 'Monto Pago 3 $', value: 'name', sortable: false },
  { name: 'Monto Pago 3 BsS', value: 'name', sortable: false },
  { name: 'Referencia de Pago 3', value: 'name', sortable: false },

  { name: 'Método de Pago 4', value: 'name', sortable: false },
  { name: 'Monto Pago 4 $', value: 'name', sortable: false },
  { name: 'Monto Pago 4 BsS', value: 'name', sortable: false },
  { name: 'Referencia de Pago 4', value: 'name', sortable: false },

  { name: 'Cuenta por cobrar $', value: 'name', sortable: false },
  { name: 'Estatus cuenta por cobrar', value: 'name', sortable: false },
]

export const PendingPaymentHeader = [
  { name: 'Código de servicio', value: 'name', sortable: false },
  { name: 'Cliente', value: 'name', sortable: false },
  { name: 'Placa', value: 'name', sortable: false },
  { name: 'Tipo de Gestión', value: 'name', sortable: false },
  { name: 'Monto pagado USD', value: 'name', sortable: false },
  { name: 'Monto pagado Bs', value: 'name', sortable: false },
  { name: 'Fecha de pago', value: 'name', sortable: false },
]

export const AVPaymentHeader = [
  { name: 'Empresa/Chofer', value: 'name', sortable: false },
  { name: 'Fecha de servicio', value: 'contact', sortable: false },
  { name: 'Servicio Prestado', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Tipo de gestión', value: 'state', sortable: false },
  { name: 'Autorizado por', value: 'state', sortable: false },
  { name: 'Código de servicio', value: 'status', sortable: false },
  { name: 'Placa', value: 'state', sortable: false },
  { name: 'Marca', value: '', sortable: false },
  { name: 'Estado origen', value: '', sortable: false },
  { name: 'Ciudad origen', value: '', sortable: false },
  { name: 'Monto a pagar proveedor', value: '', sortable: false },
]

export const PaymentHeader = [
  { name: 'Empresa/Chofer', value: 'name', sortable: false },
  { name: 'Fecha de servicio', value: 'contact', sortable: false },
  { name: 'Servicio Prestado', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Tipo de gestión', value: 'state', sortable: false },
  { name: 'Código de servicio', value: 'status', sortable: false },
  { name: 'Placa', value: 'state', sortable: false },
  { name: 'Marca', value: '', sortable: false },
  { name: 'Estado origen', value: '', sortable: false },
  { name: 'Ciudad origen', value: '', sortable: false },
  { name: 'Monto a pagar proveedor', value: '', sortable: false },
]

export const ProvidersPaymentHeader = [
  { name: 'Empresa/Chofer', value: 'name', sortable: false },
  { name: 'Fecha de servicio', value: 'contact', sortable: false },
  { name: 'Servicio Prestado', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Tipo de gestión', value: 'state', sortable: false },
  {
    name: 'Código de servicio',
    value: 'status',
    sortable: false,
  },
  { name: 'Placa', value: 'state', sortable: false },
  { name: 'Marca', value: '', sortable: false },
  { name: 'Estado origen', value: '', sortable: false },
  { name: 'Ciudad origen', value: '', sortable: false },
  { name: 'Monto a pagar proveedor', value: '', sortable: false },
  { name: 'Estatus de pago', value: '', sortable: false },
  { name: 'N de Recibo', value: '', sortable: false },
  { name: 'N de Factura', value: '', sortable: false },
  { name: 'Fecha de Pago', value: '', sortable: false },
  { name: 'Factura', value: '', sortable: false },
]

export const ParticularCounterHeader = [
  { name: 'Nombre', value: '', sortable: false },
  { name: 'Servicios activos por operador', value: '', sortable: false },
  { name: 'Servicios acumulados', value: '', sortable: false },
  {
    name: 'Cantidad de servicios particulares grúa',
    value: '',
    sortable: false,
  },
  {
    name: 'Cantidad de servicios particulares de Moto',
    value: '',
    sortable: false,
  },
  { name: 'Total', value: '', sortable: false },
  {
    name: 'Pago servicio particulares',
    value: '',
    sortable: false,
  },
]

export const internalTowPaymentHeader = [
  { name: 'Chofer', value: 'name', sortable: false },
  { name: 'Fecha de servicio', value: 'contact', sortable: false },
  { name: 'Servicio Prestado', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Tipo de gestión', value: 'state', sortable: false },
  { name: 'Código de servicio', value: 'status', sortable: false },
  { name: 'Placa', value: '', sortable: false },
  { name: 'Marca', value: '', sortable: false },
  { name: 'Estado origen', value: '', sortable: false },
  { name: 'Ciudad origen', value: '', sortable: false },
  { name: 'Costo del servicio', value: '', sortable: false },
  { name: 'Monto pagado al chofer', value: '', sortable: false },
]

export const externalTowPaymentHeader = [
  { name: 'Chofer', value: 'name', sortable: false },
  { name: 'Fecha de servicio', value: 'contact', sortable: false },
  { name: 'Servicio Prestado', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Tipo de gestión', value: 'state', sortable: false },
  { name: 'Código de servicio', value: 'status', sortable: false },
  { name: 'Placa', value: '', sortable: false },
  { name: 'Marca', value: '', sortable: false },
  { name: 'Estado origen', value: '', sortable: false },
  { name: 'Ciudad origen', value: '', sortable: false },
  { name: 'Costo del servicio', value: '', sortable: false },
  { name: 'Monto pagado al chofer', value: '', sortable: false },
]

export const exportableMockup = [
  {
    operator: 'Manuel Hernandez',
    time: '3:20pm',
    date: '12/03/2023',
    status: 'ACTIVO',
    caseId: '123467789',
    name: 'Luis',
    lastName: 'Perez',
  },
  {
    operator: 'Manuel Hernandez',
    time: '3:20pm',
    date: '12/03/2023',
    status: 'ACTIVO',
    caseId: '123467789',
    name: 'Luis',
    lastName: 'Perez',
  },
  {
    operator: 'Manuel Hernandez',
    time: '3:20pm',
    date: '12/03/2023',
    status: 'ACTIVO',
    caseId: '123467789',
    name: 'Luis',
    lastName: 'Perez',
  },
  {
    operator: 'Manuel Hernandez',
    time: '3:20pm',
    date: '12/03/2023',
    status: 'ACTIVO',
    caseId: '123467789',
    name: 'Luis',
    lastName: 'Perez',
  },
]

export const serviceHistoryMockup = [
  {
    date: 'XXXXXXXXXXXXX',
    fault: 'XXXXXXXXXXXXX',
    service: 'XXXXXXXXXXXXX',
    observations: 'XXXXXXXXXXXXX',
    KMEfectivo: 'XXXXXXXXXXXXX',
  },
  {
    date: 'XXXXXXXXXXXXX',
    fault: 'XXXXXXXXXXXXX',
    service: 'XXXXXXXXXXXXX',
    observations: 'XXXXXXXXXXXXX',
    KMEfectivo: 'XXXXXXXXXXXXX',
  },
  {
    date: 'XXXXXXXXXXXXX',
    fault: 'XXXXXXXXXXXXX',
    service: 'XXXXXXXXXXXXX',
    observations: 'XXXXXXXXXXXXX',
    KMEfectivo: 'XXXXXXXXXXXXX',
  },
]

export const caseFollowingheader = [
  { name: 'Fecha', value: 'date', sortable: false },
  { name: 'Hora', value: 'fault', sortable: false },
  { name: 'Status', value: '', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Tipo de seguimiento', value: 'observations', sortable: false },
  { name: 'Mensaje', value: 'KMEfectivo', sortable: false },
  { name: 'Observaciones', value: 'KMEfectivo', sortable: false },
]

export const scaseFollowingMockup = [
  {
    date: '04/03/2023',
    time: '09:33:01 A.M.',
    status: 'En espera de AV',
    type: 'Lllamada',
    mesage: 'En vía',
    observations: 'N/A',
  },
]

export const trazeHeader = [
  { name: 'Fecha', value: 'date', sortable: false },
  { name: 'Hora', value: 'fault', sortable: false },
  { name: 'Status', value: '', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Duración', value: 'observations', sortable: false },
]

export const trazeMockup = [
  {
    date: '04/03/2023',
    time: '10:45:01 A.M.',
    statusOrigin: '',
    statusDestiny: 'en espera AV',
    estimatedTime: 'min',
    observations: 'N/A',
  },
  {
    date: '13/03/23',
    time: '07:36:01 A.M.',
    statusOrigin: '',
    statusDestiny: 'en espera AV',
    estimatedTime: 'min',
    observations: 'N/A',
  },
]

export const bikeTableheader = [
  { name: 'Nombre', value: 'name', sortable: false },
  { name: 'Cédula', value: 'cedula', sortable: false },
  { name: 'Teléfono', value: 'phone', sortable: false },
  { name: 'Moto asignada (placa)', value: 'plate', sortable: false },
  { name: 'Estado', value: 'state', sortable: false },
  { name: 'Fecha de creación', value: 'date', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]

export const craneProvidersMockup = [
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
  {
    name: 'TRANSPORTE MACHADO 1974, C.A',
    contact: 'MANUEL HERNANDEZ',
    phone: '1234 567 789',
    state: 'MIRANDA',
    status: 'ACTIVO',
    edit: '',
  },
]

export const bikeMockup = [
  {
    name: 'Jose Luis Perez Rodriguez',
    cedula: '1234 567 789',
    phone: '1234 567 789',
    plate: 'AH74VF',
    state: 'MIRANDA',
    creationDate: '12/03/2023',
  },
  {
    name: 'Jose Luis Perez Rodriguez',
    cedula: '1234 567 789',
    phone: '1234 567 789',
    plate: 'AH74VF',
    state: 'MIRANDA',
    creationDate: '12/03/2023',
  },
  {
    name: 'Jose Luis Perez Rodriguez',
    cedula: '1234 567 789',
    phone: '1234 567 789',
    plate: 'AH74VF',
    state: 'MIRANDA',
    creationDate: '12/03/2023',
  },
  {
    name: 'Jose Luis Perez Rodriguez',
    cedula: '1234 567 789',
    phone: '1234 567 789',
    plate: 'AH74VF',
    state: 'MIRANDA',
    creationDate: '12/03/2023',
  },
  {
    name: 'Jose Luis Perez Rodriguez',
    cedula: '1234 567 789',
    phone: '1234 567 789',
    plate: 'AH74VF',
    state: 'MIRANDA',
    creationDate: '12/03/2023',
  },
  {
    name: 'Jose Luis Perez Rodriguez',
    cedula: '1234 567 789',
    phone: '1234 567 789',
    plate: 'AH74VF',
    state: 'MIRANDA',
    creationDate: '12/03/2023',
  },
  {
    name: 'Jose Luis Perez Rodriguez',
    cedula: '1234 567 789',
    phone: '1234 567 789',
    plate: 'AH74VF',
    state: 'MIRANDA',
    creationDate: '12/03/2023',
  },
]

export const usersTablesMockup = [
  {
    name: 'Rubeeeen',
    email: 'lorenzo@ruben.com',
    profile: 'Administrador de Sistema',
    status: 'ACTIVE',
    createdAt: '17/02/2020 17:02:30',
    editedAt: '17/02/2020 17:02:30',
  },
  {
    name: 'Rubeeeen',
    email: 'lorenzo@ruben.com',
    profile: 'Administrador de Sistema',
    status: 'ACTIVE',
    createdAt: '17/02/2020 17:02:30',
    editedAt: '17/02/2020 17:02:30',
  },
  {
    name: 'Rubeeeen',
    email: 'lorenzo@ruben.com',
    profile: 'Administrador de Sistema',
    status: 'ACTIVE',
    createdAt: '17/02/2020 17:02:30',
    editedAt: '17/02/2020 17:02:30',
  },
  {
    name: 'Rubeeeen',
    email: 'lorenzo@ruben.com',
    profile: 'Administrador de Sistema',
    status: 'ACTIVE',
    createdAt: '17/02/2020 17:02:30',
    editedAt: '17/02/2020 17:02:30',
  },
  {
    name: 'Rubeeeen',
    email: 'lorenzo@ruben.com',
    profile: 'Administrador de Sistema',
    status: 'ACTIVE',
    createdAt: '17/02/2020 17:02:30',
    editedAt: '17/02/2020 17:02:30',
  },
]

export const cancelServiceHeader = [
  { name: 'Servicio', value: 'name', sortable: false },
  { name: 'Proveedor', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Estatus', value: 'state', sortable: false },
  { name: 'Codigo de Servicio', value: 'state', sortable: false },
  { name: 'Acción', value: '', sortable: false },
]

export const providersTablesMockup = [
  {
    name: 'Rubeeeen',
    contact: '4142662382',
    region: 'Caracas',
    state: 'Miranda',
  },
  {
    name: 'Rubeeeen',
    contact: '4142662382',
    region: 'Caracas',
    state: 'Miranda',
  },
  {
    name: 'Rubeeeen',
    contact: '4142662382',
    region: 'Caracas',
    state: 'Miranda',
  },
  {
    name: 'Rubeeeen',
    contact: '4142662382',
    region: 'Caracas',
    state: 'Miranda',
  },
  {
    name: 'Rubeeeen',
    contact: '4142662382',
    region: 'Caracas',
    state: 'Miranda',
  },
]

export const fleetsTablesMockup = [
  {
    transport: 'Grua',
    model: 'Carrito',
    brand: 'Encava',
    plate: 'AH74VF',
    year: '2009',
    purchaseDate: '17/02/2020 17:02:30',
  },
  {
    transport: 'Grua',
    model: 'Carrito',
    brand: 'Encava',
    plate: 'AH74VF',
    year: '2009',
    purchaseDate: '17/02/2020 17:02:30',
  },
  {
    transport: 'Grua',
    model: 'Carrito',
    brand: 'Encava',
    plate: 'AH74VF',
    year: '2009',
    purchaseDate: '17/02/2020 17:02:30',
  },
  {
    transport: 'Grua',
    model: 'Carrito',
    brand: 'Encava',
    plate: 'AH74VF',
    year: '2009',
    purchaseDate: '17/02/2020 17:02:30',
  },
  {
    transport: 'Grua',
    model: 'Carrito',
    brand: 'Encava',
    plate: 'AH74VF',
    year: '2009',
    purchaseDate: '17/02/2020 17:02:30',
  },
]

export const setviceCounterTable = [
  { name: 'Servicios', value: 'name', sortable: false },
  { name: 'Cantidad', value: 'name', sortable: false },
  { name: 'Monto a Cobrar $', value: 'name', sortable: false },
]

export const avStatsHeaderTable = [
  { name: 'Servicio', value: 'name', sortable: false },
  { name: 'Código de Servicio', value: 'name', sortable: false },
  { name: 'Placa', value: 'name', sortable: false },
  { name: 'Fecha de Servicio', value: 'name', sortable: false },
  { name: 'Averia Solventada', value: 'name', sortable: false },
  { name: 'Monto a Cobrar $', value: 'name', sortable: false },
  { name: 'Estatus de pago', value: 'name', sortable: false },
]

export const reviewsTable = [
  { name: 'Código de Servicio', value: 'name', sortable: false },
  { name: 'Fecha de Servicio', value: 'name', sortable: false },
  { name: 'Placa', value: 'contact', sortable: false },
  { name: 'Marca', value: 'phone', sortable: false }, //   { name: 'Región', value: 'region' },
  { name: 'Modelo', value: 'state', sortable: false },
  { name: 'Teléfono', value: 'status', sortable: false },
  { name: 'Cliente', value: '', sortable: false },
  { name: 'Nivel de Refrigerante', value: '', sortable: false },
  { name: 'Nivel de Aceite de Motor', value: '', sortable: false },
  { name: 'Nivel de aceite de dirección', value: '', sortable: false },
  { name: 'Nivel de liga de freno', value: '', sortable: false },
  { name: 'Nivel de aceite de caja', value: '', sortable: false },
  //
  { name: 'Estado de aceite de motor', value: '', sortable: false },
  { name: 'Estado de refrigerante', value: '', sortable: false },
  { name: 'Estado de aceite de dirección', value: '', sortable: false },
  { name: 'Estado de liga de freno', value: '', sortable: false },
  { name: 'Estado de aceite de caja', value: '', sortable: false },
  { name: 'Estado de correa de tiempo', value: '', sortable: false },
  { name: 'Estado de correa de única', value: '', sortable: false },
  { name: 'Estado de correa de alternador', value: '', sortable: false },
  {
    name: 'Estado de correa de aire acondicionado',
    value: '',
    sortable: false,
  },
  { name: 'Estado de bomba de dirección', value: '', sortable: false },
  { name: 'Estado de poleas', value: '', sortable: false },
  { name: 'Estado de tensor', value: '', sortable: false },
  {
    name: 'Estado de manguera de radiador superior',
    value: '',
    sortable: false,
  },
  {
    name: 'Estado de manguera de radiador inferior',
    value: '',
    sortable: false,
  },
  { name: 'Estado de toma de termostato', value: '', sortable: false },
  { name: 'Estado de envase de refrigerante', value: '', sortable: false },
  //
  { name: 'Bote de aceite por tapavalvula', value: '', sortable: false },
  { name: 'Bote de aceite por carter de motor', value: '', sortable: false },
  { name: 'Bote de aceite por carter de caja', value: '', sortable: false },
  {
    name: 'Bote de aceite por estopera delantera de cigüeñal',
    value: '',
    sortable: false,
  },
  { name: 'Bote de aceite por estoperas de levas', value: '', sortable: false },
  {
    name: 'Bote de aceite por estopera trasera de cigüeñal',
    value: '',
    sortable: false,
  },
  {
    name: 'Bote de aceite por estoperas copa caja',
    value: '',
    sortable: false,
  },
  {
    name: 'Bote de aceite por mangueras de dirección',
    value: '',
    sortable: false,
  },
  { name: 'Bote de aceite por bomba de dirección', value: '', sortable: false },
  { name: 'Bote de aceite por transfer', value: '', sortable: false },
  { name: 'Bote de aceite por diferencial', value: '', sortable: false },
  {
    name: 'Bote de refrigerante por tanque superior de radiador',
    value: '',
    sortable: false,
  },
  {
    name: 'Bote de refrigerante por tanque inferior de radiador',
    value: '',
    sortable: false,
  },
  //
  { name: 'PSI Bomba de gasolina', value: '', sortable: false },
  //
  { name: 'Comentarios', value: '', sortable: false },
]
