import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { ActionButton } from '../../common/Buttons/Buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Header } from '../../common/Header/Header';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import {
  ScheduleServicesModal,
  MapModal,
  CancelCaseModal,
  CreateAVModal,
  MessageAVModal,
  MessageTowModal,
} from '../../common/Modals/Modals';
import { membershipService } from '../../../services/membershipService';
import { servicesService } from '../../../services/servicesService';
import { stagesService } from '../../../services/stagesService';
import { caseService } from '../../../services/caseService';
import { providersService } from '../../../services/providersService';
import { faultsService } from '../../../services/faultsService';
import { statesService } from '../../../services/statesService';
import { productService } from '../../../services/productService';
import { membershipProductsService } from '../../../services/membershipProductsService';
import { rateService } from '../../../services/rateService';
import { notify } from '../../../assets/helpers/toast';
import { CraneCounter } from '../../miscelaneos/CraneCounter/CraneCounter.jsx';
import moment from 'moment';
import {
  clientTypes,
  notFound,
  particularServices,
  afiliatedServices,
  membershipStatus,
  PaymentStatusOptions,
  PaymentStatus,
  addressEnum,
  addressStatus,
  authorizedByOptions
} from '../../../assets/helpers/options';
import {
  ClientDetail,
  IncidenceSearch,
  AvailableServices,
  SelectedServices,
  IncidenceDetailForm,
  HandleCaseButtons,
  ServiceHistory,
} from './IncidenceSections';
import './Incidence.scss';
import {
  garageLocation,
  afiliatedAddressOptions,
} from '../../../assets/helpers/options';
import {
  capitalize,
  filterParams,
  exists,
  getOption
} from '../../../assets/helpers/funciones';

export const AffiliatedIncidence = () => {
  const { t } = useTranslation();

  const [statesOptions, setStatesOptions] = useState([]);
  const [originCitiesOptions, setOriginCitiesOptions] = useState([]);
  const [destinycitiesOptions, setDestinyCitiesOptions] = useState([]);
  const [faultsOptions, setFaultsOptions] = useState([]);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [bsDisabled, setbsDisabled] = useState(true);
  const [showCancelService, setShowCancelService] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    resetField: resetField2,
    reset: reset2,
    watch: watch2,
  } = useForm({
    resolver: yupResolver(schemas.CreateIncidenceSchema(t)),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    getValues: getValues3,
    resetField: resetField3,
    watch: watch3,
  } = useForm({
    resolver: yupResolver(schemas.CreateAVFormSchema(t)),
  });

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    control: control4,
  } = useForm({
    resolver: yupResolver(schemas.ScheduleServiceSchema(t)),
  });

  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [dollarRate, setDollarRate] = useState('');
  const [reason, setReason] = useState('');
  const [showSMSModal, setShowSMSModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [showSMSTowModal, setShowSMSTowModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedBike, setSelectedBike] = useState('');
  const [caseInfo, setCaseInfo] = useState();
  const [fileUploaded, setFileUploaded] = useState([]);
  const [availableServices, setAvailableServices] = useState({
    selected: '',
    available: [],
  });
  const [showCraneCounterModal, setShowCraneCounterModal] = useState({
    show: false,
    src: '',
    zoom: 1,
  });
  const [openPayModal, setOpenPayModal] = useState(false);
  const [serviceHistory, setServiceHistory] = useState([]);
  const [clientSearch, setClientSearch] = useState({
    searchParam: '',
    options: [],
    isOpen: false,
    selected: '',
    exists: true,
  });

  const [_case, setCase] = useState({
    selected: '',
  });
  const [origin, setOrigin] = useState({
    lat: '',
    lng: '',
    address: '',
  });
  const [destiny, setDestiny] = useState({
    lat: '',
    lng: '',
    address: '',
  });

  const [route, setRoute] = useState({
    distance: '',
    time: '',
  });

  let history = useHistory();
  // Function that handles the logout request

  const scheduleService = handleSubmit4(async (data) => {
    let fecha = moment.utc(data?.date).format('YYYY-MM-DD');
    let date = new Date(fecha + 'T' + data?.time);
    date = moment.utc(date).format();

    let dataToPost = {
      date: date,
      productId: availableServices?.selected?._id,
      reason: reason
    }

    if (!reason && [afiliatedServices?.crane, particularServices?.crane]?.includes(availableServices?.selected?.code)) {
      notify('Debe indicar un motivo de selección de la grúa', 'info')
      return null
    }

    if (!isLoading) {
      setIsloading(true);
      caseService
        .scheduleCase(
          filterParams(dataToPost),
          _case?.selected?._id,
        )
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Servicio agendado con exito`, 'success');

            if (
              [afiliatedServices?.crane, particularServices?.crane].includes(
                availableServices?.selected?.code
              )
            ) {
              history.push('/services');
              setIsloading(false);
            } else {
              openSMSModal();
              setShowSchedule(false);
              setIsloading(false);
            }
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const createAffiliatedCase = handleSubmit2(async (data) => {
    let dataToPost = { ...data };

    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();

    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;

    dataToPost.membership = clientSearch?.selected?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .createCase(filterParams(dataToPost))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso creado con exito`, 'success');
            getAvailableProducts(response?.data?.case);
            setCase((prevState) => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const editAffiliatedCase = handleSubmit2(async (data) => {
    let dataToPost = { ...data };
    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();

    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;

    // Unicamente para casos particulares
    dataToPost.membership = clientSearch?.selected?._id;

    if (!isLoading) {
      setIsloading(true);
      caseService
        .editCase(filterParams(dataToPost), _case?.selected?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso editado con exito`, 'success');
            getAvailableProducts(response?.data?.case);
            setCase((prevState) => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  // Recalculate distance if origin or destny changes
  const forceRecalulateDistance = (e, value) => {
    if (value === addressStatus?.ORIGIN) {
      setValue2('originLocationStatus', addressEnum.OTHER);
    }
    if (value === addressStatus?.DESTINY) {
      setValue2('destinyLocationStatus', addressEnum.OTHER);
    }

    setValue2('caseKm', '');
  };

  const clearDestinyData = () => {
    resetField2('destinyLocationStatus');
    resetField2('destinyState');
    resetField2('destinyCity');
    resetField2('destinyAddress');
  };

  // Precarga el formulario de casos con la info por defecto
  const preloadCaseForm = (membership) => {
    // setValue2('contactNumberPrefix', membership?.customer?.phonePrefix);
    // setValue2('contactNumber', membership?.customer?.phoneNumber);
    setValue2('originLocationStatus', afiliatedAddressOptions[2]?._id);
    setValue2('isOriginBasement', false);
    setValue2('destinyLocationStatus', afiliatedAddressOptions[2]?._id);
  };

  const getMembershipById = (membershipId) => {
    membershipService
      .getMembershipById(membershipId, 'operations')
      .then((response) => {
        if (response.status === 200) {
          setClientSearch((prevState) => {
            return {
              ...prevState,
              selected: response?.data?.membership,
              isOpen: false,
            };
          });
          getLastServices(response?.data?.membership?._id);
          preloadCaseForm(response?.data?.membership);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const selectClient = (option) => {
    getMembershipById(option?.membershipId);
  };

  const handleClientSearchChange = (e) => {
    const { value, name } = e.target;
    reset2();
    setCase({ selected: '' });
    setAvailableServices({
      selected: '',
      available: [],
    });
    setClientSearch((prevState) => {
      return {
        ...prevState,
        [name]: value,
        selected: '',
        // isOpen: false,
      };
    });
  };

  const getMembership = (searchParam) => {
    if (!searchParam) {
      notify('Ingresar campos para realizar la busqueda', 'info');
      return null;
    }
    setIsloading(true);
    membershipService
      .getMembership(searchParam)
      .then((response) => {
        if (response.status === 200) {
          setClientSearch((prevState) => {
            return {
              ...prevState,
              options: response?.data?.memberships,
              isOpen: true,
            };
          });
          setIsloading(false);
        } else {
          notify(`${response.message}`, 'error');
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const getAvailableProducts = (_case) => {
    const params = {
      membershipId: _case?.membership,
      originCityId: _case?.originCity,
      distance: parseFloat(_case?.caseKm),
    };

    membershipProductsService
      .getAvailableProducts(params)
      .then((response) => {
        if (response.status === 200) {
          let availableServices = response?.data?.availableServices;
          if (watch2('withOutDestinyAddress')) {
            availableServices = availableServices.filter(
              (a) =>
                ![particularServices.crane, afiliatedServices.crane].includes(
                  a?.code
                )
            );
          }
          setAvailableServices((prevState) => {
            return {
              ...prevState,
              selected: '',
              available: [...availableServices],
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getCaseDetail = (caseId) => {
    caseService
      .getCaseById(_case?.selected?._id)
      .then((response) => {
        if (response.status === 200) {
          setCaseInfo(response?.data?.case);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const openSMSModal = () => {
    getDollarRate();
    let [provider] = providersOptions.filter((p) => p?._id === selectedBike);
    setShowSMSModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSModal?.isOpen,
        provider: provider,
      };
    });
  };

  const openSMSTowModal = () => {
    setShowSMSTowModal((prevState) => {
      return {
        ...prevState,
        isOpen: !showSMSTowModal?.isOpen,
      };
    });
  };

  const setBsRate = (e) => {
    const { name, value } = e?.target;

    if (!dollarRate) {
      setbsDisabled(false);
    }

    if (name.includes('amount')) {
      setValue3(
        name.replace('amount', 'amountBsS'),
        (value * dollarRate).toFixed(2)
      );
    }
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setbsDisabled(true);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const selectService = (service) => {
    getCaseDetail();
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach((e) => {
      e.selected = false;
      if (e._id === service._id) {
        e.selected = true;
      }

      setAvailableServices((prevState) => {
        return {
          ...prevState,
          selected: service,
          available: [...services],
        };
      });
    });
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getCities = (e, reference) => {
    forceRecalulateDistance();
    statesService
      .getCities(e?.target?.value ? e?.target?.value : e)
      .then((response) => {
        if (response.status === 200) {
          if (reference === 'originState') {
            setOriginCitiesOptions(response?.data?.cities);
          }
          if (reference === 'destinyState') {
            setDestinyCitiesOptions(response?.data?.cities);
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const getFaults = () => {
    faultsService
      .getFaults()
      .then((response) => {
        if (response.status === 200) {
          setFaultsOptions(response?.data?.newFaults);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  // Function that preloads garage location as origin or destiny
  const selectGarage = (e, reference) => {
    forceRecalulateDistance();
    const { value } = e?.target;
    // Preload GARAGE data
    if (value === afiliatedAddressOptions[1]?.name) {
      statesService
        .getCities(garageLocation?.state?._id)
        .then((response) => {
          if (response.status === 200) {
            if (reference === 'originState') {
              setOriginCitiesOptions(response?.data?.cities);
              setValue2('originState', garageLocation?.state?._id);
              setValue2('originCity', garageLocation?.city?._id);
              setValue2('originAddress', garageLocation?.address);
            }
            if (reference === 'destinyState') {
              setDestinyCitiesOptions(response?.data?.cities);
              setValue2('destinyState', garageLocation?.state?._id);
              setValue2('destinyCity', garageLocation?.city?._id);
              setValue2('destinyAddress', garageLocation?.address);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => { });
    }
    // Own Value
    if (value === afiliatedAddressOptions[0]?.name) {
      Promise.allSettled([
        getCities(clientSearch?.selected?.vehicle?.stateId, reference),
      ]).then((r) => {
        setTimeout(() => {
          if (reference === 'originState') {
            setValue2('originState', clientSearch?.selected?.vehicle?.stateId);
            setValue2('originCity', clientSearch?.selected?.vehicle?.cityId);
            setValue2(
              'originAddress',
              capitalize(clientSearch?.selected?.vehicle?.address)
            );
            setValue2(
              'originReferencePoint',
              capitalize(clientSearch?.selected?.vehicle?.addressReference)
            );
          }

          if (reference === 'destinyState') {
            setValue2('destinyState', clientSearch?.selected?.vehicle?.stateId);
            setValue2('destinyCity', clientSearch?.selected?.vehicle?.cityId);
            setValue2(
              'destinyAddress',
              capitalize(clientSearch?.selected?.vehicle?.address)
            );
            setValue2(
              'destinyReferencePoint',
              capitalize(clientSearch?.selected?.vehicle?.addressReference)
            );
          }
        }, 1500);
      });
    }
  };

  const unSelectService = () => {
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach((e) => {
      e.selected = false;
      setAvailableServices((prevState) => {
        return {
          ...prevState,
          selected: '',
          available: [...services],
        };
      });
    });
  };

  const openMapModal = () => {
    if (
      !getValues2('originState') ||
      !getValues2('originCity') ||
      !getValues2('originAddress')
    ) {
      notify('Ingrese datos de origen', 'error');
      return null;
    }
    if (
      !getValues2('destinyState') ||
      !getValues2('destinyCity') ||
      !getValues2('destinyAddress')
    ) {
      notify('Ingrese datos de destino', 'error');
      return null;
    }

    let [originState] = statesOptions.filter(
      (s) => s._id === getValues2('originState')
    );
    let [originCity] = originCitiesOptions.filter(
      (c) => c._id === getValues2('originCity')
    );

    let [destinyState] = statesOptions.filter(
      (s) => s._id === getValues2('destinyState')
    );
    let [destinyCity] = originCitiesOptions.filter(
      (c) => c._id === getValues2('destinyCity')
    );

    setOrigin({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(originState?.name) +
        ' ' +
        capitalize(originCity?.name) +
        ' ' +
        getValues2('originAddress'),
    });

    setDestiny({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(destinyState?.name) +
        ' ' +
        capitalize(destinyCity?.name) +
        ' ' +
        getValues2('destinyAddress'),
    });
    setMapModalOpen(true);
  };

  const handleCloseModal = () => {
    setValue2('caseKm', route?.distance);
    setValue2('estimatedTime', route?.time);
    setMapModalOpen(false);
    setRoute({
      time: '',
      distance: '',
    });
  };

  const selectBikeProvider = (e) => {
    setSelectedBike(e?.target?.value);
  };

  const getAVs = () => {
    providersService
      .getAVs()
      .then((response) => {
        if (response.status === 200) {
          setProvidersOptions(response?.data?.providers);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const startTowService = () => {


    if (!reason) {
      notify('Debe indicar un motivo de selección de la grúa', 'info')
      return null
    }

    const data = {
      case: _case?.selected?._id,
      reason: reason
    }


    if (!isLoading) {
      setIsloading(true);
      stagesService
        .createInitialTowStage(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            history.push('/services');
            notify(`Servicio creado en búsqueda de grúa`, 'success');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  };

  const refreshAfterCancel = () => {
    history.push('/services');
  };

  const registerPayment = () => {
    setValue3(
      'contact',
      clientSearch?.selected?.customer?.phonePrefix +
      '-' +
      clientSearch?.selected?.customer?.phoneNumber
    );
    setValue3('vehicleYear', clientSearch?.selected?.vehicle?.year);
    setValue3(
      'vehicleModel',
      capitalize(clientSearch?.selected?.vehicle?.model)
    );
    setValue3(
      'vehicleBrand',
      capitalize(clientSearch?.selected?.vehicle?.brand)
    );
    setValue3('vehiclePlate', exists(clientSearch?.selected?.vehicle?.plate));
    setValue3(
      'vehicleColor',
      capitalize(clientSearch?.selected?.vehicle?.color)
    );
    setValue3('estimatedTime', exists(_case?.selected?.estimatedTime));
    setOpenPayModal(true);
  };

  // Load payemnt methods and refactor JSON
  let loadPaymentForms = (params) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 0; i < parseInt(getValues3('paymentMethods')); i++) {
      payments.push({
        method: params[`method` + i.toString()],
        reference: params[`reference` + i.toString()],
        amount: params[`amount` + i.toString()],
        amountBsS: params[`amountBsS` + i.toString()],
      });
    }

    // "REmove unwanted params from JSON"
    let words = ['method', 'reference', 'amount', 'amountBsS', 'paymentMethod'];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const createAVService = handleSubmit3(async (data) => {
    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost);
    delete dataToPost?.contact;
    delete dataToPost?.vehicleBrand;
    delete dataToPost?.vehicleColor;
    delete dataToPost?.vehicleYear;
    delete dataToPost?.vehicleModel;
    delete dataToPost?.estimatedTime;
    delete dataToPost?.vehiclePlate;

    dataToPost.product = availableServices?.selected?._id;
    dataToPost.case = _case?.selected?._id;
    dataToPost.provider = selectedBike; // // Unicamente para casos particulares

    let fileToPost = appendFile(dataToPost);
    if (!isLoading && fileToPost) {
      setIsloading(true);
      servicesService
        .createAVService(fileToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`AV creado con exito`, 'success');
            history.push('/services');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const changePaymentStatus = () => {
    setValue3('authorizedBy', "");
    if (watch3('paymentStatus') === PaymentStatus?.COURTESY) {
      setValue3('pvp', 0);
      return null
    }
    if (watch3('paymentStatus') === PaymentStatus?.N_A) {
      setValue3('pvp', 0);
      return null
    } else {
      setValue3('pvp', '');
      return null
    }
  };

  // If the payment method is set to 0 clear the form for the N payment methods
  const restorePaymentMethods = () => {
    if (parseFloat(watch3('pvp')) === 0) {
      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        resetField3(`method${i}`);
        resetField3(`reference${i}`);
        resetField3(`paymentMethod${i}`);
        resetField3(`amount${i}`);
        resetField3(`amountBsS${i}`);
      }
      resetField3('paymentMethods');
    }
    // setValue3()
  };

  const validateMaxPaymentMethods = () => {
    if (watch3('paymentMethods') > 5) {
      setValue3('paymentMethods', 5);
      notify('El limite permitido es de 5 métodos de pago', 'info');
    }
  };

  const appendFile = (data) => {
    const formData = new FormData();
    formData.append('case', data?.case);
    formData.append('product', data?.product);
    formData.append('provider', data?.provider);
    formData.append('price', data?.price);
    formData.append('pvp', data?.pvp);
    formData.append('paymentStatus', data?.paymentStatus);
    if (data?.authorizedBy) {
      formData.append('authorizedBy', data?.authorizedBy);
    }

    // SI es cuentas por cobrar o NA no anexar datos ni comprobantes de pago

    if (
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      for (var i = 0; i < data?.payments.length; i++) {
        formData.append(`payments[${i}][amount]`, data?.payments[i].amount);
        formData.append(
          `payments[${i}][amountBsS]`,
          data?.payments[i].amountBsS
        );
        formData.append(`payments[${i}][method]`, data?.payments[i].method);
        formData.append(
          `payments[${i}][reference]`,
          data?.payments[i].reference
        );
      }

      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch3('paymentMethods')) &&
      parseFloat(watch3('paymentMethods')) > 0 &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }
    if (
      parseFloat(watch3('pvp')) &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum !== parseFloat(watch3('pvp'))) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }

    return formData;
  };

  const handleCancelService = (incidence) => {
    if (incidence) {
      membershipService
        .getCancelReason(incidence?.caseType)
        .then((response) => {
          if (response.status === 200) {
            setShowCancelService({
              cancelOptions: response?.data?.newCancellationReasons,
              show: !showCancelService.show,
              selected: incidence ? incidence : '',
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => { });
    } else {
      setShowCancelService({
        cancelOptions: [],
        show: !showCancelService.show,
        selected: incidence ? incidence : '',
      });
    }
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getLastServices = (membershipId) => {
    membershipService
      .getLastServices(membershipId)
      .then((response) => {
        if (response.status === 200) {
          setServiceHistory(response?.data?.latestServices);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getInternalTowServices = () => {
    let params = {
      type: 'all',
    };

    providersService
      .getInternalTowServices(params)
      .then((response) => {
        if (response.status === 200) {
          setShowCraneCounterModal((prevState) => {
            return {
              ...prevState,
              data: response?.data,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const openDocumentModal = (src, service) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        show: !showCraneCounterModal?.show,
        src: !showCraneCounterModal?.src ? src : '',
        service: service,
      };
    });
  };

  const zoomDocumentModal = (zoom) => {
    setShowCraneCounterModal((prevState) => {
      return {
        ...prevState,
        zoom: zoom,
      };
    });
  };

  const handleReason = (e) => {
    setReason(e?.target?.value);
  }

  useEffect(() => {
    getInternalTowServices();
    getPaymentMethods();
    getStates();
    getFaults();
    getAVs();
  }, []);

  return (
    <>
      <CraneCounter
        showCraneCounterModal={showCraneCounterModal}
        openDocumentModal={openDocumentModal}
        zoomDocumentModal={zoomDocumentModal}
      />
      <LoadSpinner label={'Cargando'} show={isLoading} />
      <MessageAVModal
        showSMSModal={showSMSModal}
        handleClose={openSMSModal}
        caseInfo={caseInfo}
        action={registerPayment}
        service={availableServices?.selected}
      />
      <MessageTowModal
        showSMSModal={showSMSTowModal}
        handleClose={openSMSTowModal}
        caseInfo={caseInfo}
        action={startTowService}
      />
      <CreateAVModal
        show={openPayModal}
        register={register3}
        openPayModal={openPayModal}
        handleClose={() => setOpenPayModal()}
        createAVService={createAVService}
        errors={errors3}
        options={{
          paymentMethods: paymentMethods,
          PaymentStatusOptions: PaymentStatusOptions,
          authorizedByOptions: authorizedByOptions
        }}
        onChange={{
          changePaymentStatus: changePaymentStatus,
          restorePaymentMethods: restorePaymentMethods,
          setBsRate: setBsRate,
          validateMaxPaymentMethods: validateMaxPaymentMethods,
        }}
        watch={watch3}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
      />
      <CancelCaseModal
        showCancelCase={showCancelService}
        handleClose={() => handleCancelService()}
        refresh={refreshAfterCancel}
      />
      <Header />
      <ScheduleServicesModal
        show={showSchedule}
        handleClose={() => setShowSchedule(false)}
        register={register4}
        control={control4}
        errors={errors4}
        action={scheduleService}
        t={t}
      />
      <div className="container">
        <div className="card incidence-card">
          <div className="buttons-container">
            <span></span>
            <ActionButton
              label={`Incidencia Particular`}
              action={() => history.push('/incidence/create/particular')}
            />
          </div>
          <div className="title-center-component">
            <h4>Nueva Incidencia</h4>
            <h5>{`Cliente Afiliado`}</h5>
          </div>
          <IncidenceSearch
            clientSearch={clientSearch}
            onChange={handleClientSearchChange}
            search={getMembership}
            selectClient={selectClient}
            type={clientTypes.AFFILIATED}
          />

          {clientSearch?.selected && (
            <ClientDetail membership={clientSearch?.selected} />
          )}

          {clientSearch?.selected &&
            clientSearch?.selected !== notFound &&
            [
              membershipStatus?.ACTIVO,
              membershipStatus['EN ESPERA'],
              membershipStatus['ESPERANDO VENCIMIENTO'],
              membershipStatus?.['POR RENOVACION'],
              membershipStatus?.RENOVADO,
            ].includes(clientSearch?.selected?.status?.membershipStatus) && (
              <IncidenceDetailForm
                register={register2}
                createCase={createAffiliatedCase}
                editCase={editAffiliatedCase}
                errors={errors2}
                setValue={setValue2}
                getValues={getValues2}
                watch={watch2}
                onChange={{
                  getCities: getCities,
                  selectGarage: selectGarage,
                  clearDestinyData: clearDestinyData,
                  forceRecalulateDistance: forceRecalulateDistance,
                }}
                options={{
                  statesOptions: statesOptions,
                  originCitiesOptions: originCitiesOptions,
                  destinycitiesOptions: destinycitiesOptions,
                  faultsOptions: faultsOptions,
                  addressOption: afiliatedAddressOptions,
                }}
                openMapModal={openMapModal}
                _case={_case?.selected}
              />
            )}

          <MapModal
            show={mapModalOpen}
            handleClose={handleCloseModal}
            origin={origin}
            setOrigin={setOrigin}
            destiny={destiny}
            setDestiny={setDestiny}
            route={route}
            setRoute={setRoute}
          />

          {_case?.selected && (
            <AvailableServices
              availableServices={availableServices}
              selectService={selectService}
            />
          )}

          {_case?.selected && availableServices?.selected && (
            <SelectedServices
              providersOptions={providersOptions}
              availableServices={availableServices}
              unSelectService={unSelectService}
              value={selectedBike}
              onChange={selectBikeProvider}
              openSMSModal={openSMSModal}
              openSMSTowModal={openSMSTowModal}
              handleReason={handleReason}
              reason={reason}
              clientType={clientTypes?.AFFILIATED}
            />
          )}
          {_case?.selected && availableServices?.selected && (
            <HandleCaseButtons
              setShowSchedule={setShowSchedule}
              startTowService={startTowService}
              selectedBike={selectedBike}
              service={availableServices?.selected}
              _case={_case?.selected}
              handleCancelService={handleCancelService}
              registerPayment={registerPayment}
              openModals={{
                openSMSTowModal: openSMSTowModal,
                openSMSModal: openSMSModal,
              }}
              bikerData={getOption(selectedBike, providersOptions)}
            />
          )}

          {clientSearch?.selected && clientSearch?.selected !== notFound && (
            <ServiceHistory serviceHistory={serviceHistory} />
          )}
        </div>
      </div>
    </>
  );
};

export const CaseDetail = (props) => {
  const { title, selectedCase } = props;
  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="buttons-container">
        <h6>{title}</h6>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Avería: </b> {selectedCase?.fault?.description} <br />
            <b>Caso Nro: </b> {selectedCase?.caseId}
            <br />
            {/* <b>Código de servicio: </b> CCCT15-0024435 */}
            <b>Operador: </b>
            {selectedCase?.user?.firstName} {selectedCase?.user?.lastName}
          </p>
        </div>
      </div>
    </div>
  );
};
