import React, { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import {
  FollowingForm,
  ApprovalPaymentForm,
  CancelCaseForm,
  FailedCaseForm,
  CancelServiceForm,
  CreateAVForm,
  FinishServiceForm,
  ProvidersPaymenForm,
  CreateTowForm,
  CreateSalesUserForm,
  EditSalesUserForm,
  CreatePaymentForm,
  FailureAssignForm,
} from '../Forms/Form';
import { Input } from '../../common/Forms/Input/Input';
import { useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AltButton, ActionButton } from '../Buttons/Buttons';
import { Dropzone, FileComponent, NewDropzone } from '../Dropzone/Dropzone';
import { ScheduleServicesForm } from '../../common/Forms/Form';
import { caseService } from '../../../services/caseService';
import { servicesService } from '../../../services/servicesService';
import { notify } from '../../../assets/helpers/toast';
import { exists, capitalize } from '../../../assets/helpers/funciones';
import { towSMSEnum, enumStatus, userType } from '../../../assets/helpers/options';
import { smsText } from '../../../assets/helpers/smsText';
import { useJsApiLoader } from '@react-google-maps/api';
import { CancelServicesTable } from "../../common/Tables/Tables";
import { cancelServiceHeader } from "../../../assets/helpers/tables"
import { Map } from './Map';
import { LocationMap } from './LocationMap';
import { AllDevicesMap } from './AllDevicesMap';
import moment from 'moment';
import { LoadSpinner } from '../LoadSpinner/LoadSpinner';
import { useDropzone } from 'react-dropzone';
import { selectServiceHeader } from '../../../assets/helpers/tables';
import Select from 'react-select';
import './Modals.scss';

export const ScheduleServicesModal = (props) => {
  const { show, register, errors, handleClose, t, action, control } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Agendar Servicio</h5>
        <h6 style={{ textAlign: 'center' }}>
          Seleccione la fecha y hora para la cual desea agendar su servicio
        </h6>
        <ScheduleServicesForm
          t={t}
          register={register}
          errors={errors}
          action={action}
          control={control}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FollowingModal = (props) => {
  const { showFollowingModal, handleClose, refresh } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas.UpdateTrackingSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    data.stage = showFollowingModal?.selected?.status;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .updateCaseTracking(data, showFollowingModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            handleClose(true);
            notify(`Seguimiento añadido con éxito`, 'success');
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={showFollowingModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Registrar seguimiento</h5>
        <FollowingForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CancelCaseModal = (props) => {
  const { showCancelCase, handleClose, refresh } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.CancelCaseSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      caseService
        .cancelCase(data, showCancelCase?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso cancelado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleClose(false);
    reset();
  };

  const cancelCase = () => {
    handleClose(false);
    refresh();
    reset();
  }

  return (
    <Modal
      size="lg"
      centered
      show={showCancelCase?.show}
      onHide={() => close()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Cancelar Caso</h5>
        <CancelCaseForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => cancelCase()}
          cancelOptions={showCancelCase?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const RollbackStageModal = (props) => {
  const { modal, handleClose, refresh } = props;

  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request

  const rollbackCase = () => {
    if (!isLoading) {
      setIsloading(true);
      caseService
        .rollbackStage(modal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso devuelto con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  }


  const close = () => {
    handleClose(false);
  };

  const cancelCase = () => {
    handleClose(false);
    refresh();
  }

  return (
    <Modal
      size="lg"
      centered
      show={modal?.show}
      onHide={() => close()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Devolver Caso</h5>
        <div style={{ marginTop: "24px" }} className="buttons-center-container">
          <h6>¿ Esta seguro que desea regresar el caso al estatus anterior ?</h6>
        </div>
        <div className="buttons-center-container">
          <AltButton action={cancelCase} label={'No'} />
          <ActionButton action={rollbackCase} label={'Si'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};


export const FailedCaseModal = (props) => {
  const { showCancelCase, handleClose, refresh } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.FailedCaseSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request

  const onSubmit = handleSubmit(async (data) => {
    let services = showCancelCase?.selected?.services
    let dataToPost = {
      failedReason: data?.failedReason,
      isInternal: services[services?.length - 1]?.provider?.isInternal,
    }
    if (!isLoading) {
      setIsloading(true);
      caseService
        .failCase(dataToPost, showCancelCase?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso marcado como fallido`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleClose(false);
    refresh();
    reset();
  };

  return (
    <Modal
      size="lg"
      centered
      show={showCancelCase?.show}
      onHide={() => close()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Marcar como fallido</h5>
        <FailedCaseForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          cancelOptions={showCancelCase?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CancelSingleServiceModal = (props) => {
  const { modal, handleClose, refresh } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.CancelServiceSchema()),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      servicesService
        .cancelService(data, modal?.service)
        .then((response) => {
          if (response.status === 200) {
            notify(`Servicio cancelado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const close = () => {
    handleClose(false);
    refresh();
    reset();
  };

  return (
    <Modal
      size="lg"
      centered
      show={modal?.show}
      onHide={() => close()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Cancelar Servicio</h5>
        <CancelServiceForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => close()}
          cancelOptions={modal?.cancelOptions}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};


export const ChangeOperatorModal = (props) => {
  const { showChangeOperatorModal, handleClose, refresh, options } = props;
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.ChangeOperatorSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      caseService
        .changeOperator(data, showChangeOperatorModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Operador modificado con exito`, 'success');
            setIsloading(false);
            handleClose(false);
            refresh();
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  let close = () => {
    reset();
    handleClose();
  };

  let filteredOperators = options?.filter(
    (o) => o?._id !== showChangeOperatorModal?.selected?.user?._id
  );

  return (
    <Modal
      size="lg"
      centered
      show={showChangeOperatorModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>
          Cambiar Operador para el caso{' '}
          {showChangeOperatorModal?.selected?.caseId}
        </h5>

        <form className="form-container">
          <div className="row">
            <Input
              bootstrap={'col-12'}
              label={'Listado de Operadores*'}
              type={'select'}
              options={filteredOperators}
              name={'user'}
              register={register}
              placeholder={''}
              errors={errors?.user?.message}
            />{' '}
          </div>
          <div className="buttons-center-container">
            {watch('user') && (
              <p style={{ textAlign: 'center' }}>
                ¿Está seguro que desea cambiar el operador de <br />
                <b>
                  {showChangeOperatorModal?.selected?.user?.firstName +
                    '  ' +
                    showChangeOperatorModal?.selected?.user?.lastName}
                </b>
                {'   '}a{'  '}
                <b>
                  {options.filter((o) => o?._id === watch('user'))[0]?.name}
                </b>{' '}
                ?
              </p>
            )}{' '}
          </div>
          <div className="buttons-center-container">
            <AltButton action={() => close()} label={'No'} />
            {watch('user') ? (
              <ActionButton action={onSubmit} label={'Si, cambiar operador'} />
            ) : (
              <span></span>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FinishCaseModal = (props) => {
  const { handleClose, options, refresh, finishCaseModal } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.FinishServiceSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    dataToPost.solvedByAV = false;
    dataToPost.stage = finishCaseModal?.selected?.lastStage?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .finishCase(dataToPost, finishCaseModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso Finalizado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            reset();
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={finishCaseModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Finalizar Caso</h5>
        <FinishServiceForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => handleClose(false)}
          options={options}
          actionLabel="Finalizar Caso"
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ProdiversPaymentModal = (props) => {
  const { handleModal, options, register, errors, action, modal, watch, onChange } = props;

  return (
    <Modal
      size="xl"
      centered
      show={modal?.isOpen}
      onHide={() => handleModal()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>CONFIRMACIÓN DEL PAGO</h5>
        <ProvidersPaymenForm
          modal={modal}
          register={register}
          watch={watch}
          onChange={onChange}
          errors={errors}
          action={action}
          cancel={() => handleModal()}
          options={options}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};


export const SolvedByAVModal = (props) => {
  const { handleClose, options, refresh, solvedByAVModal } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.FinishServiceSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    dataToPost.solvedByAV = true;
    dataToPost.stage = solvedByAVModal?.selected?.lastStage?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .finishCase(dataToPost, solvedByAVModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso Finalizado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            reset();
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={solvedByAVModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Falla Resuelta por Motorizado</h5>
        <FinishServiceForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => handleClose(false)}
          options={options}
          actionLabel="Falla resuelta por motorizado"
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FinishTowCaseModal = (props) => {
  const { handleClose, options, refresh, solvedByAVModal } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schemas.FinishServiceSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };

    dataToPost.solvedByAV = false;
    dataToPost.stage = solvedByAVModal?.selected?.lastStage?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .finishCase(dataToPost, solvedByAVModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso Finalizado con éxito`, 'success');
            setIsloading(false);
            handleClose(false);
            reset();
            refresh();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={solvedByAVModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Finalizar caso</h5>
        <FinishServiceForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => handleClose(false)}
          options={options}
          actionLabel="Finalizar Caso"
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const FailureAssignTowModal = (props) => {
  const { handleClose, options, solvedByAVModal, createTowService } = props;

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas.FinishServiceSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    let dataToPost = { ...data };


    if (!isLoading) {
      setIsloading(true);
      caseService
        .updateProviderFailureReason(dataToPost, solvedByAVModal?.selected?._id)
        .then((response) => {
          if (response.status === 200) {
            notify(`Caso actualizado`, 'success');
            setIsloading(false);
            handleClose(false);
            createTowService(solvedByAVModal?.selected)
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <Modal
      size="lg"
      centered
      show={solvedByAVModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Actualizar caso</h5>
        <FailureAssignForm
          t={t}
          register={register}
          errors={errors}
          action={onSubmit}
          cancel={() => handleClose(false)}
          options={options}
          actionLabel="Actualizar"
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};



export const LogOutModal = (props) => {
  const { handleModal, modal, action } = props;

  return (
    <Modal
      size="lg"
      centered
      show={modal?.isOpen}
      onHide={() => handleModal(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5> ¿Está seguro que desea cerrar sesión ?</h5>
      </Modal.Body>
      <br />
      <br />
      <div className="buttons-center-container">
        <AltButton label={'No'} action={() => handleModal()} />
        <ActionButton action={() => action()} label={'Si'} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeProviderModal = (props) => {
  const { handleClose, options, changeProviderModal, value, onChange, action } =
    props;

  let filteredBikers = options?.filter(
    (o) => o?._id !== changeProviderModal?.selected?.lastService?.provider?._id
  );

  return (
    <Modal
      size="lg"
      centered
      show={changeProviderModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>
          Cambiar motorizado para el caso{' '}
          {changeProviderModal?.selected?.caseId}
        </h5>
        {/* <SelectProvider
          bootstrap={'col-12 col-md-6'}
          label={'Elegir Motorizado:'}
          type={'select'}
          options={filteredBikers}
          name={'motorizado'}
          placeholder={'Seleccione'}
          value={value}
          onChange={(e) => onChange(e?.target?.value)}
        /> */}

        <div className="col-12 col-md-8" style={{ marginTop: "30px" }}>
          <Select options={filteredBikers}
            placeholder="Seleccione una opción"
            isSearchable
            // value={value}
            onChange={(e) => onChange(e)}
          />
        </div>

        {value && (
          <p style={{ textAlign: 'center' }}>
            ¿Está seguro que desea cambiar el motorizado de <br />
            <b>
              {
                changeProviderModal?.selected?.lastService?.provider
                  ?.contactName
              }
            </b>
            {'   '}a{'  '}
            <b>{options.filter((o) => o?._id === value)[0]?.contactName}</b> ?
          </p>
        )}

        <br />
        <div className="buttons-center-container">
          <AltButton label={'No'} action={() => handleClose(false)} />{' '}
          {value && (
            <ActionButton
              action={() => action()}
              label={'Cambiar motorizado'}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ChangeTowModal = (props) => {
  const { towType, handleClose, options, changeProviderModal, value, onChange, action } =
    props;

  let filteredTows = []

  if (towType === userType?.INTERNO) {
    filteredTows = options?.towOptions?.filter(
      (o) => o?._id !== changeProviderModal?.selected?.lastService?.provider?._id
    );
  }
  if (towType === userType?.EXTERNO) {
    filteredTows = options?.externalTowOptions?.filter(
      (o) => o?._id !== changeProviderModal?.selected?.lastService?.provider?._id
    );
  }

  return (
    <Modal
      size="lg"
      centered
      show={changeProviderModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>
          Cambiar Gruero para el caso {changeProviderModal?.selected?.caseId}
        </h5>
        <div className="row" style={{ minWidth: "500px" }}>
          <div className={`input-component-container col-12 col-md-4`}>
            <label >Tipo de Gruero:</label>
            <div>
              <select
                className="input-select"
                value={towType}
                onChange={(e) => onChange?.handleTowType(e)}
              >
                <option value=""></option>
                {options?.userTypeOptions?.map((o, i) => (
                  <option key={o?._id} value={o?._id}>
                    {capitalize(o?.name?.toLowerCase())}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12 col-md-8" style={{ marginTop: "30px" }}>
            <Select options={filteredTows}
              placeholder="Seleccione una opción"
              isSearchable
              // value={value}
              onChange={(e) => onChange?.handleTow(e)}
            />
          </div>

          {/* <SelectProvider
            bootstrap={'col-12 col-md-8'}
            label={'Elegir Gruero:'}
            type={'select'}
            options={filteredTows}
            name={'motorizado'}
            placeholder={'Seleccione'}
            value={value}
            onChange={(e) => onChange?.handleTow(e)}
          /> */}
        </div>
        {value && (
          <p style={{ textAlign: 'center' }}>
            ¿Está seguro que desea cambiar el gruero de <br />
            <b>
              {
                changeProviderModal?.selected?.lastService?.provider
                  ?.contactName
              }
            </b>
            {'   '}a{'  '}
            <b>
              {capitalize(
                filteredTows?.filter((o) => o?._id === value)[0]?.contactName
              )}
            </b>{' '}
            ?
          </p>
        )}

        <br />
        <div className="buttons-center-container">
          <AltButton label={'No'} action={() => handleClose(false)} />{' '}
          {value && (
            <ActionButton action={() => action()} label={'Cambiar gruero'} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MessageAVModal = (props) => {
  const { showSMSModal, handleClose, caseInfo, action, service, _case } = props;
  return (
    <Modal
      size="xl"
      centered
      show={showSMSModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h4>Mensajes de selección de AV</h4>
        <h5>
          Grupo Verde / Mensaje a cliente / Grupo Amarillo / Lista de difusión
          de grueros
        </h5>
        <div className="modal-messages-container">
          <MessageComponent1
            data={caseInfo}
            provider={showSMSModal?.provider}
            service={service}
            _case={_case}
          />
          <MessageComponent2
            data={caseInfo}
            provider={showSMSModal?.provider}
          />
          <MessageComponent3
            data={caseInfo}
            provider={showSMSModal?.provider}
            service={service}
          />
          <MessageComponent4
            data={caseInfo}
            provider={showSMSModal?.provider}
          />
        </div>

        <div className="buttons-center-container">
          <AltButton label={'Cerrar'} action={() => handleClose(false)} />{' '}
          {action && <AltButton label={'Continuar'} action={() => action()} />}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MessageComponent1 = (props) => {
  const { data, provider, service, _case } = props;
  return (
    <div className="modal-message-container">
      <p>
        <b>
          *SERVICIO DE TU/GRUERO*{' '}
          {data?.particularCustomer?._id && ' Particular'}
        </b>
        <br />-<b>*FECHA DE CREACIÓN*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
        {moment.utc(data?.createdAt).format('LT')}
        {data?.scheduleDate ? (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.scheduleDate).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.scheduleDate).format('LT')}
          </>
        ) : (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.createdAt).format('LT')}
          </>
        )}{' '}
        <br />-<b>*CLIENTE*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.lastName ||
            data?.membership?.customer?.lastName
          )}
        <br />-<b>*TELÉFONO*</b>:{' '}
        {exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)}
        <br />-<b>*PLACA*</b>:{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*COMENTARIO AVERÍA*</b>:{' '}
        {capitalize(
          data?.customerFailureDescription
            ? data?.customerFailureDescription
            : 'Ninguno'
        )}
        <br />-<b>*ORIGEN*</b>:
        {capitalize(data?.originAddress) +
          ', ' +
          capitalize(data?.originCity?.name) +
          ', ' +
          capitalize(data?.originState?.name) +
          ', ' +
          capitalize(data?.originReferencePoint)}
        {/* <br />-<b>*CÓDIGO DE PRODUCTO*</b>: XXXXXXXXX */}
        <br />-<b>*OPERADOR*</b>:
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        {data?.lastService?.code && (
          <>
            <br />
            <b>-*CÓDIGO DE SERVICIO*:</b> {exists(data?.lastService?.code)}
          </>
        )}
        <br />-<b>*MOTORIZADO*</b>: @{capitalize(provider?.contactName)}
        <br />-<b>*SERVICIO SOLICITADO*</b>:
        {capitalize(service?.product || data?.lastService?.product?.name)}
      </p>

      <CopyToClipboard
        text={smsText.greenGroup(data, provider, service, _case)}
      >
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>
    </div>
  );
};
export const MessageComponent2 = (props) => {
  const { data, provider } = props;
  return (
    <div className="modal-message-container">
      <p>
        ¡Estimad@! Ya tienes un asistente vial asignado
        <br />
        Datos del motorizado:
        <br />
        <br />
        <b>*Nombre: @{exists(provider?.contactName)} *</b> <br />
        <b>*Tiempo de espera estimado: 25 minutos *</b>
        <br />
        <br />
        Procura mantenerte en el lugar indicado para que nuestro asistente pueda
        ubicar tu posición.
        <br />
        {/* Con este link podrás hacer seguimiento del recorrido del
        asistente: */}
        <br />
        <br />
        {/* <span style={{ color: '#3f95a5', textAlign: 'center', width: '100%' }}>
          Link de ubicación
        </span> */}
      </p>
      <CopyToClipboard text={smsText.messageToClient(data, provider)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>{' '}
    </div>
  );
};

export const MessageComponent3 = (props) => {
  const { data, provider, service } = props;

  return (
    <div className="modal-message-container">
      <p>
        <b>
          *SERVICIO DE TU/GRUERO*{' '}
          {data?.particularCustomer?._id && ' Particular'}
        </b>
        <br />-<b>*FECHA DE CREACIÓN*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
        {moment.utc(data?.createdAt).format('LT')}
        {data?.scheduleDate ? (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.scheduleDate).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.scheduleDate).format('LT')}
          </>
        ) : (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.createdAt).format('LT')}
          </>
        )}{' '}
        <br />
        <b>-*AVERÍA*:</b> {capitalize(data?.fault?.description)}
        <br />-<b>*COMENTARIO AVERÍA*</b>:{' '}
        {capitalize(
          data?.customerFailureDescription
            ? data?.customerFailureDescription
            : 'Ninguno'
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}{' '}
        <br />
        <b>-*PLACA*:</b>{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />
        <b>-*TELÉFONO*:</b>
        {exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)}
        <br />
        <b>-*ORIGEN*:</b>{' '}
        {capitalize(data?.originAddress) +
          ', ' +
          capitalize(data?.originCity?.name) +
          ', ' +
          capitalize(data?.originState?.name) +
          ', ' +
          capitalize(data?.originReferencePoint)}
        <br />
        {data?.destinyState && <b>-*DESTINO*:</b>}
        {capitalize(data?.destinyAddress) +
          ', ' +
          capitalize(data?.destinyCity?.name) +
          ', ' +
          capitalize(data?.destinyState?.name) +
          '. ' +
          capitalize(data?.destinyReferencePoint)}
        {data?.lastService?.provider && (
          <>
            <br />
            <b>-*PROVEEDOR*: </b>{' '}
            {capitalize(data?.lastService?.provider?.contactName)}
          </>
        )}
        {data?.lastService?.price && (
          <>
            <br />
            <b>-*COSTO DE PROVEEDOR*: </b>{' '}
            {exists(data?.lastService?.price) + '$'}
          </>
        )}
        {data?.caseKm > 0 && (
          <>
            <br />
            <b>-*KILOMETRAJE*:</b> {exists(data?.caseKm) + ' Km'}
          </>
        )}
        <br />-<b>*DOMICILIO*</b>:{' '}
        {capitalize(data?.membership?.vehicle?.city) + ', '}
        {capitalize(data?.membership?.vehicle?.state)}
        <br />-<b>*CANTIDAD DE CASOS*</b>:{' '}
        {data?.membership?.towServices?.length}
        <br />-<b>*OPERADOR*</b>:
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        {data?.lastService?.code && (
          <>
            <br />
            <b>-*CÓDIGO DE SERVICIO*:</b> {exists(data?.lastService?.code)}
          </>
        )}
        {data?.membership?.code && (
          <>
            <br />
            <b>-*CÓDIGO DE PRODUCTO*:</b>
            {' ' +
              exists(data?.membership?.code?.prefixCode) +
              '-' +
              exists(data?.membership?.saleChannel?.shortName) +
              '-' +
              exists(data?.membership?.membershipId)}
          </>
        )}
        {/* <br />
        <b>-*CÓDIGO DE PRODUCTO*:</b> TGP-OFIC-14310 */}
        {/* <br />
        <b>-*VIGENCIA*:</b> 13/06/2021-13/06/2022
        <br />
        <b>-*DOMICILIO*:</b> Miranda
        <br />
        <b>-*CANT DE SERVICIOS*:</b> 0
        <br />
        <b>-*CLIENTE*:</b> Nuevo
        <br /> */}
        <br />
        <b>-*OPERADOR*: </b>{' '}
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        <br />-<b>*SERVICIO SOLICITADO*</b>:
        {capitalize(service?.product || data?.lastService?.product?.name)}
        {/* <br />
        <br />
        <b>*SERVICIOS ANTERIORES*:</b> */}
      </p>
      <CopyToClipboard text={smsText.yellowGroup(data, provider, service)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>{' '}
    </div>
  );
};

export const MessageComponent4 = (props) => {
  const { data } = props;

  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />
        <br />-<b>*FECHA DE CREACIÓN*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
        {moment.utc(data?.createdAt).format('LT')}
        {data?.scheduleDate ? (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.scheduleDate).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.scheduleDate).format('LT')}
          </>
        ) : (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.createdAt).format('LT')}
          </>
        )}{' '}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*COMENTARIO AVERÍA*</b>:{' '}
        {capitalize(
          data?.customerFailureDescription
            ? data?.customerFailureDescription
            : 'Ninguno'
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />
        <b>-*ORIGEN*:</b>{' '}
        {capitalize(data?.originAddress) +
          ', ' +
          capitalize(data?.originCity?.name) +
          ', ' +
          capitalize(data?.originState?.name) +
          '. ' +
          capitalize(data?.originReferencePoint)}
        <br />
        {data?.destinyState && <b>-*DESTINO*:</b>}
        {capitalize(data?.destinyAddress) +
          ', ' +
          capitalize(data?.destinyCity?.name) +
          ', ' +
          capitalize(data?.destinyState?.name) +
          '. ' +
          capitalize(data?.destinyReferencePoint)}
        <br />
        {data?.caseKm && <b>-*DISTANCIA*: </b>}
        {exists(data?.caseKm) + ' Km'}
        <br />
        <br />
        <b>*Interesados responder con el precio y el tiempo de espera*</b>
      </p>
      <CopyToClipboard text={smsText.towBroadcastMessage(data)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>{' '}
    </div>
  );
};

export const MessageComponent5 = (props) => {
  const { data } = props;
  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />-<b>*FECHA DE CREACIÓN*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
        {moment.utc(data?.createdAt).format('LT')}
        {data?.scheduleDate ? (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.scheduleDate).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.scheduleDate).format('LT')}
          </>
        ) : (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.createdAt).format('LT')}
          </>
        )}
        <br />-<b>*CLIENTE*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.lastName ||
            data?.membership?.customer?.lastName
          )}
        <br />-<b>*TELÉFONO*</b>:{' '}
        {exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)}
        <br />-<b>*PLACA*</b>:{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*COMENTARIO AVERÍA*</b>:{' '}
        {capitalize(
          data?.customerFailureDescription
            ? data?.customerFailureDescription
            : 'Ninguno'
        )}
        <br />-<b>*ORIGEN*</b>:
        {capitalize(data?.originState?.name) +
          ', ' +
          capitalize(data?.originCity?.name) +
          ', ' +
          capitalize(data?.originAddress) +
          '. ' +
          capitalize(data?.originReferencePoint)}
        {data?.destinyState?.name && (
          <>
            <br /> - <b>*DESTINO*</b>
          </>
        )}
        :
        {capitalize(data?.destinyAddress) +
          ', ' +
          capitalize(data?.destinyCity?.name) +
          ', ' +
          capitalize(data?.destinyState?.name) +
          '. ' +
          capitalize(data?.destinyReferencePoint)}
        {data?.caseKm && (
          <>
            <br /> - <b>*DISTANCIA*: </b> {exists(data?.caseKm)}Km
          </>
        )}
        <br />-<b>*OPERADOR* </b>:
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        {data?.lastService?.code && (
          <>
            <br />
            <b>-*CÓDIGO DE SERVICIO*: </b> {exists(data?.lastService?.code)}
          </>
        )}
        {data?.lastService?.price && (
          <>
            <br />
            <b>-*COSTO DE PROVEEDOR*: </b>{' '}
            {exists(data?.lastService?.price) + '$'}
          </>
        )}
        {/* <br />-<b>*CÓDIGO DE PRODUCTO*</b>: XXXXXXXXX */}
        <br />
        <br />
        <b>
          Estimado Gruero por favor llamar al cliente e indicarle al mismo
          Marca, Modelo, Color de la grua que le asistirá.
        </b>
        <br />
        <br />
        <b>
          SE SOLICITA POR FAVOR QUE AL TOMAR EL SERVICIO NOS ENVÍEN 3 FOTOS, AL
          SUBIRLO A LA GRÚA, SOBRE LA GRÚA Y AL BAJARLO DE LA GRÚA. RECUERDE QUE
          EL CÓDIGO DEL SERVICIO DEBE ESTAR REFLEJADO EN LA FACTURA.
        </b>
      </p>

      <CopyToClipboard text={smsText.towMesage(data)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>
    </div>
  );
};

export const CreateSalesUserModal = (props) => {
  const {
    reset,
    onChange,
    modal,
    handleModal,
    register,
    errors,
    action,
    options,
    watch,
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Nuevo Usuario</h5>
        <CreateSalesUserForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          options={options}
          onChange={onChange}
          watch={watch}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const EditSalesUserModal = (props) => {
  const {
    reset,
    onChange,
    modal,
    handleModal,
    register,
    errors,
    action,
    options,
  } = props;

  const close = () => {
    handleModal();
    reset();
  };

  return (
    <Modal size="lg" centered show={modal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h5>Nuevo Usuario</h5>
        <EditSalesUserForm
          register={register}
          errors={errors}
          action={action}
          cancel={() => close()}
          options={options}
          onChange={onChange}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MessageComponent6 = (props) => {
  const { data } = props;
  return (
    <div className="modal-message-container">
      <p>
        <b>*SERVICIO DE TU/GRUERO*</b>
        <br />-<b>*FECHA DE CREACIÓN*</b>:{' '}
        {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
        {moment.utc(data?.createdAt).format('LT')}
        {data?.scheduleDate ? (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.scheduleDate).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.scheduleDate).format('LT')}
          </>
        ) : (
          <>
            <br />-<b>*FECHA DE SERVICIO*</b>:{' '}
            {moment.utc(data?.createdAt).format('DD/MM/YYYY')}{' '}
            {moment.utc(data?.createdAt).format('LT')}
          </>
        )}{' '}
        <br />-<b>*CLIENTE*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.lastName ||
            data?.membership?.customer?.lastName
          )}
        <br />-<b>*TELÉFONO*</b>:{' '}
        {exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)}
        <br />-<b>*PLACA*</b>:{' '}
        {exists(
          data?.particularCustomer?.vehiclePlate ||
          data?.membership?.vehicle?.plate
        )}
        <br />-<b>*VEHICULO*</b>:{' '}
        {capitalize(
          data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
        ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleModel?.model ||
            data?.membership?.vehicle?.model
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleYear ||
            data?.membership?.vehicle?.year
          ) +
          ' ' +
          capitalize(
            data?.particularCustomer?.vehicleColor ||
            data?.membership?.vehicle?.color
          )}
        <br />-<b>*AVERÍA*</b>: {capitalize(data?.fault?.description)}
        <br />-<b>*ORIGEN*</b>:
        {capitalize(data?.originState?.name) +
          ', ' +
          capitalize(data?.originCity?.name) +
          ', ' +
          capitalize(data?.originAddress) +
          '. ' +
          capitalize(data?.originReferencePoint)}
        {data?.destinyState?.name && (
          <>
            <br /> - <b>*DESTINO*</b>
          </>
        )}
        :
        {capitalize(data?.destinyAddress) +
          ', ' +
          capitalize(data?.destinyCity?.name) +
          ', ' +
          capitalize(data?.destinyState?.name) +
          '. ' +
          capitalize(data?.destinyReferencePoint)}
        {data?.membership?.beginDate && data?.membership?.endDate && (
          <>
            <br /> - <b>*DISTANCIA*: </b> {exists(data?.caseKm)}Km
          </>
        )}
        {data?.services?.length > 0 && data?.services[0]?.type === "Asistencia vial" && (
          <>
            <br /> - <b>*VERIFICADO POR:*</b> {data?.services[0]?.provider?.contactName}
          </>
        )}
        {data?.services?.length > 0 && data?.services[0]?.type === "Grua" && (
          <>
            <br /> - <b>*VERIFICADO POR:*</b> Servicio de grúa directo
          </>
        )}
        {data?.lastService?.provider && (
          <>
            <br />
            <b>-*PROVEEDOR*: </b>{' '}
            {capitalize(data?.lastService?.provider?.contactName)}
          </>
        )}
        {data?.lastService?.price && (
          <>
            <br />
            <b>-*COSTO DE PROVEEDOR*: </b>{' '}
            {exists(data?.lastService?.price) + '$'}
          </>
        )}
        {data?.membership?.customer?.homeCity && (
          <>
            <br /> - <b>*DOMICILIO*: </b>
            {capitalize(data?.membership?.customer?.homeState) + ' '}
            {capitalize(data?.membership?.customer?.homeCity) + ' '}
            {capitalize(data?.membership?.customer?.homeMunicipality) + ' '}
            {capitalize(data?.membership?.customer?.homeAddress)}
          </>
        )}
        {data?.membership?.beginDate && (
          <>
            <br /> - <b>*VIGENCIA*: </b>{' '}
            {exists(
              moment.utc(data?.membership?.beginDate).format('DD/MM/YYYY')
            )}
            -
            {exists(moment.utc(data?.membership?.endDate).format('DD/MM/YYYY'))}
          </>
        )}
        <br />-<b>*DOMICILIO*</b>:{' '}
        {capitalize(data?.membership?.vehicle?.city) + ', '}
        {capitalize(data?.membership?.vehicle?.state)}
        <br />-<b>*CANTIDAD DE CASOS*</b>:{' '}
        {data?.membership?.towServices?.length}
        <br />-<b>*OPERADOR*</b>:
        {capitalize(data?.user?.firstName) +
          ' ' +
          capitalize(data?.user?.lastName)}
        {data?.lastService?.code && (
          <>
            <br />
            <b>-*CÓDIGO DE SERVICIO*:</b> {exists(data?.lastService?.code)}
          </>
        )}
        {data?.membership?.code && (
          <>
            <br />
            <b>-*CÓDIGO DE PRODUCTO*:</b>
            {' ' +
              exists(data?.membership?.code?.prefixCode) +
              '-' +
              exists(data?.membership?.saleChannel?.shortName) +
              '-' +
              exists(data?.membership?.membershipId)}
          </>
        )}
      </p>

      <CopyToClipboard text={smsText.towServiceMessage(data)}>
        <button className="copy-sms-text-button">Copiar</button>
      </CopyToClipboard>
    </div>
  );
};

export const MessageTowModal = (props) => {
  const { showSMSModal, handleClose, caseInfo, action } = props;

  let stage = '';

  switch (caseInfo?.lastStage?.name) {
    case enumStatus?.SEARCHING_TOW:
      stage = towSMSEnum?.SEARCHING_TOW;
      break;
    case enumStatus?.TOW_ASSIGNED:
      stage = towSMSEnum?.ASSIGNED_TOW;
      break;
    case undefined:
      stage = towSMSEnum?.FORM;
      break;
    default:
      stage = towSMSEnum?.ASSIGNED_TOW;
      break;
  }

  return (
    <Modal
      size="xl"
      centered
      show={showSMSModal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h4>Mensajes de selección de grueros</h4>
        <div className="modal-messages-container">
          {[towSMSEnum?.ASSIGNED_TOW].includes(stage) && (
            <div className="sms-modal-text-container ">
              <h5>Aprobación de Grueros</h5>
              <MessageComponent5
                data={caseInfo}
                provider={showSMSModal?.provider}
              />
            </div>
          )}
          {[towSMSEnum?.SEARCHING_TOW, towSMSEnum?.ASSIGNED_TOW].includes(
            stage
          ) && (
              <div className="sms-modal-text-container ">
                <h5>Grupo Amarillo</h5>
                <MessageComponent6
                  data={caseInfo}
                  provider={showSMSModal?.provider}
                />
              </div>
            )}

          {[towSMSEnum?.FORM, towSMSEnum?.SEARCHING_TOW].includes(stage) && (
            <div className="sms-modal-text-container ">
              <h5>Lista de Difusión de Grueros</h5>
              <MessageComponent4
                data={caseInfo}
                provider={showSMSModal?.provider}
              />
            </div>
          )}
        </div>

        <div className="buttons-center-container">
          <AltButton label={'Cerrar'} action={() => handleClose(false)} />{' '}
          {action && <AltButton label={'Continuar'} action={() => action()} />}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ProviderDetailModal = (props) => {
  const { show, handleClose, action } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3>Detalles del Proveedor</h3>
        <br />
        <table className="providers-detail-table">
          <tr>
            <td>
              <b>-Telefonos de Contacto:</b>
            </td>
            <td>02123191250</td>
          </tr>
          <tr>
            <td>
              <b>-Turno:</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Horario de atencion:</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Whee life (de 0 a 3500 Kg):</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Plataforma (de 0 a 5 Tonelada):</b>
            </td>
            <td>1</td>
          </tr>
          <tr>
            <td>
              <b>-Gancho (de 5 Hasta 8 Toneladas):</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>-Extra Pesado (de 8 hasta 20 Toneladas):</b>
            </td>
            <td></td>
          </tr>
        </table>
        <br />
        <div className="buttons-center-container">
          <AltButton action={() => handleClose(false)} label={'Cancelar'} />
          <ActionButton action={action} label={'Seleccionar'} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ApprovalModal = (props) => {
  const { show, handleClose, action, t, register, errors } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3>Se necesita aprobación para continuar el proceso</h3>
        <ApprovalPaymentForm
          t={t}
          register={register}
          errors={errors}
          action={action}
          cancel={() => handleClose(false)}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const NewIncidenceModal = (props) => {
  const { show, handleClose, particular, afiliated } = props;

  return (
    <Modal size="lg" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3 style={{ marginBottom: '24px' }}>Nueva Incidencia</h3>
        <h6 style={{ margin: '24px' }}>
          Seleccione el tipo de incidencia a crear
        </h6>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton action={particular} label={'Cliente Particular'} />
        <ActionButton action={afiliated} label={'Cliente Afiliado'} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const MapModal = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const {
    show,
    handleClose,
    origin,
    setOrigin,
    destiny,
    setDestiny,
    route,
    setRoute,
  } = props;

  if (!isLoaded) {
    return null;
  }

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container">
          <Map
            origin={origin}
            setOrigin={setOrigin}
            destiny={destiny}
            setDestiny={setDestiny}
            handleClose={handleClose}
            route={route}
            setRoute={setRoute}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const LocationMapModal = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const { modal, handleModal } = props;

  if (!isLoaded) {
    return null;
  }

  return (
    <Modal size="xl" centered show={modal?.show} onHide={() => handleModal()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container">
          <LocationMap data={modal?.data} />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};



export const AllDevicesMapModal = (props) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const { modal, handleModal, showInfoWindow, refresh, showLegend } = props;

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <LoadSpinner show={modal?.devicesLocation?.isLoading} />
      <Modal size="xl" centered show={modal?.show} onHide={() => handleModal(false)}>
        <Modal.Header closeButton className="modal-header"></Modal.Header>
        <Modal.Body className="modal-body">
          <div className="google-maps-container">
            <AllDevicesMap data={modal?.devicesLocation?.data} showLegend={showLegend} showInfoWindow={showInfoWindow} />
          </div>
        </Modal.Body>
        <div style={{ marginTop: '36px' }} className="buttons-center-container">
          <AltButton label={"Leyenda"} action={() => showLegend()} />
          <ActionButton label={"Actualizar"} action={() => refresh(true)} />

        </div>
        <Modal.Footer className="modal-footer"></Modal.Footer>
      </Modal>
    </>
  );
};




export const SelectServicePaymentModal = (props) => {
  const { modal, handleClose, handlePaymentModal } = props;

  return (
    <Modal
      size="xl"
      centered
      show={modal?.isOpen}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h4 style={{ marginBottom: '24px' }}>Seleccione pago a modificar</h4>
        <div className="google-maps-container"></div>

        <table className="table">
          <tr>
            {selectServiceHeader.map((h) => (
              <th key={h?.value}>{h?.name}</th>
            ))}
          </tr>

          {modal?.services.length > 0 &&
            modal?.services.map((d) => (
              <tr key={d?._id}>
                <td>{capitalize(d?.product?.name)}</td>
                <td>{exists(d?.paymentStatus)} {d?.pendingPayment?.datePaid ? " (Pago realizado) " : " "}</td>
                <td>{capitalize(d?.provider?.contactName)}</td>
                <td>{exists(d?.code)}</td>
                <td>{exists(d?.price) + '$'}</td>
                <td>{exists(d?.pvp) + '$'}</td>
                <td>
                  <div className='className="buttons-center-container'>
                    <ActionButton
                      label={'Seleccionar'}
                      action={() => handlePaymentModal(d)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateAVModal = (props) => {
  const {
    show,
    handleClose,
    register,
    createAVService,
    errors,
    options,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    bsDisabled,
  } = props;

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <CreateAVForm
          register={register}
          cancel={() => handleClose(false)}
          action={createAVService}
          errors={errors}
          options={options}
          watch={watch}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          bsDisabled={bsDisabled}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreatePaymentModal = (props) => {
  const {
    modal,
    cancel,
    register,
    action,
    errors,
    options,
    control,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
  } = props;

  return (
    <Modal size="xl" centered show={modal?.isOpen} onHide={() => cancel()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <CreatePaymentForm
          register={register}
          cancel={() => cancel()}
          action={action}
          errors={errors}
          options={options}
          watch={watch}
          modal={modal}
          control={control}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          bsDisabled={true}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CreateTowServiceModal = (props) => {
  const {
    show,
    handleClose,
    register,
    payTowService,
    errors,
    options,
    watch,
    onChange,
    fileUploaded,
    setFileUploaded,
    selectedProvider,
    bsDisabled,
  } = props;

  return (
    <Modal size="xl" centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="google-maps-container"></div>
        <CreateTowForm
          register={register}
          cancel={() => handleClose(false)}
          action={payTowService}
          errors={errors}
          options={options}
          watch={watch}
          onChange={onChange}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          selectedProvider={selectedProvider}
          bsDisabled={bsDisabled}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const UploadImageModal = (props) => {
  const { handleClose, imageModal } = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);

      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');
  const [canPost, setCanPost] = useState(true);

  const appendFile = (file) => {
    const formData = new FormData();
    formData.append('evidence', file);
    return formData;
  };

  const uploadAVImage = async () => {
    let fileToPost = appendFile(fileUploaded);

    if (canPost) {
      setCanPost(false);
      await servicesService
        .uploadAVImage(fileToPost, imageModal?.selected)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Imagen cargada con exito`, 'success');
            handleClose();
            setFileUploaded('');
            setCanPost(true);
          } else {
            notify(`${response.message}`, 'error');
            setCanPost(true);
          }
        });
    }
  };

  const close = () => {
    setFileUploaded('');
    handleClose();
  };

  return (
    <Modal size="xl" centered show={imageModal?.isOpen} onHide={() => close()}>
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h1 style={{ marginBottom: '24px' }}>Cargar imágenes de servicio AV</h1>
        <div className="dropzone-container">
          {imageModal?.label && <h6>{imageModal?.label}</h6>}
          {fileUploaded ? (
            <FileComponent
              file={fileUploaded}
              remove={() => setFileUploaded('')}
            />
          ) : (
            <Dropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              message={imageModal?.message}
            />
          )}
        </div>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton label={'Cancelar'} action={() => close()} />
        {fileUploaded && (
          <ActionButton
            label={'Enviar Imagen'}
            action={() => uploadAVImage()}
          />
        )}
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const ServiceImageModal = (props) => {
  const { handleClose, imageModal } = props;

  const [fileUploaded, setFileUploaded] = useState([]);
  const [showImages, setShowImages] = useState([true, true, true]);
  const [canPost, setCanPost] = useState(true);

  const appendFile = (file) => {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      if (file[i]?.name) {
        formData.append(images[i]?.name, file[i]);
      }
    }
    return formData;
  };

  const uploadImages = async () => {
    let fileToPost = appendFile(fileUploaded);

    if (canPost) {
      setCanPost(false);
      await servicesService
        .uploadTowImage(fileToPost, imageModal?.selected)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Imagenes cargadas con exito`, 'success');
            handleClose();
            setFileUploaded([]);
            setCanPost(true);
            window.location.reload();
          } else {
            notify(`${response.message}`, 'error');
            setCanPost(true);
          }
        });
    }
  };

  const close = () => {
    setFileUploaded([]);
    handleClose();
    setShowImages([true, true, true]);
  };

  const images = [
    {
      name: 'towAtOrigin',
      label: 'Gruero en origen',
      message: '(PNG, JPG, JEPG, máx. 3MB)',
      uploaded: imageModal?.uploaded?.towAtOrigin,
    },
    {
      name: 'vehicleOnTow',
      label: 'Vehículo en grúa',
      message: '(PNG, JPG, JEPG, máx. 3MB)',
      uploaded: imageModal?.uploaded?.vehicleOnTow,
    },
    {
      name: 'vehicleOnRoad',
      label: 'Vehículo en destino',
      message: '(PNG, JPG, JEPG, máx. 3MB)',
      uploaded: imageModal?.uploaded?.vehicleOnRoad,
    },
  ];

  return (
    <>
      <LoadSpinner show={!canPost} />
      <Modal
        size="xl"
        centered
        show={imageModal?.isOpen}
        onHide={() => close()}
      >
        <Modal.Header closeButton className="modal-header"></Modal.Header>
        <Modal.Body className="modal-body">
          <h1 style={{ marginBottom: '24px' }}>
            Cargar imágenes de servicio de grúa
          </h1>
          {images.map((img, index) => {
            return (
              <NewDropzone
                key={img?.name}
                fileUploaded={fileUploaded}
                setFileUploaded={setFileUploaded}
                img={img}
                index={index}
                setShowImages={setShowImages}
                showImages={showImages}
              />
            );
          })}
        </Modal.Body>
        <div className="buttons-center-container">
          <AltButton label={'Cancelar'} action={() => close()} />
          {fileUploaded.length > 0 && (
            <ActionButton
              label={'Enviar Imagen'}
              action={() => uploadImages()}
            />
          )}
        </div>
        <Modal.Footer className="modal-footer"></Modal.Footer>
      </Modal>
    </>
  );
};

export const CancelServiceModal = (props) => {
  const { modal, refresh, handleClose, cancelService } = props;

  const close = () => {
    handleClose(false);
    refresh();
  };

  return (
    <>
      <Modal
        size="xl"
        centered
        show={modal?.show}
        onHide={() => close()}
      >
        <Modal.Header closeButton className="modal-header"></Modal.Header>
        <Modal.Body className="modal-body">
          <h5 style={{ marginBottom: '24px' }}>
            Cancelar Servicio
          </h5>

          <CancelServicesTable header={cancelServiceHeader} data={modal?.services} cancelService={cancelService} />
        </Modal.Body>
        <Modal.Footer className="modal-footer"></Modal.Footer>
      </Modal>
    </>
  );
};


export const ChangeCaseStageModal = (props) => {
  const { showChangeStatusModal, handleClose, updateCaseStage } = props;

  return (
    <Modal
      size="lg"
      centered
      show={showChangeStatusModal?.isOpen}
      onHide={() => handleClose()}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <h3 style={{ marginBottom: '24px' }}>
          Mover caso {showChangeStatusModal?.selected?.caseId} a{' '}
          {showChangeStatusModal?.nextStatus}{' '}
        </h3>
        <h6 className="change-case-stage-modal" style={{ margin: '24px' }}>
          ¿Está seguro que desea mover el caso:{' '}
          {showChangeStatusModal?.selected?.caseId} de <br />
          <b>{showChangeStatusModal?.selected?.lastStage?.name}</b> a{' '}
          <b>{showChangeStatusModal?.nextStatus}</b>?
        </h6>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton label={'No'} action={() => handleClose()} />
        <ActionButton
          label={'Si, cambiar de estatus'}
          action={() => updateCaseStage()}
        />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};
