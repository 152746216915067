import { Header } from '../../../common/Header/Header.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas.js';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  EditExternalProviderForm,
  EditInternalProviderForm,
  EditVehicularAssetForm,
} from '../../../common/Forms/Form.jsx';
import { statesService } from '../../../../services/statesService.js';
import { providersService } from '../../../../services/providersService.js';
import { vehicleService } from '../../../../services/vehicleService.js';
import { notify } from '../../../../assets/helpers/toast.js';
import { useState } from 'react';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import './EditProvider.scss';
import { vehicularAssetsService } from '../../../../services/vehicularAssetsService.js';

export const EditExternalProvider = () => {
  let history = useHistory();
  let { providerId } = useParams();
  const { t } = useTranslation();
  const [statesOptions, setStatesOptions] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.CreateProviderSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      providersService
        .editProvider(data, providerId)
        .then((response) => {
          if (response.status === 200) {
            notify(`Proveedor externo editado con éxito`, 'success');
            history.push('/providers/external');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
          getProvidersById(providerId);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getProvidersById = (providerId) => {
    setIsloading(true);
    providersService
      .getProvidersById(providerId)
      .then((response) => {
        if (response.status === 200) {
          setValue('companyName', response?.data?.provider?.companyName);
          setValue('contactName', response?.data?.provider?.contactName);
          setValue('identification', response?.data?.provider?.identification);
          setValue(
            'identificationType',
            response?.data?.provider?.identificationType
          );
          setValue('phoneNumber', response?.data?.provider?.phoneNumber);
          setValue('phonePrefix', response?.data?.provider?.phonePrefix);
          setValue('state', response?.data?.provider?.state);
          setValue('type', response?.data?.provider?.type);
          setValue('isActive', response?.data?.provider?.isActive);
          setValue('isInternal', response?.data?.provider?.isInternal);
          setIsloading(false);
        } else {
          notify(`${response.message}` || 'error', 'error');
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const cancelEdition = () => {
    history.push('/providers/external');
  };

  useState(() => {
    getStates();
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner label={''} show={isLoading} />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Editar Proveedor Externo</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <EditExternalProviderForm
            t={t}
            register={register}
            errors={errors}
            action={onSubmit}
            cancel={cancelEdition}
            options={{ statesOptions: statesOptions }}
          />
        </div>
      </div>
    </>
  );
};

export const EditInternalProvider = () => {
  let history = useHistory();
  let { providerId } = useParams();
  const { t } = useTranslation();
  const [statesOptions, setStatesOptions] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.NewInternalProviderSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      providersService
        .editProvider(data, providerId)
        .then((response) => {
          if (response.status === 200) {
            notify(`Proveedor interno editado con éxito`, 'success');
            history.push('/providers/internal');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
          getProvidersById(providerId);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getProvidersById = (providerId) => {
    setIsloading(true);
    providersService
      .getProvidersById(providerId)
      .then((response) => {
        if (response.status === 200) {
          setValue('companyName', response?.data?.provider?.companyName);
          setValue('contactName', response?.data?.provider?.contactName);
          setValue('identification', response?.data?.provider?.identification);
          setValue(
            'identificationType',
            response?.data?.provider?.identificationType
          );
          setValue('phoneNumber', response?.data?.provider?.phoneNumber);
          setValue('phonePrefix', response?.data?.provider?.phonePrefix);
          setValue('state', response?.data?.provider?.state);
          setValue('type', response?.data?.provider?.type);
          setValue('isActive', response?.data?.provider?.isActive);
          setValue('isInternal', response?.data?.provider?.isInternal);
          setIsloading(false);
        } else {
          notify(`${response.message}` || 'error', 'error');
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const cancelEdition = () => {
    history.push('/providers/internal');
  };

  useState(() => {
    getStates();
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner label={''} show={isLoading} />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Editar Chofer</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <EditInternalProviderForm
            t={t}
            register={register}
            errors={errors}
            action={onSubmit}
            cancel={cancelEdition}
            options={{ statesOptions: statesOptions }}
          />
        </div>
      </div>
    </>
  );
};

export const EditVehicularAsset = () => {
  let history = useHistory();
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.CreateVehicularAssetSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    delete data.date;

    if (!isLoading) {
      setIsloading(true);
      vehicularAssetsService
        .editVehicularAsset(data, vehicleId)
        .then((response) => {
          if (response.status === 200) {
            notify(`Vehiculo editado con éxito`, 'success');
            history.push('/providers/fleet');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getVehicleClasses = () => {
    vehicleService
      .getVehicleClasses(true)
      .then((response) => {
        if (response.status === 200) {
          setVehicleClassesOptions(response?.data?.classes);
          getVehicleById(vehicleId);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getVehicleBrands = async (classId) => {
    vehicleService
      .getVehicleBrands(classId)
      .then((response) => {
        if (response.status === 200) {
          setBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getVehicleModels = async (brandId) => {
    vehicleService
      .getVehicleModels(brandId)
      .then((response) => {
        if (response.status === 200) {
          setModelOptions(response?.data?.models);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const preloadForm = (vehicularAsset) => {
    setTimeout(() => {
      setValue('class', vehicularAsset?.class?._id);
      setValue('brand', vehicularAsset?.brand?._id);
      setValue('model', vehicularAsset?.model?._id);
      setValue('plate', vehicularAsset?.plate);
      setValue('color', vehicularAsset?.color);
      if (vehicularAsset?.purchaseDate) {
        setValue('purchaseDate', new Date(vehicularAsset?.purchaseDate));
      }
      setValue('year', vehicularAsset?.year);
      setValue('description', vehicularAsset?.description);
      setIsloading(false);
    }, 2000);
  };

  const getVehicleById = async (vehicleId) => {
    vehicularAssetsService
      .getVehicleById(vehicleId)
      .then((response) => {
        if (response.status === 200) {
          getVehicleBrands(response?.data?.vehicularAsset?.class?._id)
            .then((brandId) => {
              getVehicleModels(response?.data?.vehicularAsset?.brand?._id);
            })
            .then((vehicularAsset) => {
              preloadForm(response?.data?.vehicularAsset);
            });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  };

  const cancelEdition = () => {
    history.push('/providers/external');
  };

  useState(() => {
    getVehicleClasses();
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner label={''} show={isLoading} />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Editar Vehículo</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <EditVehicularAssetForm
            t={t}
            register={register}
            errors={errors}
            action={onSubmit}
            options={{
              vehicleClassesOptions: vehicleClassesOptions,
              brandOptions: brandOptions,
              modelOptions: modelOptions,
            }}
            cancel={cancelEdition}
            control={control}
            watch={watch}
            onChange={{
              getVehicleBrands: getVehicleBrands,
              getVehicleModels: getVehicleModels,
            }}
          />
        </div>
      </div>
    </>
  );
};
